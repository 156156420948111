import { collection, getDocs } from 'firebase/firestore';
import { db } from 'core/config/firebase';

export const getClientCSAs = async (clientId: string): Promise<any[]> => {
  const csasCollection = collection(db, 'clients', clientId, 'consultingServicesAgreements');
  const csasSnapshot = await getDocs(csasCollection);
  return csasSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const getProjectCSAs = async (clientId: string, projectId: string): Promise<any[]> => {
  const csasCollection = collection(db, 'clients', clientId, 'projects', projectId, 'consultingServicesAgreements');
  const csasSnapshot = await getDocs(csasCollection);
  return csasSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));
};