import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import {theme} from './core/theme';
import HyperDX from '@hyperdx/browser'

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

if (window.location.hostname === 'portal.freetech.co') {
  HyperDX.init({
    apiKey: '010f76ed-e39b-4a1e-8a8f-909ee7b1f7d2',
    service: 'freetech-portal',
    consoleCapture: true, 
    advancedNetworkCapture: false,
  })
} 

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>,
);
