const freetechPaymentsPublicKey = `-----BEGIN PUBLIC KEY-----
MIIBojANBgkqhkiG9w0BAQEFAAOCAY8AMIIBigKCAYEApw6UmwrgFwx5WdfWYM0I
J6Nw0sH6OaOXGScGgrt1dmOhFGJvDLt/7SvLtdqgB/3OOKjzAaoSjHfUXdp7fpoo
XrFaqubA/WUMsJvy6a+KCHSEnKQmZDrnrbqdCebjkV15iIOz2SNxkG3uXRB+0lvy
5/MQoArNbx2IifKihSc0ImYrPBnW7WJOVlsDtxA6188X6SZXV6OR5IubErOg2FYG
FdTM1FX9md6xzRYixwqO79SvLl07WavvIDVAOADHxmAwBlXU9n2LSHbYAK9dAPKY
iEcT3eIna2mlu/Llr/NE8JgWt0g8+Wb2zPR54tqYA1M0EhbZ30dfvBkuTlqWSJ5p
dBNhI3A9U+ikDp+BwT3Zcw+Zd+ENHIDLPPTRSScg2nqadgXNgQVwnF6q3YH3j21t
mkDsZrjfrbM24wmtENO0kPuj2vzWntbhfHpx28a4cJg04eCqQRC6f3UpjN2m3tTi
1rRv69ekHlm76MsLPTlm8JSJJrsjhoBwXDobCcMKy7X3AgMBAAE=
-----END PUBLIC KEY-----
`;

export { freetechPaymentsPublicKey };
