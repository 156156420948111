import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, orderBy, onSnapshot, addDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { TextField, Button, MenuItem, Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import DashboardLayout from 'layouts/DashboardLayout';
import Papa from 'papaparse';
import Snackbar from '@mui/material/Snackbar';
import DataGridFirestoreCRUD from 'components/DataGridFirestoreCRUD';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MonthCalendar } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface TimeEntry {
    id: string; // Ensure 'id' is always provided for DataGrid
    date: string;
    hours: number;
    project: string;
    description: string;
    client: string;
    codeLink?: string;
    userId: string;
}

// Sample clients and projects, replace with your actual data or fetch from Firestore
const clients = ["Client A", "Client B", "Client C"];
const projects = ["Project 1", "Project 2", "Project 3"];

const TimeTracker = () => {
    const [newEntry, setNewEntry] = useState<Omit<TimeEntry, 'id'>>({ date: '', hours: 0, project: '', description: '', client: '', codeLink: '', userId: '' });
    const [user, setUser] = useState<string | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('Your time entry was added successfully.');

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const db = getFirestore();


    const handleAddEntry = async () => {
        console.log('Adding entry:', newEntry)
        if (!newEntry.date || !newEntry.hours || !newEntry.project || !newEntry.client || !user) {
            setSnackbarMessage("Please fill in all the required time entry fields.")
            setSnackbarOpen(true);
            return;
        }
        setSnackbarOpen(true);


        const res = await addDoc(collection(db, "timesheet"), {
            ...newEntry,
            userId: user,
        });

        console.log('Entry added to Firestore res:', res);

        setNewEntry({ date: '', hours: 0, project: '', description: '', client: '', codeLink: '', userId: '' });
    };

    const columns: GridColDef[] = [
        { field: 'date', headerName: 'Date', width: 150 },
        { field: 'hours', headerName: 'Hours', type: 'number', width: 110 },
        { field: 'project', headerName: 'Project', width: 150 },
        { field: 'client', headerName: 'Client', width: 150 },
        { field: 'description', headerName: 'Description', width: 200, sortable: false },
        { field: 'codeLink', headerName: 'Code Link', width: 200, sortable: false },
    ];

    // Add CSV import functionality
    //@ts-ignore
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        Papa.parse(file, {
            complete: async function (results) {
                console.log('Parsed results:', results);

                // Assuming results.data is an array of objects where each object has keys corresponding to the Firestore fields
                for (const row of results.data as any) {
                    // Assuming you have a TimeEntry interface where the fields match your CSV columns
                    const entry = {
                        date: row.Date, // Make sure CSV columns match these keys
                        hours: parseInt(row.Hours, 10),
                        project: row.Project,
                        description: row.Description,
                        client: row.Client,
                        codeLink: row['Code Link'],
                        userId: user, // Assuming 'user' state holds the current user's UID
                    };

                    // Add each entry to Firestore
                    try {
                        await addDoc(collection(db, "timesheet"), entry);
                        console.log('Entry added to Firestore:', entry);
                    } catch (error) {
                        console.error('Error adding document:', error);
                    }
                }
            },
            header: true // Indicates the first row of your CSV contains column headers
        });
    };

    const handleDownloadTemplate = () => {
        const template = 'Date,Hours,Project,Description,Client,Code Link\n2023-01-01,8,Project 1,Sample note,Client A,https://github.com/the-freetech-company/freetech-portal';
        const blob = new Blob([template], { type: 'text/csv;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'timesheet_template.csv';
        document.body.appendChild(a); // We need to append the element to the dom -> otherwise it won't work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again         
    };

    // @ts-ignore


    const newFilters: any[] = [["userId", "==", user]];

    const orderByColumn = "date"

    const staticValues = [{ userId: user }]

    const [filters, setFilters] = useState<any[]>([]);

    const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs('2024-04-17'));

    const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs('2024-04-17'));

    const updateFilters = (userId: string) => {
        const newFilters: any[] = [["userId", "==", userId]];
        // if (startDate && endDate) {
        //     newFilters.push(["date", ">=", startDate.format('YYYY-MM-DD')]);
        //     newFilters.push(["date", "<=", endDate.format('YYYY-MM-DD')]);
        // }
        setFilters(newFilters);
    };

    const onSubmitFilter = () => {

        if (startDate && endDate) {
          newFilters.push(["date", ">=", startDate.format('YYYY-MM-DD')]);
          newFilters.push(["date", "<=", endDate.format('YYYY-MM-DD')]);
        }
      
        setFilters(newFilters);

        console.log('Filters:', filters);

    }

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user.uid);
                updateFilters(user.uid);

            } else {
                setUser(null);
            }
        });
    }, []);


    return (
        <DashboardLayout>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'columns',
                    alignItems: 'center',
                    gap: 2,
                    m: 2
                }}
                >
                    <Typography
                        variant='h2'>
                        Time Tracker</Typography>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                    />

                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        
                    />

                    <Button onClick={() => {
                        onSubmitFilter();
                    }}>
                        Filter
                    </Button>

                </Box>


                <Box sx={{ height: 400, width: '100%' }}>

                <DataGridFirestoreCRUD
                        collectionName="timesheet"
                        columns={columns}
                        editable={true}
                        filters={filters}
                        orderByColumn={orderByColumn}
                        // staticValues={staticValues}
                        rowSize={25}
                    />
                </Box>

                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <TextField
                        label="Date"
                        type="date"
                        value={newEntry.date}
                        onChange={(e) => setNewEntry({ ...newEntry, date: e.target.value })}
                        sx={{ marginRight: 1 }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label="Hours"
                        type="number"
                        value={newEntry.hours}
                        onChange={(e) => setNewEntry({ ...newEntry, hours: parseInt(e.target.value, 10) || 0 })}
                        sx={{ marginRight: 1 }}
                    />
                    <TextField
                        select
                        label="Project"
                        value={newEntry.project}
                        onChange={(e) => setNewEntry({ ...newEntry, project: e.target.value })}
                        sx={{ width: 150 }}
                    >
                        {projects.map((project) => (
                            <MenuItem key={project} value={project}>{project}</MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        label="Client"
                        value={newEntry.client}
                        onChange={(e) => setNewEntry({ ...newEntry, client: e.target.value })}
                        sx={{ width: 150 }}
                    >
                        {clients.map((client) => (
                            <MenuItem key={client} value={client}>{client}</MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Code Link (Optional)"

                        value={newEntry.codeLink}
                        onChange={(e) => setNewEntry({ ...newEntry, codeLink: e.target.value })}
                    />

                    {/* Similar for Client and description */}
                    <Button variant="contained" onClick={handleAddEntry} sx={{ height: 55 }}>Add Entry</Button>
                    {/* </Box>

                <Box sx={{
                    '& > :not(style)': { m: 1 },
                    display: 'flex',
                    flexDirection: 'column',
                    width: '60%',

                }}> */}
                    <TextField
                        label="Description"
                        multiline
                        fullWidth
                        value={newEntry.description}
                        minRows={4}
                        onChange={(e) => setNewEntry({ ...newEntry, description: e.target.value })}
                    />
                    {/* </Box>
                <Box sx={{
                    '& > :not(style)': { m: 1 },
                    display: 'flex',
                    flexDirection: 'column',
                    width: '60%',

                }}> */}
                    <TextField
                        label="Code Link (Optional)"
                        fullWidth
                        value={newEntry.codeLink}
                        onChange={(e) => setNewEntry({ ...newEntry, codeLink: e.target.value })}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: 2, m: 2 }}>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Upload Spreadsheet
                        <input
                            type="file"
                            hidden
                            accept=".csv"
                            onChange={handleFileUpload}
                        />
                    </Button>
                    <Button variant="contained" onClick={handleDownloadTemplate}>Download Template</Button>
                </Box>



                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={snackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}
                    message="Your time entry was added successfully."
                />
            </LocalizationProvider>

        </DashboardLayout >
    );
};

export default TimeTracker;
