import { collection, getDocs } from 'firebase/firestore';
import { db } from 'core/config/firebase';

export const getClientProjects = async (clientId: string): Promise<Project[]> => {
  const projectsCollection = collection(db, 'clients', clientId, 'projects');
  const projectsSnapshot = await getDocs(projectsCollection);
  // @ts-ignore
  return projectsSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
};