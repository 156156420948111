import React from 'react';
import { Box, Typography, Avatar, TextField, Button } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

interface ProfileInfoProps {
  freelancer: Freelancer;
  onUpdate: (updatedFreelancer: Freelancer) => void;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({ freelancer, onUpdate }) => {
  const [editing, setEditing] = React.useState(false);
  const [editedFreelancer, setEditedFreelancer] = React.useState(freelancer);

  const handleEdit = () => setEditing(true);
  const handleCancel = () => {
    setEditedFreelancer(freelancer);
    setEditing(false);
  };

  const handleSave = () => {
    onUpdate(editedFreelancer);
    setEditing(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedFreelancer(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar src={freelancer.profilePicture} alt={`${freelancer.firstName} ${freelancer.lastName}`} sx={{ width: 80, height: 80, mr: 2 }} />
        <Box>
          {editing ? (
            <>
              <TextField
                name="firstName"
                value={editedFreelancer.firstName}
                onChange={handleChange}
                variant="standard"
                sx={{ mb: 1 }}
              />
              <TextField
                name="lastName"
                value={editedFreelancer.lastName}
                onChange={handleChange}
                variant="standard"
              />
            </>
          ) : (
            <Typography variant="h4">{`${freelancer.firstName} ${freelancer.lastName}`}</Typography>
          )}
          <Typography variant="body1" color="textSecondary">{freelancer.email}</Typography>
        </Box>
      </Box>
      {editing ? (
        <TextField
          fullWidth
          name="phone"
          label="Phone"
          value={editedFreelancer.phone}
          onChange={handleChange}
          margin="normal"
        />
      ) : (
        <>
          <Typography variant="body1"><strong>Phone:</strong> {freelancer.phone}</Typography>
          <Typography variant="body1"><strong>User Type:</strong> {freelancer.user_type}</Typography>
        </>
      )}
      {!editing ? (
        <Button startIcon={<EditIcon />} onClick={handleEdit}>
          Edit Profile
        </Button>
      ) : (
        <Box mt={2}>
          <Button startIcon={<SaveIcon />} onClick={handleSave} sx={{ mr: 1 }}>
            Save
          </Button>
          <Button startIcon={<CancelIcon />} onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ProfileInfo;