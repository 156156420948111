import { addDocument, updateDocument, getServerTimestamp } from "core/api/firestore";
import { uploadFile } from "core/api/storage";

export const addEngineerToProjectAssignment = async (
  clientId: string,
  engineerId: string,
  assignmentData: Omit<ProjectAssignment, 'id'>,
  file: File | null
): Promise<ProjectAssignment> => {
  const newAssignmentRef = await addDocument(`clients/${clientId}/projectAssignments`, {
    ...assignmentData,
    engineerId,
    createdAt: getServerTimestamp(),
  });

  let fileUrl: string | undefined;
  if (file) {
    fileUrl = await uploadFile(`clients/${clientId}/projectAssignments/${newAssignmentRef.id}`, file);
    await updateDocument(`clients/${clientId}/projectAssignments/${newAssignmentRef.id}`, { fileUrl });
  }

  const newAssignment: ProjectAssignment = {
    id: newAssignmentRef.id,
    ...assignmentData,
    engineerId,
    fileUrl,
  };

  await addDocument(`users/${engineerId}/projectAssignments`, {
    clientId,
    projectId: assignmentData.projectId,
    assignmentId: newAssignmentRef.id,
    createdAt: getServerTimestamp(),
  });

  return newAssignment;
};

