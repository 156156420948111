import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { LineChart } from '@mui/x-charts/LineChart';

interface EarningsChartProps {
  assignments: ProjectAssignment[];
}

const EarningsChart: React.FC<EarningsChartProps> = ({ assignments }) => {
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [earningsByMonth, setEarningsByMonth] = useState<{ month: string; earnings: number }[]>([]);

  useEffect(() => {
    calculateEarningsByMonth(assignments);
  }, [assignments, selectedYear]);

  const calculateEarningsByMonth = (assignments: ProjectAssignment[]) => {
    const monthlyEarnings: { [key: string]: number } = {};
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    assignments.forEach(assignment => {
      if (assignment.startDate) {
        const startDate = new Date(assignment.startDate);
        const endDate = assignment.endDate ? new Date(assignment.endDate) : new Date();
        
        let currentDate = new Date(startDate);
        currentDate.setDate(1); // Start from the beginning of the month

        while (currentDate <= endDate && currentDate.getFullYear() <= selectedYear) {
          if (currentDate.getFullYear() === selectedYear) {
            const month = monthNames[currentDate.getMonth()];
            const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
            let daysWorked = daysInMonth;

            // Adjust days worked for start and end months
            if (currentDate.getMonth() === startDate.getMonth() && currentDate.getFullYear() === startDate.getFullYear()) {
              daysWorked = daysInMonth - startDate.getDate() + 1;
            } else if (currentDate.getMonth() === endDate.getMonth() && currentDate.getFullYear() === endDate.getFullYear()) {
              daysWorked = endDate.getDate();
            }

            const monthlyEarning = (assignment.billableRate || 0) * (assignment.utilizedHours || 160) * (daysWorked / daysInMonth);
            monthlyEarnings[month] = (monthlyEarnings[month] || 0) + monthlyEarning;
          }

          currentDate.setMonth(currentDate.getMonth() + 1);
        }
      }
    });

    const sortedEarnings = monthNames.map(month => ({
      month,
      earnings: Math.round(monthlyEarnings[month] || 0)
    }));

    setEarningsByMonth(sortedEarnings);
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(event.target.value as number);
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 5 }, (_, i) => currentYear - i);

  return (
    <Box>
      <FormControl sx={{ mb: 2, minWidth: 120 }}>
        <InputLabel id="year-select-label">Year</InputLabel>
        <Select
          labelId="year-select-label"
          value={selectedYear}
          label="Year"
          onChange={handleYearChange}
        >
          {yearOptions.map(year => (
            <MenuItem key={year} value={year}>{year}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ height: 300, mb: 4 }}>
        <Typography variant="h6" gutterBottom align="center">
          Monthly Earnings for {selectedYear}
        </Typography>
        <LineChart
          xAxis={[{ 
            data: earningsByMonth.map(item => item.month),
            scaleType: 'band',
          }]}
          yAxis={[{ 
            label: 'Earnings ($)'
          }]}
          series={[
            {
              data: earningsByMonth.map(item => item.earnings),
              area: true,
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default EarningsChart;