import { clockifyReportsUrl, functionUrl, workspaceId } from "core/constants/clockify"
import { get, post, del, put, patch } from "./requests"
import { ClockifyProject, CostAndHours, GetCostOptions, ProjectMembership, UserCostOptions } from "./types"
import axios from "axios"


export const listProjects = async (): Promise<ClockifyProject[]> => {
    try {
        const projects = await get(`/workspaces/${workspaceId}/projects`)
        return projects as ClockifyProject[]
    } catch (error) {
        console.error('Failed to fetch projects:', error)
        throw error
    }
}
export const findProjects = async (query: string): Promise<ClockifyProject[]> => {
    const projects = await listProjects()
    return projects.filter((project) => project.name.toLowerCase().includes(query.toLowerCase()))
}
export const addProject = async (projectData: Omit<ClockifyProject, 'id'>): Promise<ClockifyProject> => {
    try {
        const project = await post(`/workspaces/${workspaceId}/projects`, projectData)
        return project as ClockifyProject
    }
    catch (error) {
        console.error('Failed to add project:', error)
        throw error
    }
}
export const deleteProject = async (projectId: string): Promise<void> => {
    try {
        await del(`/workspaces/${workspaceId}/projects/${projectId}`)
    }
    catch (error) {
        console.error('Failed to delete project:', error)
        throw error
    }
}
export const findProjectById = async (projectId: string): Promise<ClockifyProject> => {
    try {
        const project = await get(`/workspaces/${workspaceId}/projects/${projectId}`)
        return project as ClockifyProject
    }
    catch (error) {
        console.error('Failed to fetch project:', error)
        throw error
    }
}
export const updateProject = async (projectId: string, projectData: Partial<ClockifyProject>): Promise<ClockifyProject> => {
    try {
        const project = await put(`/workspaces/${workspaceId}/projects/${projectId}`, projectData)
        return project as ClockifyProject
    }
    catch (error) {
        console.error('Failed to update project:', error)
        throw error
    }
}
export const updateProjectMembership = async (projectId: string, userId: string, membershipData: { membershipStatus: string }): Promise<void> => {
    try {
        await patch(`/workspaces/${workspaceId}/projects/${projectId}/memberships/${userId}`, membershipData)
    }
    catch (error) {
        console.error('Failed to update project membership:', error)
        throw error
    }
}
export const addUsersToProject = async (projectId: string, userIds: string[]): Promise<void> => {
    try {
        await post(`/workspaces/${workspaceId}/projects/${projectId}/memberships`, userIds)
    }
    catch (error) {
        console.error('Failed to add users to project:', error)
        throw error
    }
}
export const removeUsersFromProject = async (projectId: string, userIds: string[]): Promise<void> => {
    try {
        await post(`/workspaces/${workspaceId}/projects/${projectId}/memberships`, {userIds, remove: true})
    }
    catch (error) {
        console.error('Failed to remove users from project:', error)
        throw error
    }
}    
export const getUsersInProject = async (projectId: string): Promise<ProjectMembership[]> => {
    try {
        const users = await get(`/workspaces/${workspaceId}/projects/${projectId}`)
        console.log(users.memberships)
        return users.memberships as ProjectMembership[]
    }
    catch (error) {
        console.error('Failed to fetch project users:', error)
        throw error
    }
}
export const getCost = async (
    projectId: string,
    options: GetCostOptions
  ): Promise<CostAndHours> => {
    let dateRangeStart: string
    let dateRangeEnd: string
    if (options.month === 'current') {
      const now = new Date()
  
      console.log(now)
      dateRangeStart = new Date(now.getFullYear(), now.getMonth(), 1).toISOString()
      dateRangeEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999).toISOString()
      console.log(dateRangeStart)
      console.log(dateRangeEnd)
    } else if (options.dateRangeStart && options.dateRangeEnd) {
      dateRangeStart = options.dateRangeStart
      dateRangeEnd = options.dateRangeEnd
    } else {
      throw new Error('You must provide either a date range or set month to "current".')
    }
  
    const endpoint = `/workspaces/${workspaceId}/reports/summary`
  
    const body = {
      dateRangeStart,
      dateRangeEnd,
      summaryFilter: {
        groups: ['PROJECT'],
      },
      projects: {
        ids: [projectId],
      },
      exportType: 'JSON',
      amountShown: 'EARNED',
    };
  
    try {
      const reportData = await post(endpoint, body, undefined, clockifyReportsUrl)
      console.log(reportData)
  
      const totalAmount = (reportData.totals[0]?.totalAmount || 0) / 100
      const totalHours = (reportData.totals[0]?.totalBillableTime/60 || 0)
  
      return { totalAmount, totalHours }
    } catch (error) {
      console.error('Error fetching total cost and hours:', error)
      throw error
    }
  }

  export const getUserCost = async (
    projectId: string,
    userId: string,
    options: UserCostOptions
  ): Promise<CostAndHours> => {
    const params = { projectId, userId, options }
    console.log(functionUrl)
    
    try {
      const response = await axios.post(`${functionUrl}/projects/getUserCost`, params )
      return response.data as CostAndHours
    } catch (error) {
      console.error('Error fetching user cost and hours:', error)
      throw error
    }

  }

