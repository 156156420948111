import { updateDocument } from "../firestore";
import { increaseFunctionUrls } from "../increase/increaseFunctionUrls";
import { createInboundAchTransfer } from 'core/api/increase/ach/createInboundAchTransfer';
import { loadSingleInvoice } from "./loadSingleInvoice";

interface TakePaymentForClientInvoiceProps {
    invoice: InvoiceData;
    client: Client;
    stakeholder: Stakeholder;
    achTransferDetails: typeof increaseFunctionUrls.ach.createInboundAchTransfer.input;
}

interface TakePaymentForClientInvoiceResult {
    success: boolean;
    error?: string;
    result?: any;
}

export const takePaymentForClientInvoice = async (props: TakePaymentForClientInvoiceProps): Promise<TakePaymentForClientInvoiceResult> => {

    const { invoice, client, stakeholder, achTransferDetails } = props;

    // 1. Create an Increase inbound ACH transfer
    const increaseTransferResult = await createInboundAchTransfer(achTransferDetails);
 
    // 2. Update the invoice with the Increase transfer ID
    await updateDocument(`clients/${client.id}/invoices/${invoice.id}`, {
        ...invoice,
        increaseAchPayments: [
            ...(invoice.increaseAchPayments || []),
            {
                ...increaseTransferResult.result,
                madeByStakeholder: stakeholder,
            }
        ]
    } as InvoiceData);

    const updatedInvoice = await loadSingleInvoice(client.id, invoice.id);

    // 3. Return the result

    return {
        success: true,
        result: updatedInvoice,
    }
}