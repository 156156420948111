import { updateDocument } from "core/api/firestore";

export const updateClientFreelancer = async (clientId: string, freelancerId: string, updatedFreelancer: Partial<Freelancer>): Promise<void> => {
  try {
    const freelancerPath = `clients/${clientId}/engineers/${freelancerId}`;
    await updateDocument(freelancerPath, updatedFreelancer);
  } catch (error) {
    console.error("Error updating freelancer:", error);
    throw new Error("Failed to update freelancer");
  }
};
