import { doc, getDoc, getFirestore } from "firebase/firestore";

const db = getFirestore();

export const fetchFreelancerDoc = async (userId: string) => {
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as Freelancer;
  }
  return null;
};
