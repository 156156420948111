import { deleteDocument } from "core/api/firestore";

export const deleteEngineerFromProjectAssignment = async (
  clientId: string,
  assignmentId: string,
  engineerId: string
): Promise<void> => {
  // Delete the assignment from the client's projectAssignments collection
  await deleteDocument(`clients/${clientId}/projectAssignments/${assignmentId}`);

  // Delete the assignment reference from the engineer's projectAssignments collection
  await deleteDocument(`users/${engineerId}/projectAssignments/${assignmentId}`);
};
