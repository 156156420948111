import React, { useState, useEffect } from 'react';
import { DashboardLayout } from 'layouts';
import { Button, TextField, Grid, Typography, Container, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import ExistingCSADataGrid from './components/ExistingCSADataGrid';
import { useAuth } from 'core/contexts/AuthContext';
import { generateCSA } from 'core/api/csa/legacy/generateCSA';

const CSAGenerator = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        seeking: '',
        servicesType: '',
        phaseNumber: '',
        phasePurpose: '',
        monthlyBudgetDollars: '',
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { userInfo } = useAuth();



    const handleChange = (e: any) => {
        const { name, value } = e.target;
        // @ts-ignore
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        // Here you would send the formData to your serverless function
        const rawData = formData;

        // @ts-ignore
        rawData.createdBy = userInfo.email;

        const data = await generateCSA(formData);
        console.log('Data:', data);
        setFormSubmitted(true);


        console.log('Response:', data.fileUrl);

        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            seeking: '',
            servicesType: '',
            phaseNumber: '',
            phasePurpose: '',
            monthlyBudgetDollars: '',
        });
        setFormSubmitted(false);

    };



    return (
        <DashboardLayout>
            <Container component="main" maxWidth="xl">
                <Typography component="h1" variant="h5">
                    CSA Generator
                </Typography>
                <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item  key='firstName'>
                                    <TextField
                                        required
                                        fullWidth
                                        id='firstName'
                                        label='First Name'
                                        name='firstName'
                                        // @ts-ignore
                                        value={formData.firstName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item key='lastName'>
                                    <TextField
                                        required
                                        fullWidth
                                        id='lastName'
                                        label='Last Name'
                                        name='lastName'
                                        // @ts-ignore
                                        value={formData.lastName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item key='email'>
                                    <TextField
                                        required
                                        fullWidth
                                        id='email'
                                        label='Email'
                                        name='email'
                                        // @ts-ignore
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item key='companyName'>
                                    <TextField
                                        required
                                        fullWidth
                                        id='companyName'
                                        label='Company Name'
                                        name='companyName'
                                        // @ts-ignore
                                        value={formData.companyName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item key='phaseNumber'>
                                    <TextField
                                        required
                                        fullWidth
                                        id='phaseNumber'
                                        label='Phase Number'
                                        name='phaseNumber'
                                        // @ts-ignore
                                        value={formData.phaseNumber}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item key='servicesType'>
                                <InputLabel id="demo-simple-select-standard-label">Age</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="servicesType"
                                        value={formData.servicesType}
                                        label="Services Type"
                                        name='servicesType'
                                        onChange={handleChange}

                                    >
                                        <MenuItem value="">
                                            <em>None</em>   
                                        </MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item key='monthlyBudgetDollars'>
                                    <TextField
                                        required
                                        fullWidth
                                        id='monthlyBudgetDollars'
                                        label='Monthly Budget Dollars'
                                        name='monthlyBudgetDollars'
                                        // @ts-ignore
                                        value={formData.monthlyBudgetDollars}
                                        onChange={handleChange}
                                    />


                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} key='seeking'>
                                    <TextField
                                        required
                                        fullWidth
                                        multiline
                                        rows={4}
                                        id='seeking'
                                        label='Seeking'
                                        name='seeking'
                                        // @ts-ignore
                                        value={formData.seeking}
                                        onChange={handleChange}
                                    />


                                </Grid>
                                <Grid item xs={12} key='phasePurpose'>
                                    <TextField
                                        required
                                        fullWidth
                                        multiline
                                        rows={12}
                                        id='phasePurpose'
                                        label='Phase Purpose'
                                        name='phasePurpose'
                                        // @ts-ignore
                                        value={formData.phasePurpose}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* {Object.keys(formData).map((key) => (
                            <Grid item xs={12} sm={6} key={key}>
                                <TextField
                                    required
                                    fullWidth
                                    id={key}
                                    label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                    name={key}
                                    // @ts-ignore
                                    value={formData[key]}
                                    onChange={handleChange}
                                />
                            </Grid>
                        ))} */}
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </FormControl>
            </Container>

            <ExistingCSADataGrid formSubmitted={formSubmitted} />

        </DashboardLayout>
    );
};

export default CSAGenerator;
