import React, { useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Box,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { useQuery } from '@tanstack/react-query'

import {
  listProjects,
  findProjects,
  getUsersInProject,
  getUserCost,
} from 'core/api/clockify/projects'
import { getUserById } from 'core/api/clockify/users'
import { ClockifyProject, CostAndHours, MemberProfile } from 'core/api/clockify/types'
import { DashboardLayout } from 'layouts'
interface MemberProfileWithId extends MemberProfile {
  userId: string
  cost?: CostAndHours
}

const fetchProjects = async (searchQuery: string): Promise<ClockifyProject[]> => {
  if (searchQuery) {
    return await findProjects(searchQuery)
  }
  return await listProjects()
}
const roundToNearest5Minutes = (date: Date) => {
  const minutes = date.getMinutes()
  const roundedMinutes = Math.round(minutes / 5) * 5
  date.setMinutes(roundedMinutes, 0, 0)
  return date
}

const fetchProjectMembers = async (
  projectId: string,
  startDate: Date | null,
  endDate: Date | null
): Promise<MemberProfileWithId[]> => {
  const members = await getUsersInProject(projectId)
  const userIds = members.map((member: { userId: string }) => member.userId)

  const memberProfilesPromises = userIds.map(async (userId: string) => {
    try {
      const profile = await getUserById(userId)

      const cost = await getUserCost(projectId, userId, {
        dateRangeStart: startDate?.toISOString() || new Date().toISOString(),
        dateRangeEnd: endDate?.toISOString() || new Date().toISOString(),
      })
      return { ...profile, userId, cost } as MemberProfileWithId
    } catch (error) {
      console.error(`Failed to fetch profile or cost for user ${userId}:`, error)
      return null
    }
  })

  const memberProfiles = await Promise.all(memberProfilesPromises)

  return memberProfiles.filter(
    (profile): profile is MemberProfileWithId => profile !== null
  )
}

const ClockifyManagement: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  )
  const [endDate, setEndDate] = useState<Date | null>(roundToNearest5Minutes(new Date()))
  const [expandedProjectId, setExpandedProjectId] = useState<string | false>(false)

  const {
    data: projects,
    isLoading: isProjectsLoading,
    isError: isProjectsError,
    error: projectsError,
    refetch: refetchProjects,
  } = useQuery<ClockifyProject[], Error>({
    queryKey: ['projects', searchQuery],
    queryFn: () => fetchProjects(searchQuery),
    staleTime: 1000 * 60 * 5,
  })

  const handleSubmit = () => {
    refetchProjects()
    setExpandedProjectId(false)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const {
    data: membersData,
    isLoading: isMembersLoading,
    isError: isMembersError,
    error: membersError,
  } = useQuery<MemberProfileWithId[], Error>({
    queryKey: ['projectMembers', expandedProjectId, startDate, endDate],
    queryFn: () => fetchProjectMembers(expandedProjectId as string, startDate, endDate),
    enabled: !!expandedProjectId,
    staleTime: 1000 * 60 * 5,
  })

  const handleAccordionChange = (projectId: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpandedProjectId(isExpanded ? projectId : false)
  }

  return (
    <DashboardLayout>
      <Box>
        <TextField
          sx={{ marginBottom: 2 }}
          label="Search Projects"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <Box sx={{ my: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>

        </Box>

        {isProjectsLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 200,
            }}
          >
            <CircularProgress />
          </Box>
        ) : isProjectsError ? (
          <Typography color="error">
            Error: {projectsError?.message || 'Failed to load projects.'}
          </Typography>
        ) : projects && projects.length > 0 ? (
          projects.map((project: ClockifyProject) => (
            <Accordion
              key={project.id}
              expanded={expandedProjectId === project.id}
              onChange={handleAccordionChange(project.id)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{project.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {expandedProjectId === project.id && isMembersLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 100,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : expandedProjectId === project.id && isMembersError ? (
                  <Typography color="error">
                    Error: {membersError?.message || 'Failed to load members.'}
                  </Typography>
                ) : (
                  <Grid container spacing={2}>
                    {membersData && membersData.length > 0 ? (
                      membersData.map((member: MemberProfileWithId) => (
                        <Grid item xs={12} sm={6} md={4} key={member.userId}>
                          <Card>
                            <CardContent>
                              <Typography variant="h6">{member.name}</Typography>
                              <Typography variant="body2">{member.email}</Typography>
                              {member.cost ? (
                                <>
                                  <Typography variant="body2">
                                    Total Amount: $
                                    {member.cost.totalAmount
                                      ? member.cost.totalAmount.toFixed(2)
                                      : '0.00'}
                                  </Typography>
                                  <Typography variant="body2">
                                    Total Hours:{' '}
                                    {member.cost.totalHours > 0
                                      ? `${member.cost.totalHours.toFixed(2)} hrs`
                                      : 'No hours logged'}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography variant="body2">
                                    No billable amount
                                  </Typography>
                                  <Typography variant="body2">No hours logged</Typography>
                                </>
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      ))
                    ) : (
                      <Typography>No members in this project.</Typography>
                    )}
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography>No projects found.</Typography>
        )}
      </Box>
    </DashboardLayout>
  )
}

export default ClockifyManagement
