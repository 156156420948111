import React from 'react';
import { DashboardLayout as MuiDashboardLayout, AppProvider } from '@toolpad/core';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import Footer from 'components/Footer';
import { useTheme } from '@mui/material/styles';
import type { Navigation, Router, Session } from '@toolpad/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { theme } from 'core/theme'
import Tooltip from '@mui/material/Tooltip';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import ReceiptIcon from '@mui/icons-material/Receipt';

import {
    Home as HomeIcon,
    Settings as SettingsIcon,
    Build as BuildIcon,
    AccountTree as AccountTreeIcon,
    RecentActors as RecentActorsIcon,
    Calculate as CalculateIcon,
    LocalShipping as LocalShippingIcon,
    Loyalty as LoyaltyIcon,
    Description as DescriptionIcon,
    Paid as PaidIcon,
    Group as GroupIcon,
    PostAdd as PostAddIcon,
    Inventory2 as StockIcon,
    Person2, 
    SvgIconComponent,
} from "@mui/icons-material";
import { useAuth } from 'core/contexts/AuthContext';

interface Props {
    children: React.ReactNode;
}

export const superuserPages: Navigation = [
    { kind: 'header', title: 'General' },
    {
        segment: 'admin',
        title: 'Home',
        icon: <HomeIcon />,
    },
    {
        segment: 'project-assignments',
        title: 'Project Assignments',
        icon: <DescriptionIcon />,
    },
    {
        segment: 'freelancer-manage',
        title: 'Profile',
        icon: <Person2 />,
    },
    {
        segment: 'client-invoices',
        title: 'Invoices',
        icon: <ReceiptIcon />,
    },
    { kind: 'divider' },
    { kind: 'header', title: 'Management' },
    {
        segment: 'admin',
        title: 'Operations',
        icon: <AccountTreeIcon />,
        children: [
            {
                segment: 'client-management',
                title: 'Manage Clients',
                icon: <GroupIcon />,
                pattern: 'client-management{/:clientId}?'
            },
            {
                segment: 'freelancer-management',
                title: 'Manage Freelancers',
                icon: <GroupIcon />,
                pattern: 'freelancer-management{/:freelancerId}?'
            },
            {
                segment: 'user-management',
                title: 'Manage Users',
                icon: <StockIcon />,
            },
        ],
    },

    {
        segment: 'admin',
        title: 'Finance',
        icon: <AccountTreeIcon />,
        children: [
            {
                segment: 'vault',
                title: 'Vault',
                icon: <PaidIcon />,
            },
            {
                segment: 'invoice-management',
                title: 'Invoicing',
                icon: <ReceiptIcon />,
            },
        ],
    },
    {
        segment: 'admin',
        title: 'Tools',
        icon: <BuildIcon />,
        children: [
            {
                segment: 'csa-generator',
                title: 'CSA Generator',
                icon: <RecentActorsIcon />,
            },
            {
                segment: 'clockify',
                title: 'Clockify Explorer',
                icon: <GroupIcon />,
            },
            {
                segment: 'project-assignment-generator',
                title: 'Project Assignment Generator',
                icon: <GroupIcon />,
            },
        ],
    },
    // {
    //     segment: 'stkaeholder',
    //     title: 'Stakeholder',
    //     icon: <BuildIcon />,
    //     children: [
    //         {
    //             segment: 'invoices',
    //             title: 'Invoices',
    //             icon: <ReceiptIcon />,
    //         },
    //         {
    //             segment: 'clockify',
    //             title: 'Clockify Explorer',
    //             icon: <GroupIcon />,
    //         },
    //     ],
    // },

];


const freelancerPages: Navigation = [
    { kind: 'header', title: 'General' },
    {
        segment: '',
        title: 'Home',
    icon: <HomeIcon />,
    },
    {
        segment: 'project-assignments',
        title: 'Project Assignments',
        icon: <DescriptionIcon />,
    },
    {
        segment: 'freelancer-manage',
        title: 'Profile',
        icon: <Person2 />,
    }
];



export const stakeholderPages: Navigation = [
    { kind: 'header', title: 'General' },
    {
        segment: '',
        title: 'Home',
        icon: <HomeIcon />,
    },
    {
        segment: 'stakeholder/invoices',
        title: 'Invoices',
        icon: <ReceiptIcon />,
    },
    {
        segment: 'stakeholder/projects',
        title: 'Projects',
        icon: <DescriptionIcon />,
    },
];


export const LayoutProvider = ({ children }: Props): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    console.log(location)

    const router = React.useMemo<Router>(() => ({
        pathname: location.pathname,
        searchParams: new URLSearchParams(location.search),
        navigate: (path) => navigate(path),
    }), [location, navigate]);


    const { userInfo, logout } = useAuth();


    const userType = userInfo?.user_type;

    const pages = React.useMemo(() => {
        switch (userType) {
            case "admin":
                return superuserPages;
            case "freelancer":
                return freelancerPages;
            case "stakeholder":
                return stakeholderPages;
            default:
                return [];
        }
    }, [userType]);

    const [session, setSession] = React.useState<Session | null>({
        user: {
          id: userInfo?.id,
          name: userInfo?.firstName + ' ' + userInfo?.lastName,
          email: userInfo?.email,
          image: userInfo?.profilePicture,
        },
    });


    const authentication = React.useMemo(() => {
    return {
        signIn: () => {
        setSession({
            user: {
                id: userInfo?.id,
                name: userInfo?.firstName + ' ' + userInfo?.lastName,
                email: userInfo?.email,
                image:  userInfo?.profilePicture,
              },
        });
        },
        signOut: () => {
        setSession(null);
        logout();
        },
    };
    }, [userInfo, logout]);

    return (
        <AppProvider
            authentication={authentication}
            session={session}
            navigation={pages}
            theme={theme}
            router={router}
            branding={{
                logo: (
                    <Box sx={{ paddingLeft: 2 }}>
                        <Box
                            component="img"
                            src="https://raw.githubusercontent.com/the-freetech-company/freetech-image-content/refs/heads/main/freeteh-cropped-larger.png"
                            alt="FreeTech"
                            sx={{
                                maxWidth: '400px',
                                width: '100%',
                                height: 'auto',
                                borderRadius: '10px',
                            }}
                        />
                    </Box>
                ),
                title: '',
            }}
        >
            {/* <MuiDashboardLayout slots={{ toolbarActions: Search }}> */}
            {children}

        </AppProvider>
    );
}