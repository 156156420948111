import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  NotFound,
  IsLoading,
  SessionExpired,
  TimeTracker,
  Profile,
  SupportRequest,
} from "components";
import { AuthRoute, SuperUserRoute, ProtectedRoute } from "./components";
import { useAuth } from "core/contexts/AuthContext";
import { Login, ForgotPassword, ResetPassword } from "views/Auth";
import {
  Home as FreelancerHome,
  ProjectAssignments,
  Manage,
} from "views/Freelancer";
import ClientDirectory from "views/SuperAdmin/ClientManagement";
import CSAGenerator from "views/SuperAdmin/CSAGenerator";
import UserManagement from "views/SuperAdmin/UserManagement";
import SalesManagement from "views/SuperAdmin/SalesManagement";
import AccountDeactivated from "components/AccountDeactivated";
import NeedVerification from "components/NeedVerification";
import ProjectManagement from "views/SuperAdmin/ProjectManagement";
import ClientManagement from "views/SuperAdmin/ClientManagement";
import ClientOverview from "views/SuperAdmin/ClientManagement/components/ClientOverview";
import FreelancerManagement from "views/SuperAdmin/FreelancerManagement";
import FreelancerOverview from "views/SuperAdmin/FreelancerManagement/FreelancerOverview/FreelancerOverview";
import ClockifyManagement from "views/SuperAdmin/ClockifyManagement";
import { LayoutProvider, superuserPages } from "layouts/LayoutProvider";
import Home from "views/SuperAdmin/Home";
import InvoiceManagement from "views/SuperAdmin/InvoiceManagement/InvoiceManagement";
import ClientInvoices from "views/Stakeholder/StakeholderInvoices/ClientInvoices";
import Bank from "views/SuperAdmin/Bank/Bank";
import { NavigationPageItem } from "@toolpad/core";
import StakeholderRoute from "./components/StakeholderRoute";
import StakeholderProjects from "views/Stakeholder/StakeholderProjects/StakeholderProjects";
import { ProjectAssignmentGenerator } from "views/SuperAdmin/ProjectAssignmentGenerator/ProjectAssignmentGenerator";

export const AppRoutes = () => {
  const { isLoading, userInfo, isVerified } = useAuth();

  if (isLoading) {
    console.log("is loading");
    return <IsLoading />;
  }

  console.log("isnt loading");

  // @ts-ignore
  if (userInfo?.is_active === false || userInfo?.is_active === "false") {
    return <AccountDeactivated />;
  }

  if (!isVerified() && userInfo) {
    return <NeedVerification />;
  }

  const AuthRoutes = () => {
    return (
      <>
        <Route
          path="/login"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <AuthRoute>
              <ForgotPassword />
            </AuthRoute>
          }
        />

        <Route
          path="/reset-password"
          element={
            <AuthRoute>
              <ResetPassword />
            </AuthRoute>
          }
        />
      </>
    );
  };

  const SharedRoutes = () => {
    return (
      <>
        {/* Profile */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        {/* Support Request */}
        <Route
          path="/support-request"
          element={
            <ProtectedRoute>
              <SupportRequest />
            </ProtectedRoute>
          }
        />

        {/* Not Found - other pages redirect here */}
        <Route
          path="/not-found"
          element={
            <ProtectedRoute>
              <NotFound />
            </ProtectedRoute>
          }
        />

        <Route
          path="/project-assignments"
          element={
            <ProtectedRoute>
              <ProjectAssignments />
            </ProtectedRoute>
          }
        />

        <Route
          path="/time-tracker"
          element={
            <ProtectedRoute>
              <TimeTracker />
            </ProtectedRoute>
          }
        />

        {/* Redirect * to NotFound to hide SuperUser Routes  */}
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <Navigate to="/not-found" replace />
            </ProtectedRoute>
          }
        />
      </>
    );
  };

  const FreelancerRoutes = () => {
    return (
      <>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <FreelancerHome />
            </ProtectedRoute>
          }
        />

        <Route
          path="/project-assignments"
          element={
            <ProtectedRoute>
              <ProjectAssignments />
            </ProtectedRoute>
          }
        />

        <Route
          path="/freelancer-manage"
          element={
            <ProtectedRoute>
              <Manage />
            </ProtectedRoute>
          }
        />
      </>
    );
  };

  const SuperUserRoutes = () => {
    return (
      <>
        {/* SuperUser Routes */}
        <Route
          path="/admin"
          element={
            <SuperUserRoute>
              <Home />
            </SuperUserRoute>
          }
        />

        <Route
          path="/admin/client-management"
          element={
            <SuperUserRoute>
              {/* <Admin /> */}
              <ClientManagement />
            </SuperUserRoute>
          }
        />

        <Route
          path="/admin/vault"
          element={
            <SuperUserRoute>
              <Bank />
            </SuperUserRoute>
          }
        />

        <Route
          path="/admin/client-management/:clientId"
          element={
            <SuperUserRoute>
              <ClientOverview />
            </SuperUserRoute>
          }
        />

        <Route
          path="/admin/csa-generator"
          element={
            <SuperUserRoute>
              <CSAGenerator />
            </SuperUserRoute>
          }
        />

        <Route
          path="/admin/user-management"
          element={
            <SuperUserRoute>
              {/* <Admin /> */}
              <UserManagement />
            </SuperUserRoute>
          }
        />

        <Route
          path="/client-invoices"
          element={
            <SuperUserRoute>
              <ClientInvoices />
            </SuperUserRoute>
          }
        />

        {/* <Route
          path="/admin/sales-management"
          element={
            <SuperUserRoute>
              <SalesManagement />
            </SuperUserRoute>
          }
        /> */}

        <Route
          path="/admin/project-management"
          element={
            <SuperUserRoute>
              <ProjectManagement />
            </SuperUserRoute>
          }
        />

        <Route
          path="/admin/freelancer-management"
          element={
            <SuperUserRoute>
              <FreelancerManagement />
            </SuperUserRoute>
          }
        />

        <Route
          path="/admin/freelancer-management/:freelancerId"
          element={
            <SuperUserRoute>
              <FreelancerOverview />
            </SuperUserRoute>
          }
        />
        <Route
          path="/admin/clockify"
          element={
            <SuperUserRoute>
              <ClockifyManagement />
            </SuperUserRoute>
          }
        />
        <Route
          path="/admin/invoice-management"
          element={
            <SuperUserRoute>
              <InvoiceManagement />
            </SuperUserRoute>
          }
        />

        <Route
          path="/admin/project-assignment-generator"
          element={
            <SuperUserRoute>
              <ProjectAssignmentGenerator />
            </SuperUserRoute>
          }
        />
      </>
    );
  };

  const filteredSuperUserRoutes = superuserPages.filter(
    (page): page is NavigationPageItem => "segment" in page && "title" in page
  );

  console.log("filteredsuperuseroutes", filteredSuperUserRoutes);

  const StakeholderRoutes = () => {
    return (
      <>
        <Route
          path="/stakeholder/invoices"
          element={
            <StakeholderRoute>
              <ClientInvoices />
            </StakeholderRoute>
          }
        />

        <Route
          path="/stakeholder/projects"
          element={
            <StakeholderRoute>
              <StakeholderProjects />
            </StakeholderRoute>
          }
        />
      </>
    );
  };

  return (
    <Router>
      <LayoutProvider>
        <Routes>
          {AuthRoutes()}

          {SharedRoutes()}

          {FreelancerRoutes()}

          {SuperUserRoutes()}

          {StakeholderRoutes()}
        </Routes>
      </LayoutProvider>
    </Router>
  );
};
