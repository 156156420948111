import { getHeaders } from "core/api/getHeaders";
import { functionUrls } from "core/api";

export const generateCSA = async (formData: any) => {
  const res = await fetch(functionUrls.misc.generateCSA.url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(formData),
  });
  return res.json();
};
