import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  CircularProgress,
} from '@mui/material';
import { createExternalAccount } from 'core/api/increase/external-accounts/createExternalAccount';
import { increaseFunctionUrls } from 'core/api/increase/increaseFunctionUrls';
interface AddBankDialogProps {
  open: boolean;
  onClose: () => void;
}

const AddBankDialog: React.FC<AddBankDialogProps> = ({ open, onClose }) => {
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [fundingSource, setFundingSource] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [accountNickname, setAccountNickname] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleContinue = async () => {
    setLoading(true);
    setError(null);

    try {
      const externalAccountDetails: typeof increaseFunctionUrls.externalAccounts.createExternalAccount.input = {
        account_number: accountNumber,
        description: accountNickname,
        routing_number: routingNumber,
        account_holder: accountType as 'business' | 'individual' | 'unknown',
        funding: fundingSource as 'checking' | 'savings' | 'other',
      };

      const result = await createExternalAccount(externalAccountDetails);

      if (result.success) {
        console.log('External account created:', result.result);
        onClose();
      } else {
        setError('Failed to create external account');
      }
    } catch (err) {
      console.error('Error creating external account:', err);
      setError('An error occurred while creating the external account');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5">Verify your account</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" paragraph>
          To move money, we'll need your routing and account numbers. To verify this info, we'll make two small deposits to your account, then remove them.
        </Typography>
        <TextField
          fullWidth
          label="Account holder name"
          value={accountHolderName}
          onChange={(e) => setAccountHolderName(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Account nickname"
          value={accountNickname}
          onChange={(e) => setAccountNickname(e.target.value)}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Account type</InputLabel>
          <Select
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
            label="Account type"
          >
            <MenuItem value="individual">Individual</MenuItem>
            <MenuItem value="business">Business</MenuItem>
            <MenuItem value="unknown">Unknown</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Funding source</InputLabel>
          <Select
            value={fundingSource}
            onChange={(e) => setFundingSource(e.target.value)}
            label="Funding source"
          >
            <MenuItem value="checking">Checking</MenuItem>
            <MenuItem value="savings">Savings</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Routing number"
          value={routingNumber}
          onChange={(e) => setRoutingNumber(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Account number"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Confirm account number"
          value={confirmAccountNumber}
          onChange={(e) => setConfirmAccountNumber(e.target.value)}
          margin="normal"
        />
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <Button
          onClick={handleContinue}
          variant="contained"
          color="primary"
          disabled={loading || !accountHolderName || !accountType || !fundingSource || !routingNumber || !accountNumber || accountNumber !== confirmAccountNumber || !accountNickname}
        >
          {loading ? <CircularProgress size={24} /> : 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddBankDialog;