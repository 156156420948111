import {
  getFirestore,
  collection,
  query,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { getProjectName } from "core/api/projects";
const db = getFirestore();

export const fetchAllFreelancerProjectAssignments = async (
  freelancerId: string
): Promise<ProjectAssignment[]> => {
  const assignmentsQuery = query(
    collection(db, "users", freelancerId, "projectAssignments")
  );
  const assignmentsSnapshot = await getDocs(assignmentsQuery);
  const assignments: ProjectAssignment[] = [];

  for (const assignmentDoc of assignmentsSnapshot.docs) {
    const assignmentData = assignmentDoc.data();
    const projectName = await getProjectName(
      assignmentData.clientId,
      assignmentData.projectId
    );

    const clientRef = doc(db, "clients", assignmentData.clientId);
    const clientSnap = await getDoc(clientRef);
    const clientData = clientSnap.data();

    assignments.push({
      id: assignmentData.assignmentId,
      projectId: assignmentData.projectId,
      clientId: assignmentData.clientId,
      status: assignmentData.status,
      startDate: assignmentData.startDate,
      endDate: assignmentData.endDate,
      billableRate: assignmentData.billableRate,
      projectName: projectName,
      engineerId: freelancerId,
      csaId: assignmentData.csaId || null,
    });
  }

  return assignments;
};
