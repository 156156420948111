import { functionsBaseUrl } from "core/constants/functionsBaseUrl";
import { UseEversignTemplateInput, UseEversignTemplateOutput } from "./types";

interface GenerateCSAInput {}
interface GenerateCSAOutput {}

interface DownloadCSAInput {}
interface DownloadCSAOutput {}

interface CreateFirebaseUserInput {}
interface CreateFirebaseUserOutput {}

interface DeleteFirebaseUserInput {}
interface DeleteFirebaseUserOutput {}

interface SendEmailInput {}
interface SendEmailOutput {}

interface ResendVerificationEmailInput {}
interface ResendVerificationEmailOutput {}


const increaseFunctionUrlsConfig = {
  users: {
    createFirebaseUser: {
      url: `${functionsBaseUrl}/users-createFirebaseUser`,
      method: "POST",
      input: {} as CreateFirebaseUserInput,
      output: {} as CreateFirebaseUserOutput,
    },
    deleteFirebaseUser: {
      url: `${functionsBaseUrl}/users-deleteFirebaseUser`,
      method: "DELETE",
      input: {} as DeleteFirebaseUserInput,
      output: {} as DeleteFirebaseUserOutput,
    },
  },
  mail: {
    sendEmail: {
      url: `${functionsBaseUrl}/mail-sendEmail`,
      method: "POST",
      input: {} as SendEmailInput,
      output: {} as SendEmailOutput,
    },
    resendVerificationEmail: {
      url: `${functionsBaseUrl}/mail-resendVerificationEmail`,
      method: "POST",
      input: {} as ResendVerificationEmailInput,
      output: {} as ResendVerificationEmailOutput,
    },
  },
  eversign: {
    useEversignTemplate: {
      url: `${functionsBaseUrl}/useEversignTemplate`,
      method: "POST",
      input: {} as UseEversignTemplateInput,
      output: {} as UseEversignTemplateOutput,
    },
  },
  misc: {
    generateCSA: {
      url: `${functionsBaseUrl}/generateCSA`,
      method: "POST",
      input: {} as GenerateCSAInput,
      output: {} as GenerateCSAOutput,
    },
    downloadCSA: {
      url: `${functionsBaseUrl}/downloadCSA`,
      method: "GET",
      input: {} as DownloadCSAInput,
      output: {} as DownloadCSAOutput,
    },
  },
} as const;


export const functionUrls: NestedFunctionUrls<typeof increaseFunctionUrlsConfig> = increaseFunctionUrlsConfig;
