//@ts-nocheck
import React, { useEffect, useMemo, useState, useRef } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
} from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridCellParams,
  GridSlotsComponent,
  useGridApiRef,
} from '@mui/x-data-grid'
import { useSnackbar } from 'core/contexts/snackBarContext'
import { db } from 'core/config/firebase'
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  onSnapshot,
  orderBy,
  query as firestoreQuery,
  QueryConstraint,
  updateDoc,
  where,
  addDoc,
  writeBatch,
} from 'firebase/firestore'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import DataGridToolBar, { SelectionButtonT } from './DataGridToolBar'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'
import FirestoreDocViewer from './FirestoreDocViewer'

export type ColumnFunctionsType = {
  [key: string]: (id: string, value: string) => void | Promise<void>
}

interface DataGridFirestoreCRUDProps {
  collectionName: string
  columns: GridColDef[]
  hiddenColumns?: string[]
  filters?: [string, WhereFilterOp, string | number][]
  orderByColumn?: string
  staticValues?: string[]
  rowSize?: number
  search?: boolean
  create?: boolean
  editable: boolean
  doubleClickEdit?: boolean
  deleteable?: boolean
  multiDeleteable?: boolean
  onDoubleClick?: (id: string) => void
  onDeleteClick?: (id: string) => void
  customToolbarSelectionButtons?: SelectionButtonT[]
  customColumnSaveFunctions?: ColumnFunctionsType
  viewOnlyMode?: boolean
  canShowDocViewer?: boolean
}

const DataGridFirestoreCRUD: React.FC<DataGridFirestoreCRUDProps> = ({
  collectionName,
  hiddenColumns,
  columns,
  filters,
  orderByColumn,
  search = true,
  create = true,
  editable = true,
  deleteable = true,
  multiDeleteable = false,
  onDoubleClick,
  onDeleteClick,
  customToolbarSelectionButtons,
  customColumnSaveFunctions,
  viewOnlyMode,
  canShowDocViewer = true,
}) => {
  const [openDocViewer, setOpenDocViewer] = useState<boolean>(false)
  const [docViewerId, setDocViewerId] = useState<GridRowId | null>(null)
  const collectionRef = collection(db, collectionName)
  const { showSnackbar } = useSnackbar()
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
    Object.fromEntries((hiddenColumns ?? []).map((columnName) => [columnName, false])),
  )
  const apiRef = useGridApiRef()
  const queryClient = useQueryClient()

  const generateNewRow = useMemo(() => {
    const newRow: DocumentData = {}
    columns.forEach((column) => {
      newRow[column.field] = ''
    })
    return newRow
  }, [columns])

  const firestoreQueryConstraints: QueryConstraint[] = useMemo(() => {
    const constraints: QueryConstraint[] = []
    if (filters) {
      filters.forEach((filter) => {
        constraints.push(where(...filter))
      })
    }
    if (orderByColumn) {
      constraints.push(orderBy(orderByColumn))
    }
    return constraints
  }, [filters, orderByColumn])

  const queryKey = ['firestore', collectionName, filters, orderByColumn]

  const { data: rows = [], isLoading } = useQuery<DocumentData[], Error>({
    queryKey: queryKey,
    queryFn: () =>
      new Promise<DocumentData[]>((resolve, reject) => {
        const q = firestoreQuery(collectionRef, ...firestoreQueryConstraints)
        const unsubscribe = onSnapshot(
          q,
          (snapshot) => {
            const data = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }))
            resolve(data)
          },
          (error) => {
            reject(error)
          },
        )
        return unsubscribe
      }),
    staleTime: Infinity,
    cacheTime: Infinity,
    onError: (error) => {
      showSnackbar('Failed to fetch data', 'error')
      console.error('Error fetching data:', error)
    },
  })

  useEffect(() => {
    const q = firestoreQuery(collectionRef, ...firestoreQueryConstraints)
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        queryClient.setQueryData(queryKey, data)
      },
      (error) => {
        console.error('Error in Firestore subscription:', error)
      },
    )
    return () => {
      unsubscribe()
    }
  }, [collectionRef, firestoreQueryConstraints, queryClient, queryKey])

  const addFirebaseDocument = useMutation<
    DocumentData & { id: string },
    Error,
    DocumentData
  >({
    mutationFn: async (newData: DocumentData) => {
      const docRef = await addDoc(collectionRef, newData)
      return { ...newData, id: docRef.id }
    },
    onError: (error) => {
      showSnackbar('Adding row failed', 'error')
      console.error('Error adding document:', error)
    },
    onSuccess: () => {
      showSnackbar('Row added successfully', 'success')
      queryClient.invalidateQueries(queryKey)
    },
  })

  const handleCreateRow = () => {
    const newRow = generateNewRow
    addFirebaseDocument.mutate(newRow)
  }

  const updateFirebaseDocument = useMutation<
    DocumentData & { id: string },
    Error,
    GridRowModel
  >({
    mutationFn: async (updatedRow: GridRowModel) => {
      const { id, ...data } = updatedRow
      const docRef = doc(collectionRef, id.toString())
      const existingDoc = await getDoc(docRef)
      if (!existingDoc.exists()) {
        throw new Error('Document does not exist')
      }
      const existingData: DocumentData = existingDoc.data()

      if (customColumnSaveFunctions) {
        for (const [columnName, saveFunction] of Object.entries(customColumnSaveFunctions)) {
          if (
            data[columnName] !== undefined &&
            data[columnName] !== existingData[columnName]
          ) {
            await saveFunction(id.toString(), data[columnName])
          }
        }
      }

      Object.keys(data).forEach((key) => {
        if (data[key] === undefined) {
          delete data[key]
        }
      })

      await updateDoc(docRef, data)
      return { ...data, id }
    },
    onError: (error) => {
      showSnackbar('Updating row failed', 'error')
      console.error('Error updating document:', error)
    },
    onSuccess: () => {
      showSnackbar('Row updated successfully', 'success')
      queryClient.invalidateQueries(queryKey)
    },
  })

  const handleUpdateRow = (updatedRow: GridRowModel) => {
    updateFirebaseDocument.mutate(updatedRow)
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const deleteFirebaseDocs = useMutation<
    GridRowId[],
    Error,
    GridRowId[]
  >({
    mutationFn: async (ids: GridRowId[]) => {
      const batch = writeBatch(db)
      ids.forEach((id) => {
        const docRef = doc(db, collectionName, id.toString())
        batch.delete(docRef)
      })
      await batch.commit()
      return ids
    },
    onError: (error, variables) => {
      showSnackbar(`Deleting row${variables.length === 1 ? '' : 's'} failed`, 'error')
      console.error(`Error deleting document${variables.length === 1 ? '' : 's'}:`, error)
    },
    onSuccess: (deletedIds) => {
      showSnackbar(`Deleted ${deletedIds.length} row${deletedIds.length === 1 ? '' : 's'}`, 'success')
      setSelectedRows([])
      queryClient.invalidateQueries(queryKey)
    },
  })

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [deleteMessage, setConfirmDeleteMessage] = useState<string>('')

  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([])

  const handleCancelDelete = () => {
    setOpenDeleteConfirmation(false)
  }

  const handleDeleteRows = (selectedRowIds: GridRowId[]) => {
    setConfirmDeleteMessage(
      `Are you sure you want to delete ${selectedRowIds.length} row${
        selectedRowIds.length === 1 ? '' : 's'
      }?`,
    )
    setOpenDeleteConfirmation(true)
    setSelectedRows(selectedRowIds)
  }

  const confirmDelete = () => {
    if (selectedRows.length > 0) {
      if (onDeleteClick) {
        selectedRows.forEach((id) => onDeleteClick(id.toString()))
      }
      deleteFirebaseDocs.mutate(selectedRows)
      setOpenDeleteConfirmation(false)
    }
  }

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number
    mouseY: number
    rowId: GridRowId
  } | null>(null)

  useEffect(() => {
    const handleCellMouseUp = (params: GridCellParams, event: React.MouseEvent) => {
      if (event.button === 2) {
        if (canShowDocViewer) {
          event.preventDefault()
          setContextMenu(
            contextMenu === null
              ? {
                  mouseX: event.clientX - 2,
                  mouseY: event.clientY - 4,
                  rowId: params.id,
                }
              : null,
          )
        } else {
          setContextMenu(null)
        }
      }
    }

    const unsubscribe = apiRef.current.subscribeEvent('cellMouseUp', handleCellMouseUp)
    return () => {
      unsubscribe()
    }
  }, [apiRef, contextMenu, canShowDocViewer])

  const handleGridContextMenu = (event: React.MouseEvent) => {
    if (canShowDocViewer) {
      event.preventDefault()
    }
  }

  const handleShowFirestoreDoc = (id: GridRowId) => {
    if (canShowDocViewer) {
      setDocViewerId(id)
      setOpenDocViewer(true)
    } else {
      showSnackbar('Document viewer is disabled for your role', 'error')
    }
  }

  const handleCloseDocViewer = () => {
    setOpenDocViewer(false)
    setDocViewerId(null)
  }

  const customColumns: GridColDef[] = columns.map((column) => ({
    ...column,
    editable: editable && column.editable !== false,
  }))

  const handleSave = () => {
    const rowsInEditMode = apiRef.current
      .getAllRowIds()
      .filter((id) => apiRef.current.getRowMode(id) === GridRowModes.Edit)
    rowsInEditMode.forEach((selectedId) => {
      apiRef.current.stopRowEditMode({ id: selectedId })
    })
  }

  const renderDataGrid = useMemo(() => {
    return (
      <Box
        sx={{ height: '70vh', width: '100%' }}
        onContextMenu={handleGridContextMenu}
      >
        <DeleteConfirmationDialog
          open={openDeleteConfirmation}
          title='Confirm Delete'
          content={deleteMessage}
          onConfirm={confirmDelete}
          onCancel={handleCancelDelete}
        />

        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: columnVisibilityModel,
            },
          }}
          onColumnVisibilityModelChange={setColumnVisibilityModel}
          apiRef={apiRef}
          checkboxSelection={multiDeleteable}
          onCellDoubleClick={(params) => {
            if (onDoubleClick) onDoubleClick(String(params.id))
          }}
          rows={isLoading ? [] : rows}
          loading={isLoading}
          autoHeight
          columns={customColumns.map((column, colNum) => ({
            ...column,
            flex: 1,
            minWidth: colNum === customColumns.length - 1 ? 250 : 100,
          }))}
          editMode='row'
          onRowEditStop={handleRowEditStop}
          isCellEditable={() => !viewOnlyMode}
          processRowUpdate={(updatedRow) => {
            handleUpdateRow(updatedRow)
            return updatedRow
          }}
          onProcessRowUpdateError={(error) => console.error('Update Row error:', error)}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectedRows([...newSelectionModel])
          }}
          hideFooterSelectedRowCount
          sx={{
            '& .row-to-delete': {
              backgroundColor: '#ffcccc',
            },
            '& .MuiDataGrid-cell': {
              display: 'flex',
              alignItems: 'center',
              fontSize: '0.8rem',
              padding: '12px',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-columnHeaders': {
              fontSize: '0.8rem',
              padding: '12px',
            },
            '& .MuiDataGrid-columnHeader': {
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            },
          }}
          slots={{
            toolbar: DataGridToolBar as GridSlotsComponent['toolbar'],
          }}
          slotProps={{
            toolbar: {
              collectionName,
              onDeleteRows: handleDeleteRows,
              onCreateRow: handleCreateRow,
              onSaveRows: handleSave,
              search,
              create,
              showQuickFilter: true,
              deleteable: deleteable,
              multiDeleteable: multiDeleteable,
              selectedRows: selectedRows,
              customToolbarSelectionButtons,
            },
          }}
          pageSizeOptions={[25, 50, 100]}
        />
        {canShowDocViewer && contextMenu !== null && (
          <Menu
            open={contextMenu !== null}
            onClose={() => setContextMenu(null)}
            anchorReference='anchorPosition'
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            onMouseLeave={() => setContextMenu(null)}
          >
            <MenuItem
              onClick={() => {
                handleShowFirestoreDoc(contextMenu.rowId)
                setContextMenu(null)
              }}
            >
              Show Firestore Doc
            </MenuItem>
          </Menu>
        )}
        {canShowDocViewer && (
          <Dialog open={openDocViewer} onClose={handleCloseDocViewer} maxWidth='md' fullWidth>
            <DialogTitle>Firestore Document - ID: {docViewerId}</DialogTitle>
            <DialogContent>
              {docViewerId && (
                <FirestoreDocViewer
                  collectionName={collectionName}
                  docId={docViewerId.toString()}
                />
              )}
            </DialogContent>
          </Dialog>
        )}
      </Box>
    )
  }, [
    rows,
    selectedRows,
    openDeleteConfirmation,
    deleteMessage,
    contextMenu,
    columnVisibilityModel,
    customColumns,
    apiRef,
    handleRowEditStop,
    isLoading,
    multiDeleteable,
    viewOnlyMode,
    deleteFirebaseDocs,
    handleDeleteRows,
    handleCreateRow,
    handleSave,
    collectionName,
    customToolbarSelectionButtons,
    deleteable,
    create,
    search,
    openDocViewer,
    docViewerId,
    canShowDocViewer,
    onDoubleClick,
  ])

  return <>{renderDataGrid}</>
}

export default DataGridFirestoreCRUD
