import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Box, Paper, CircularProgress, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import EarningsChart from './components/EarningsChart';
import { getFirestore, collection, query, getDocs, doc, getDoc } from 'firebase/firestore';
import { useAuth } from 'core/contexts/AuthContext';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { useTheme } from '@emotion/react';
import { useCollection } from 'core/hooks/useCollection';
import { useNavigate } from 'react-router-dom';

const FreelancerHome: React.FC = () => {
    const [user, setUser] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [assignments, setAssignments] = useState<ProjectAssignment[]>([]);
    const theme = useTheme();
    const [stats, setStats] = useState({
        amountPaid: 0,
        totalHours: 0,
        averageBillRate: 0,
        uniqueClients: 0
    });
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const subcollectionNamesUsers = useMemo(() => ['projectAssignments'], [])
    const subcollectionNamesClients = useMemo(() => ['projectAssignments', 'projects', 'engineers'], [])
    const usersCollection = useCollection<Client>('users', true, subcollectionNamesUsers)
    const clientsCollection = useCollection<Client>('clients', true, subcollectionNamesClients)


    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();

        if (userInfo?.user_type === 'admin') {
            navigate('/admin');
            return;
        }

        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                await fetchAssignments(currentUser.uid);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        calculateStats();
    }, [assignments, selectedYear]);

    const { userInfo } = useAuth();

    const fetchAssignments = async (userId: string) => {
        const db = getFirestore();
        userId = userInfo?.id || ''
        // userId = "gQdWj68vtINv9csCupuihCF1o313"
        const userAssignmentsRef = collection(db, `users/${userId}/projectAssignments`);
        const userAssignmentsSnapshot = await getDocs(userAssignmentsRef);
        
        const assignmentsData: ProjectAssignment[] = [];

        for (const userAssignmentDoc of userAssignmentsSnapshot.docs) {
            const userAssignmentData = userAssignmentDoc.data();
            const { clientId, assignmentId, projectId } = userAssignmentData;
            
            const projectAssignmentRef = doc(db, `clients/${clientId}/projectAssignments/${assignmentId}`);
            const projectAssignmentDoc = await getDoc(projectAssignmentRef);
            const projectAssignmentData = projectAssignmentDoc.data() as ProjectAssignment;

            if (projectAssignmentData) {
                const projectRef = doc(db, `clients/${clientId}/projects/${projectId}`);
                const projectDoc = await getDoc(projectRef);
                const projectData = projectDoc.data();

                assignmentsData.push({
                    ...projectAssignmentData,
                    id: assignmentId,
                    projectId,
                    projectName: projectData?.name || 'Unknown Project',
                    engineerId: userId,
                    startDate: projectAssignmentData.startDate || null,
                    endDate: projectAssignmentData.endDate || null,
                });
            }
        }
        setAssignments(assignmentsData);
    };

    const calculateYearlyStats = (assignments: ProjectAssignment[], year: number) => {
        const clientSet = new Set();
        let totalPaid = 0;
        let totalHours = 0;
        let totalBillRate = 0;
        let assignmentCount = 0;

        assignments.forEach(assignment => {
            const startDate = assignment.startDate ? new Date(assignment.startDate) : null;
            const endDate = assignment.endDate ? new Date(assignment.endDate) : new Date();

            if (startDate && startDate.getFullYear() <= year && endDate.getFullYear() >= year) {
                clientSet.add(assignment.clientId);

                totalPaid += assignment.amountPaid || 0;
                totalHours += assignment.utilizedHours || 0;
                totalBillRate += assignment.billableRate || 0;
                assignmentCount++;
            }
        });

        return {
            amountPaid: totalPaid,
            totalHours: totalHours,
            averageBillRate: assignmentCount > 0 ? totalBillRate / assignmentCount : 0,
            uniqueClients: clientSet.size
        };
    };

    const calculateStats = () => {
        const yearlyStats = calculateYearlyStats(assignments, selectedYear);
        setStats(yearlyStats);
    };

    const handleYearChange = (event: SelectChangeEvent<number>) => {
        setSelectedYear(event.target.value as number);
    };

    if (loading) {
        return (
            <DashboardLayout>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <Paper elevation={3} sx={{ padding: 4, marginTop: 4, borderRadius: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4 }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                        Welcome, {user?.displayName || user?.email || 'Freelancer'}!
                    </Typography>
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="year-select-label">Year</InputLabel>
                        <Select
                            labelId="year-select-label"
                            id="year-select"
                            value={selectedYear}
                            label="Year"
                            onChange={handleYearChange}
                        >
                            {[...Array(5)].map((_, i) => {
                                const year = new Date().getFullYear() - i;
                                return <MenuItem key={year} value={year}>{year}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Typography variant="body1" sx={{ marginBottom: 4, color: '#555' }}>
                    We're glad to have you here. Your dashboard is ready for you to explore and manage your projects.
                </Typography>
                <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                    <Grid item xs={3}>
                        <Paper elevation={2} sx={{ padding: 2, textAlign: 'center' }}>
                            <Typography variant="h6">${stats.amountPaid.toFixed(2)}</Typography>
                            <Typography variant="body2">Amount Paid</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper elevation={2} sx={{ padding: 2, textAlign: 'center' }}>
                            <Typography variant="h6">{stats.totalHours.toFixed(2)}</Typography>
                            <Typography variant="body2">Total Hours Worked</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper elevation={2} sx={{ padding: 2, textAlign: 'center' }}>
                            <Typography variant="h6">${stats.averageBillRate.toFixed(2)}/hr</Typography>
                            <Typography variant="body2">Average Bill Rate</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper elevation={2} sx={{ padding: 2, textAlign: 'center' }}>
                            <Typography variant="h6">{stats.uniqueClients}</Typography>
                            <Typography variant="body2">Total Unique Clients</Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Typography variant="h6" gutterBottom sx={{ color: '#333' }}>
                    Your Earnings:
                </Typography>
                <EarningsChart assignments={assignments} />
            </Paper>





            
        </DashboardLayout>
    );
};

export default FreelancerHome;