import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Snackbar, Paper, IconButton, Chip, TextField, Autocomplete } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EngineeringIcon from '@mui/icons-material/Engineering';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import EngineerCard from "./EngineerCard";
import {
  addFreelancerToClient,
  removeFreelancerFromClient,
  getFreelancersForClient,
  updateClientFreelancer
} from 'core/api/freelancer';
import { AddEngineerDialog } from "./AddEngineerDialog";
import { useLocalStorageBoolState } from "core/utils";

interface AssociatedEngineersProps {
  clientId: string;
}

const AssociatedEngineers: React.FC<AssociatedEngineersProps> = ({ clientId }) => {
  const [openEngineerDialog, setOpenEngineerDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [engineers, setEngineers] = useState<Freelancer[]>([]);
  const [filteredEngineers, setFilteredEngineers] = useState<Freelancer[]>([]);
  const [expandedEngineerId, setExpandedEngineerId] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useLocalStorageBoolState("isAssociatedEngineersVisible", false);
  const [statusFilters, setStatusFilters] = useState<string[]>([]);

  useEffect(() => {
    loadEngineers();
  }, [clientId]);

  useEffect(() => {
    filterEngineers();
  }, [engineers, statusFilters]);

  const loadEngineers = async () => {
    try {
      const clientEngineers = await getFreelancersForClient(clientId);
      setEngineers(clientEngineers);
    } catch (error) {
      console.error("Error loading engineers:", error);
      setError("Failed to load engineers. Please try again.");
    }
  };

  const filterEngineers = () => {
    if (statusFilters.length === 0) {
      setFilteredEngineers(engineers);
    } else {
      setFilteredEngineers(engineers.filter(engineer => statusFilters.includes(engineer.status)));
    }
  };

  const handleAddEngineer = async (engineerData: Freelancer) => {
    try {
      if (engineers.some(eng => eng.id === engineerData.id)) {
        setError("This engineer is already associated with the client.");
        return;
      }

      const newEngineer = await addFreelancerToClient(clientId, engineerData);
      setEngineers([...engineers, newEngineer]);
    } catch (error) {
      console.error("Error adding engineer:", error);
      setError("Failed to add engineer. Please try again.");
    }
  };

  const handleRemoveEngineer = async (engineerId: string) => {
    try {
      await removeFreelancerFromClient(clientId, engineerId);
      setEngineers(engineers.filter(engineer => engineer.id !== engineerId));
      if (expandedEngineerId === engineerId) {
        setExpandedEngineerId(null);
      }
    } catch (error) {
      console.error("Error removing engineer:", error);
      setError("Failed to remove engineer. Please try again.");
    }
  };

  const handleAddAssignment = async (clientId: string, engineerId: string, assignmentData: Omit<ProjectAssignment, 'id'>, file: File) => {
    try {
      await uploadProjectAssignment(clientId, engineerId, assignmentData, file);
      loadEngineers();
    } catch (error) {
      console.error("Error adding project assignment:", error);
      setError("Failed to add project assignment. Please try again.");
    }
  };

  const handleUpdateEngineerStatus = async (engineerId: string, newStatus: Freelancer['status']) => {
    try {
      await updateClientFreelancer(clientId, engineerId, { status: newStatus });
      setEngineers(engineers.map(eng => 
        eng.id === engineerId ? { ...eng, status: newStatus } : eng
      ));
    } catch (error) {
      console.error("Error updating engineer status:", error);
      setError("Failed to update engineer status. Please try again.");
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleAccordionChange = (engineerId: string, isExpanded: boolean) => {
    setExpandedEngineerId(isExpanded ? engineerId : null);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const getStatusColor = (status: Freelancer['status']) => {
    switch (status) {
      case 'ready': return '#3498db'; // Blue
      case 'active': return '#2ecc71'; // Green
      case 'inactive': return '#e74c3c'; // Red
      default: return '#95a5a6'; // Grey
    }
  };

  return (
    <Box>
      <Paper 
        elevation={3}
        sx={{
          mb: 3,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: 'linear-gradient(45deg, #757575 30%, #9E9E9E 90%)',
          color: 'white',
        }}
      >
        <Box display="flex" alignItems="center">
          <EngineeringIcon sx={{ fontSize: 40, mr: 2 }} />
          <Typography variant="h5" component="h2" sx={{ mr: 2 }}>Engineers</Typography>
          <Autocomplete
            multiple
            options={['ready', 'active', 'inactive']}
            value={statusFilters}
            onChange={(event, newValue) => {
              setStatusFilters(newValue);
            }}
            renderInput={(params) => (
              isVisible && (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder={statusFilters.length === 0 ? "Status filter..." : ""}
                sx={{ 
                  width: 300,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'white',
                  },
                  '& .MuiInputBase-input': {
                    color: 'white',
                  },
                  '& .MuiAutocomplete-input': {
                    color: 'white',
                  },
                }}
              />
                )
            )}
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: getStatusColor(option as Freelancer['status']),
                    color: 'white',
                    '& .MuiChip-deleteIcon': {
                      color: 'white',
                    },
                  }}
                  deleteIcon={<CloseIcon />}
                />
              ))
            }
          />
            <IconButton onClick={toggleVisibility} sx={{ color: 'white', mr: 1 }}>
            {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Box>
        <Button
          onClick={() => setOpenEngineerDialog(true)}
          startIcon={<AddIcon />}
          variant="contained"
          sx={{ 
            bgcolor: '#424242',
            color: 'white',
            '&:hover': {
              bgcolor: '#616161',
            },
          }}
        >
          Add Engineer
        </Button>
      </Paper>
      
      {isVisible && (
        <Box display="flex" justifyContent="center" mt={4} mb={2}>
          {filteredEngineers.length === 0 && (
            <Typography>No engineers found.</Typography>
          )}
        </Box>
      )}
      
      {isVisible && (
      <Grid container spacing={3}>
        {filteredEngineers.map((engineer) => (
          <Grid item xs={12} sm={expandedEngineerId === engineer.id ? 12 : 6} key={engineer.id}>
            <EngineerCard
              engineer={engineer}
              onRemove={() => handleRemoveEngineer(engineer.id)}
              clientId={clientId}
              onAddAssignment={handleAddAssignment}
              onUpdateStatus={handleUpdateEngineerStatus}
              onAccordionChange={(isExpanded) => handleAccordionChange(engineer.id, isExpanded)}
              isExpanded={expandedEngineerId === engineer.id}
            />
          </Grid>
        ))}
      </Grid>
      )}
      <AddEngineerDialog
        open={openEngineerDialog}
        onClose={() => setOpenEngineerDialog(false)}
        onAdd={handleAddEngineer}
        clientId={clientId}
        currentEngineers={engineers}
      />
      
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={error}
      />
    </Box>
  );
};

export default AssociatedEngineers;

function uploadProjectAssignment(clientId: string, engineerId: string, assignmentData: Omit<ProjectAssignment, "id">, file: File) {
    throw new Error("Function not implemented.");
}
