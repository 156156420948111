import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  FormHelperText,
  Snackbar,
  Alert,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { getProjectCSAs } from "core/api/projects/getProjectCsaDocs";
import { getClientProjects } from 'core/api/projects';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

interface AddProjectAssignmentDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (clientId: string, engineerId: string, assignmentData: Omit<ProjectAssignment, 'id'>, file: File | null) => Promise<void>;
  clientId: string;
  engineerId: string;
}

const AddProjectAssignmentDialog: React.FC<AddProjectAssignmentDialogProps> = ({
  open,
  onClose,
  onAdd,
  clientId,
  engineerId,
}) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [csas, setCSAs] = useState<ConsultingServicesAgreement[]>([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedCSA, setSelectedCSA] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [billableRate, setBillableRate] = useState('');
  const [budgetedHours, setBudgetedHours] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState<ProjectAssignmentStatus>('interviewing');
  const [fileRequired, setFileRequired] = useState(false);
  const [utilizedHours, setUtilizedHours] = useState('');
  const [amountPaid, setAmountPaid] = useState('');
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'error' | 'success' }>({
    open: false,
    message: '',
    severity: 'error',
  });

  useEffect(() => {
    if (open) {
      loadProjects();
    }
  }, [open, clientId]);

  useEffect(() => {
    if (selectedProject) {
      loadCSAs();
    }
  }, [selectedProject]);

  const loadProjects = async () => {
    try {
      const clientProjects = await getClientProjects(clientId);
      setProjects(clientProjects);
    } catch (error) {
      console.error('Error loading projects:', error);
    }
  };

  const loadCSAs = async () => {
    try {
      const projectCSAs = await getProjectCSAs(clientId, selectedProject);
      setCSAs(projectCSAs);
    } catch (error) {
      console.error('Error loading CSAs:', error);
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'application/pdf': ['.pdf'], 'application/msword': ['.doc'], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] } });

  const handleStatusChange = (newStatus: ProjectAssignmentStatus) => {
    setStatus(newStatus);
    setFileRequired(newStatus !== 'interviewing' && newStatus !== 'ready');
  };

  const isFieldRequired = (fieldName: string) => {
    if (status === 'in_progress') {
      return ['project', 'billableRate', 'budgetedHours', 'file'].includes(fieldName);
    } else if (status === 'complete') {
      return ['startDate', 'endDate', 'utilizedHours', 'amountPaid', 'project', 'billableRate', 'budgetedHours', 'file'].includes(fieldName);
    }
    return false;
  };

  const isFieldError = (fieldName: string) => {
    return !!errors[fieldName] && !eval(fieldName);
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (isFieldRequired('project') && !selectedProject) newErrors.project = 'Project is required';
    if (isFieldRequired('billableRate') && !billableRate) newErrors.billableRate = 'Billable rate is required';
    if (isFieldRequired('budgetedHours') && !budgetedHours) newErrors.budgetedHours = 'Budgeted hours are required';
    if (isFieldRequired('file') && !file) newErrors.file = 'File is required';
    if (isFieldRequired('startDate') && !startDate) newErrors.startDate = 'Start date is required';
    if (isFieldRequired('endDate') && !endDate) newErrors.endDate = 'End date is required';
    if (isFieldRequired('utilizedHours') && !utilizedHours) newErrors.utilizedHours = 'Utilized hours are required';
    if (isFieldRequired('amountPaid') && !amountPaid) newErrors.amountPaid = 'Amount paid is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isFormValid = () => {
    const requiredFields = ['selectedProject', 'status'];
    if (status === 'in_progress' || status === 'complete') {
      requiredFields.push('billableRate', 'budgetedHours');
    }
    if (status === 'complete') {
      requiredFields.push('startDate', 'endDate', 'utilizedHours', 'amountPaid');
    }
    
    return requiredFields.every(field => {
      const value = eval(field);
      return value !== null && value !== undefined && value !== '';
    }) && (file !== null || !fileRequired);
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      setSnackbar({ open: true, message: 'Please fill in all required fields', severity: 'error' });
      return;
    }

    setIsSubmitting(true);

    let fileUrl: string | null = null;
    if (file) {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, `clients/${clientId}/projectAssignments/${selectedCSA}`);
        
        await uploadBytes(storageRef, file);
        fileUrl = await getDownloadURL(storageRef);
      } catch (error) {
        console.error("Error uploading file:", error);
        setIsSubmitting(false);
        // Show error message about file upload failure
        return;
      }
    }

    const assignmentData: Omit<ProjectAssignment, 'id'> = {
      projectId: selectedProject,
      csaId: selectedCSA || null,
      engineerId,
      billableRate: billableRate ? parseFloat(billableRate) : null,
      budgetedHours: budgetedHours ? parseInt(budgetedHours) : null,
      status,
      startDate: startDate || null,
      endDate: endDate || null,
      fileUrl: fileUrl,
      utilizedHours: utilizedHours ? parseInt(utilizedHours) : null,
      amountPaid: amountPaid ? parseFloat(amountPaid) : null,
      clientId: clientId
    };

    try {
      await onAdd(clientId, engineerId, assignmentData, file);
      onClose();
    } catch (error) {
      console.error('Error adding project assignment:', error);
      // Show error message
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Add Project Assignment</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal" error={!!errors.project} required={isFieldRequired('project')}>
            <InputLabel>Project {isFieldRequired('project') && '*'}</InputLabel>
            <Select value={selectedProject} onChange={(e) => setSelectedProject(e.target.value)}>
              {projects.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
            {errors.project && <FormHelperText>{errors.project}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Consulting Services Agreement</InputLabel>
            <Select
              value={selectedCSA}
              onChange={(e) => setSelectedCSA(e.target.value as string)}
              disabled={!selectedProject}
            >
              <MenuItem value="">None</MenuItem>
              {csas.map((csa) => (
                <MenuItem key={csa.id} value={csa.id}>
                  {csa.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!errors.status} required>
            <InputLabel>Status *</InputLabel>
            <Select value={status} onChange={(e) => handleStatusChange(e.target.value as ProjectAssignmentStatus)}>
              <MenuItem value="interviewing">Interviewing</MenuItem>
              <MenuItem value="ready">Ready</MenuItem>
              <MenuItem value="in_progress">In Progress</MenuItem>
              <MenuItem value="complete">Complete</MenuItem>
            </Select>
            {errors.status && <FormHelperText>{errors.status}</FormHelperText>}
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required={isFieldRequired('startDate')}
            error={!!errors.startDate}
            helperText={errors.startDate}
          />
          <TextField
            fullWidth
            margin="normal"
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required={isFieldRequired('endDate')}
            error={!!errors.endDate}
            helperText={errors.endDate}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Billable Rate"
            type="number"
            value={billableRate}
            onChange={(e) => setBillableRate(e.target.value)}
            error={!!errors.billableRate}
            helperText={errors.billableRate}
            required={isFieldRequired('billableRate')}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Budgeted Hours"
            type="number"
            value={budgetedHours}
            onChange={(e) => setBudgetedHours(e.target.value)}
            error={!!errors.budgetedHours}
            helperText={errors.budgetedHours}
            required={isFieldRequired('budgetedHours')}
          />

          {status === 'complete' && (
            <>
              <TextField
                fullWidth
                margin="normal"
                label="Utilized Hours"
                type="number"
                value={utilizedHours}
                onChange={(e) => setUtilizedHours(e.target.value)}
                error={!!errors.utilizedHours}
                helperText={errors.utilizedHours}
                required={isFieldRequired('utilizedHours')}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Amount Paid"
                type="number"
                value={amountPaid}
                onChange={(e) => setAmountPaid(e.target.value)}
                error={!!errors.amountPaid}
                helperText={errors.amountPaid}
                required={isFieldRequired('amountPaid')}
              />
            </>
          )}
          <Box {...getRootProps()} sx={{ mt: 2, p: 2, border: '1px dashed grey', borderRadius: 1 }}>
            <input {...getInputProps()} />
            <Typography>
              {file ? `Selected file: ${file.name}` : 'Drag and drop a file here, or click to select a file'}
            </Typography>
          </Box>
          {errors.file && <FormHelperText error>{errors.file}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" disabled={isSubmitting || !isFormValid()}>
            {isSubmitting ? 'Adding...' : 'Add Assignment'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddProjectAssignmentDialog;