import { clockifyApiUrl, clockifyReportsUrl, getHeaders } from '../../constants/clockify'
import { ClockifyTimeEntry, ClockifyError } from './types'

interface RequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  endpoint: string
  body?: any
  queryParams?: Record<string, string>
  baseUrl?: string
}

const buildUrl = (baseUrl: string, endpoint: string, queryParams?: Record<string, string>): string => {
  const url = new URL(`${baseUrl}${endpoint}`)
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== undefined) {
        url.searchParams.append(key, value)
      }
    })
  }
  return url.toString()
}

export const apiRequest = async ({ method, endpoint, body, queryParams, baseUrl }: RequestOptions) => {
  const url = buildUrl(baseUrl || clockifyApiUrl, endpoint, queryParams)
  const options: RequestInit = {
    method,
    headers: getHeaders(),
    body: body ? JSON.stringify(body) : undefined,
  }

  try {
    const response = await fetch(url, options)

    if (!response.ok) {
      let errorData: ClockifyError | string = await response.text()
      try {
        errorData = JSON.parse(errorData)
      } catch {
        console.error("Something went wrong sorry")
      }
      throw new Error(
        `API request failed: ${response.status} ${response.statusText} - ${JSON.stringify(
          errorData
        )}`
      )
    }

    const contentType = response.headers.get('Content-Type')
    if (contentType && contentType.includes('application/json')) {
      return response.json()
    } else {
      return response.text()
    }
  } catch (error) {
    console.error('Error making API request:', error)
    throw error
  }
}

export const get = async (
  endpoint: string,
  queryParams?: Record<string, string>,
  baseUrl?: string
) => {
  return apiRequest({ method: 'GET', endpoint, queryParams, baseUrl })
}

export const post = async (
  endpoint: string,
  body: any,
  queryParams?: Record<string, string>,
  baseUrl?: string
) => {
  return apiRequest({ method: 'POST', endpoint, body, queryParams, baseUrl })
}

export const put = async (
  endpoint: string,
  body: any,
  queryParams?: Record<string, string>,
  baseUrl?: string
) => {
  return apiRequest({ method: 'PUT', endpoint, body, queryParams, baseUrl })
}

export const patch = async (
  endpoint: string,
  body: any,
  queryParams?: Record<string, string>,
  baseUrl?: string
) => {
  return apiRequest({ method: 'PATCH', endpoint, body, queryParams, baseUrl })
}

export const del = async (
  endpoint: string,
  queryParams?: Record<string, string>,
  baseUrl?: string
) => {
  return apiRequest({ method: 'DELETE', endpoint, queryParams, baseUrl })
}
