import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardContent,
  Box,
  Chip,
  Typography,
  Grid,
  IconButton,
  TextField,
  MenuItem,
  Autocomplete,
  useTheme,
  Button,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { getEngineerEmailOrNameById } from "core/api/engineers";
import { readCSA } from "core/api/csa/readCSA";
import { getClientProjects } from "core/api/projects";
import { getProjectCSAs } from "core/api/csa";
import { deleteClientPayment } from "core/api/payments";
import ConfirmDialog from "components/ConfirmDialog";

interface PaymentCardProps {
  clientId: string;
  payment: Payment;
  projectAssignments: ProjectAssignment[];
  onUpdate: (updatedPayment: Payment) => void;
  onAdd: (addedPayment: Payment) => void;
  onCancel: () => void;
  isNew?: boolean;
}

const PaymentCard: React.FC<PaymentCardProps> = ({
  clientId,
  payment,
  projectAssignments,
  onUpdate,
  onAdd,
  onCancel,
  isNew = false,
}) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(isNew);
  const [editedPayment, setEditedPayment] = useState<Payment>(payment);
  const [engineerNames, setEngineerNames] = useState<{ [key: string]: string }>(
    {}
  );
  const [projects, setProjects] = useState<Project[]>([]);
  const [csas, setCsas] = useState<ConsultingServicesAgreement[]>([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  useEffect(() => {
    const fetchEngineerNames = async () => {
      const names: { [key: string]: string } = {};
      for (const assignment of projectAssignments) {
        if (!names[assignment.engineerId]) {
          names[assignment.engineerId] = await getEngineerEmailOrNameById(
            assignment.engineerId
          );
        }
      }
      setEngineerNames((prevNames) => ({ ...prevNames, ...names }));
    };

    fetchEngineerNames();
  }, [projectAssignments]);

  useEffect(() => {
    const fetchProjects = async () => {
      const fetchedProjects = await getClientProjects(clientId);
      setProjects(fetchedProjects);
    };
    fetchProjects();
  }, [clientId]);

  useEffect(() => {
    const fetchCSAs = async () => {
      if (editedPayment.projectId) {
        const fetchedCSAs = await getProjectCSAs(
          clientId,
          editedPayment.projectId
        );
        setCsas(fetchedCSAs);
      }
    };
    fetchCSAs();
  }, [clientId, editedPayment.projectId]);

  const getStatusColor = (status: Payment["status"]) => {
    switch (status) {
      case "paid":
        return theme.palette.success.main;
      case "pending":
        return theme.palette.warning.main;
      case "overdue":
        return theme.palette.error.main;
      default:
        return theme.palette.text.disabled;
    }
  };

  const getCardBackgroundColor = (status: Payment["status"]) => {
    const baseColor = theme.palette.background.paper;
    const alpha = theme.palette.mode === "light" ? 0.1 : 0.2;
    switch (status) {
      case "paid":
        return `linear-gradient(135deg, ${theme.palette.success.main}${alpha} 0%, ${baseColor} 100%)`;
      case "pending":
        return `linear-gradient(135deg, ${theme.palette.warning.main}${alpha} 0%, ${baseColor} 100%)`;
      case "overdue":
        return `linear-gradient(135deg, ${theme.palette.error.main}${alpha} 0%, ${baseColor} 100%)`;
      default:
        return baseColor;
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    if (isNew) {
      onAdd(editedPayment);
    } else {
      onUpdate(editedPayment);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedPayment((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setEditedPayment((prev) => ({ ...prev, [name]: value }));
  };

  const handleAssignmentChange = (
    event: React.SyntheticEvent,
    value: ProjectAssignment[]
  ) => {
    setEditedPayment((prev) => ({
      ...prev,
      projectAssignments: value.map((assignment) => assignment.id),
    }));
  };

  const uniqueProjectAssignments = useMemo(() => {
    const uniqueAssignments = new Map();
    projectAssignments.forEach((assignment) => {
      if (!uniqueAssignments.has(assignment.id)) {
        uniqueAssignments.set(assignment.id, assignment);
      }
    });
    return Array.from(uniqueAssignments.values());
  }, [projectAssignments]);

  const [csaName, setCsaName] = useState("");

  useEffect(() => {
    const fetchCSAName = async () => {
      try {
        const csa = await readCSA(clientId, payment.projectId, payment.csaId);
        setCsaName(csa?.name || "");
      } catch (error) {
        console.error("Error fetching CSA name:", error);
        setCsaName("");
      }
    };

    fetchCSAName();
  }, [clientId, payment.projectId, payment.csaId]);

  const handleProjectChange = async (event: SelectChangeEvent<string>) => {
    const projectId = event.target.value;
    const selectedProject = projects.find((p) => p.id === projectId);
    setEditedPayment((prev) => ({
      ...prev,
      projectId,
      projectName: selectedProject?.name || "",
      csaId: "", // Reset CSA when project changes
    }));

    // Fetch CSAs for the selected project
    const fetchedCSAs = await getProjectCSAs(clientId, projectId);
    setCsas(fetchedCSAs);
  };

  const handleCSAChange = (event: SelectChangeEvent<string>) => {
    const csaId = event.target.value;
    setEditedPayment(prev => ({ 
      ...prev, 
      csaId,
      projectAssignments: prev.projectAssignments.filter(paId => {
        const assignment = uniqueProjectAssignments.find(pa => pa.id === paId);
        return assignment && assignment.csaId === csaId;
      })
    }));
  };

  const handleCancel = () => {
    onCancel();
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteClientPayment(clientId, payment.id);
      onCancel?.(); // This will close the card
    } catch (error) {
      console.error("Error deleting payment:", error);
      // You might want to show an error message to the user here
    }
    setDeleteConfirmOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const renderAssignmentChip = (assignment: ProjectAssignment) => (
    <Chip
      key={assignment.id}
      label={`${engineerNames[assignment.engineerId] || "Loading..."} (${assignment.startDate} - ${assignment.endDate})`}
      size="small"
    />
  );

  const renderDisplayView = () => (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontWeight: "bold" }}
        >
          Invoice: {payment.invoiceNumber}
        </Typography>
        <Chip
          label={payment.status}
          size="small"
          sx={{
            backgroundColor: getStatusColor(payment.status),
            color: "white",
            fontWeight: "bold",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2">
          Amount:{" "}
          <span style={{ fontWeight: "bold" }}>
            $
            {typeof payment.amount === "number"
              ? payment.amount.toFixed(2)
              : payment.amount}
          </span>
        </Typography>
        <Typography variant="body2">
          Due:{" "}
          <span style={{ fontWeight: "bold" }}>
            {new Date(payment.dueDate).toLocaleDateString()}
          </span>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2">
          Sent:{" "}
          <span style={{ fontWeight: "bold" }}>
            {new Date(payment.sentDate).toLocaleDateString()}
          </span>
        </Typography>
        <Typography variant="body2">
          Processed:{" "}
          <span style={{ fontWeight: "bold" }}>
            {payment.processedDate
              ? new Date(payment.processedDate).toLocaleDateString()
              : "N/A"}
          </span>
        </Typography>
      </Box>
      <Typography variant="body2">
        Payment Source:{" "}
        <span style={{ fontWeight: "bold" }}>{payment.paymentSource}</span>
      </Typography>
      <Box>
        <Typography variant="body2" gutterBottom>
          Project Assignments:
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {payment.projectAssignments.map((assignmentId) => {
            const assignment = projectAssignments.find(
              (pa) => pa.id === assignmentId
            );
            return assignment ? (
              <Chip
                key={assignment.id}
                label={`${engineerNames[assignment.engineerId] || "Loading..."} (${assignment.startDate} - ${assignment.endDate})`}
                size="small"
              />
            ) : null;
          })}
        </Box>
      </Box>
    </Box>
  );

  const renderEditView = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth size="small">
          <InputLabel>Project</InputLabel>
          <Select
            value={editedPayment.projectId || ''}
            onChange={handleProjectChange}
            label="Project"
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth size="small">
          <InputLabel>CSA</InputLabel>
          <Select
            value={editedPayment.csaId || ''}
            onChange={handleCSAChange}
            label="CSA"
            disabled={!editedPayment.projectId}
          >
            {csas.map((csa) => (
              <MenuItem key={csa.id} value={csa.id}>
                {csa.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          size="small"
          label="Amount"
          type="number"
          name="amount"
          value={editedPayment.amount}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth size="small">
          <InputLabel>Status</InputLabel>
          <Select
            name="status"
            value={editedPayment.status}
            onChange={handleSelectChange}
            label="Status"
          >
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="overdue">Overdue</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          size="small"
          label="Sent Date"
          type="date"
          name="sentDate"
          value={editedPayment.sentDate}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          size="small"
          label="Due Date"
          type="date"
          name="dueDate"
          value={editedPayment.dueDate}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          size="small"
          label="Processed Date"
          type="date"
          name="processedDate"
          value={editedPayment.processedDate || ""}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          size="small"
          label="Invoice Number"
          name="invoiceNumber"
          value={editedPayment.invoiceNumber}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small">
          <InputLabel>Payment Source</InputLabel>
          <Select
            name="paymentSource"
            value={editedPayment.paymentSource}
            onChange={handleSelectChange}
            label="Payment Source"
          >
            <MenuItem value="Quickbooks">Quickbooks</MenuItem>
            <MenuItem value="Zelle">Zelle</MenuItem>
            <MenuItem value="Venmo">Venmo</MenuItem>
            <MenuItem value="CashApp">CashApp</MenuItem>
            <MenuItem value="Check">Check</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          size="small"
          options={uniqueProjectAssignments.filter(pa => pa.csaId === editedPayment.csaId)}
          getOptionLabel={(option) => `${engineerNames[option.engineerId] || 'Loading...'} (${option.startDate} - ${option.endDate})`}
          value={uniqueProjectAssignments.filter(pa => editedPayment.projectAssignments.includes(pa.id) && pa.csaId === editedPayment.csaId)}
          onChange={handleAssignmentChange}
          renderInput={(params) => <TextField {...params} label="Project Assignments" variant="outlined" />}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => renderAssignmentChip({ ...getTagProps({ index }), ...option }))
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Grid>
    </Grid>
  );

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        transition: "all 0.3s",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: theme.shadows[6],
        },
        background: getCardBackgroundColor(payment.status),
        color: theme.palette.text.primary,
      }}
    >
      <CardContent sx={{ flexGrow: 1, p: 2, position: "relative" }}>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" component="div" color="text.primary">
            {isNew ? "New Payment" : payment.projectName}
          </Typography>
          <Box>
            {isEditing || isNew ? (
              <>
                {!isNew && (
                  <IconButton
                    onClick={handleDeleteClick}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
                <IconButton
                  onClick={handleSave}
                  color="primary"
                  size="small"
                  sx={{ mr: 1 }}
                >
                  <SaveIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={handleCancel}
                  color="secondary"
                  size="small"
                  sx={{ mr: 1 }}
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={handleEdit} color="primary" size="small">
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Box>
        {!isNew && (
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {csaName}
          </Typography>
        )}
        {isEditing || isNew ? renderEditView() : renderDisplayView()}
      </CardContent>
      <ConfirmDialog
        open={deleteConfirmOpen}
        title="Confirm Delete"
        content="Are you sure you want to delete this payment? This action cannot be undone."
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      />
    </Card>
  );
};

export default PaymentCard;