import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import { DocumentState } from '../ProjectAssignmentGenerator';
import { Field } from 'core/api/functionUrls/types';

interface PAGeneratorVerificationProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  documents: DocumentState[];
}

export const PAGeneratorVerification: React.FC<PAGeneratorVerificationProps> = ({
  open,
  onClose,
  onConfirm,
  documents,
}) => {
  const findFieldValue = (doc: DocumentState, identifier: string): string => {
    return doc.fields.find((f: Field) => f.identifier === identifier)?.value || '';
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Confirm Project Assignments</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Please review the following project assignments before generating:
        </Typography>
        <List>
          {documents.map((doc, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={`Project Assignment ${index + 1}`}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="text.primary">
                        Client: {findFieldValue(doc, 'client_name')}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="text.primary">
                        Project: {findFieldValue(doc, 'project_name')}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="text.primary">
                        Effective Date: {findFieldValue(doc, 'effective_date')}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="text.primary">
                        Conclusion Date: {findFieldValue(doc, 'conclusion_date')}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="text.primary">
                        Billable Rate: ${findFieldValue(doc, 'billable_rate')}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="text.primary">
                        Maximum Billable Hours: {findFieldValue(doc, 'maximum_billable_hours')}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="text.primary">
                        Admin Signer: {doc.signers[0]?.email}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="text.primary">
                        Freelancer Signer: {doc.signers[1]?.email}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              {index < documents.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Confirm and Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
