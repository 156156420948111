import { getDocument, updateDocument } from "core/api/firestore";
import { uploadFile } from "core/api/storage";

export const updateProjectAssignment = async (
  clientId: string,
  assignmentId: string,
  updatedAssignment: Partial<ProjectAssignment>,
  file?: File | null
): Promise<ProjectAssignment> => {
  const cleanedAssignment = Object.entries(updatedAssignment).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null) {
      acc[key as keyof ProjectAssignment] = value as any;
    }
    return acc;
  }, {} as Partial<ProjectAssignment>);

  if (file) {
    const fileUrl = await uploadFile(`clients/${clientId}/projectAssignments/${assignmentId}`, file);
    cleanedAssignment.fileUrl = fileUrl;
  }
  
  await updateDocument(`clients/${clientId}/projectAssignments/${assignmentId}`, cleanedAssignment);


  const output = await getDocument(`clients/${clientId}/projectAssignments/${assignmentId}`);

  return output as ProjectAssignment;
};
