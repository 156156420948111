import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Chip, Box } from '@mui/material';
import { readCSA } from "core/api/csa/readCSA";
import { getProjectName } from "core/api/projects";
import { readClientDoc } from 'core/api/clients';

interface ProjectAssignmentCardProps {
  assignment: ProjectAssignment;
  onViewFile: (assignment: ProjectAssignment) => void;
  getStatusColor: (status: ProjectAssignmentStatus) => "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
}

const ProjectAssignmentCard: React.FC<ProjectAssignmentCardProps> = ({ 
  assignment, 
  onViewFile, 
  getStatusColor 
}) => {
  const [projectName, setProjectName] = useState<string | null>(null);
  const [csaName, setCSAName] = useState<string | null>(null);
  const [clientName, setClientName] = useState<string | null | undefined>(null);

  useEffect(() => {
    const fetchNames = async () => {
      if (assignment.projectId && assignment.clientId) {
        const fetchedProjectName = await getProjectName(assignment.clientId, assignment.projectId);
        setProjectName(fetchedProjectName);
      }
      if (assignment.csaId && assignment.clientId) {
        const csa = await readCSA(assignment.clientId, assignment.projectId, assignment.csaId);
        setCSAName(csa?.name || null);
      }

      if (assignment.clientId) {
        const fetchedClient = await readClientDoc(assignment.clientId)
        setClientName(fetchedClient?.companyName)
      }

    };

    fetchNames();
  }, [assignment]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>{projectName || assignment.projectName}</Typography>
        <Typography variant="body2" gutterBottom>
          Client: {clientName || 'N/A'}
        </Typography>
        {/* <Typography variant="body2" gutterBottom>
          CSA: {csaName || 'N/A'}
        </Typography> */}
        <Chip 
          label={assignment.status} 
          color={getStatusColor(assignment.status)}
          size="small"
          sx={{ mb: 1 }}
        />
        <Typography variant="body2">
          Start Date: {assignment.startDate || 'Not set'}
        </Typography>
        {assignment.endDate && (
          <Typography variant="body2">
            End Date: {assignment.endDate}
          </Typography>
        )}
        <Typography variant="body2">
          Billable Rate: ${assignment.billableRate || 0}/hr
        </Typography>
        {assignment.fileUrl && (
          <Box mt={1}>
            <Typography 
              variant="body2" 
              color="primary" 
              sx={{ cursor: 'pointer' }}
              onClick={() => onViewFile(assignment)}
            >
              View Contract
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ProjectAssignmentCard;