import { workspaceId } from "core/constants/clockify"
import { get, post } from "./requests"
import { MemberProfile } from "./types"
export const getUserByEmail = async (email: string) => {
    try {
        const user = await post(`/workspaces/${workspaceId}/users/info`, { email })
        return user
    } catch (error) {
        console.error('Failed to fetch user by email:', error)
        throw error
    }
}
export const getUserById = async (userId: string): Promise<MemberProfile> => {
    try {
        const user = await get(`/workspaces/${workspaceId}/member-profile/${userId}`, {})
        return user
    } catch (error) {
        console.error('Failed to fetch user by id:', error)
        throw error
    }
}