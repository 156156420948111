import { getDocument } from './firestore';

export const getEngineerEmailOrNameById = async (engineerId: string): Promise<string> => {
  try {
    const engineer = await getDocument<Freelancer>(`users/${engineerId}`);
    if (engineer) {
      return engineer.email || engineer?.firstName + `${engineer?.lastName}` || engineerId;
    }
    return engineerId;
  } catch (error) {
    console.error(`Error fetching engineer data for ID ${engineerId}:`, error);
    return engineerId;
  }
};