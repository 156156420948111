import { getHeaders, functionUrls } from 'core/api'

export const sendRequestedUserEmail = async (email: string, first_name: string, last_name: string) => {
  const res = await fetch(functionUrls.mail.sendEmail.url, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ email, first_name, last_name }),
  })
  if (res.status === 200) {
    return true
  } else {
    return false
  }
}
