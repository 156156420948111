import { getHeaders } from 'core/api';
import { increaseFunctionUrls as ifu } from '../increaseFunctionUrls';
import Increase from 'increase';
interface AccountDetailsByIdOutput {
    accountDetails: Increase.Account;
    balance: {
        available_balance: number;
        current_balance: number;
    }
}

export const getAccountDetailsById = async (accountId: string): Promise<AccountDetailsByIdOutput> => {
  try {
    
    const detailsResponse = await fetch(ifu.accounts.retrieveAccountDetails.url, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ accountId }),
    });

    const detailsData: typeof ifu.accounts.retrieveAccountDetails.output = await detailsResponse.json();

    const balanceResponse = await fetch(ifu.accounts.retrieveAccountBalance.url, {
      method: 'POST',
      headers: getHeaders(),
      // body: encryptPayload({ accountId }),
      body: JSON.stringify({ accountId }),
    });

    const data: typeof ifu.accounts.retrieveAccountBalance.output = await balanceResponse.json();
    
    const available_balance_dollars = data.result.available_balance/100;

    const current_balance_dollars = data.result.current_balance/100;


    return {
        accountDetails: detailsData,
        balance: {
            available_balance: available_balance_dollars,
            current_balance: current_balance_dollars
        }
    };
  } catch (error) {
    console.error('Error retrieving account balance:', error);
    throw error;
  }
};
