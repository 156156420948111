import { doc, getDoc, getFirestore } from "firebase/firestore";

const db = getFirestore();

export const getUserProfile = async (userId: string): Promise<UserInfoType | null> => {
  const userRef = doc(db, `users/${userId}`);
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    const docData = docSnap.data();
    return {
      id: docSnap.id,
      email: docData.email,
      firstName: docData.firstName,
      lastName: docData.lastName,
      is_active: docData.is_active,
      emailVerified: docData.email_verified,
      user_type: docData.user_type,
      notes: docData.notes,
      profilePicture: docData.profile_picture,
      skills: docData.skills,
    };
  }
  return null;
};
