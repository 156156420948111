import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Link,
  Grid,
  Box,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "core/contexts/AuthContext";
// import { VerificationSuccessDialog } from './components/VerificationSuccessDialog';
import AuthLayout from "layouts/AuthLayout";
import { authTextFieldStyle } from "core/theme";

// TODO: Re-implemnt resend verification email @chaseleto
import { resendVerificationEmail } from "core/api/users";



import { FcGoogle } from "react-icons/fc";
const Login = () => {
  const navigate = useNavigate();
  const {
    loginWithEmail,
    loginWithGoogle,
    setIsAuthenticated,
    // setUserInfo,
    isVerified,
    handleVerifyActionCode,
  } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const [dialogContent, setDialogContent] = useState({
    title: "",
    body: "",
    button: "",
    resendVerification: false,
    resetPassword: "",
    emailVerified: "",
  });

  const [currentEmail, setCurrentEmail] = useState("");

  const [searchParams] = useSearchParams();

  const token = searchParams.get("oobCode");
  const mode = searchParams.get("mode");

  const checkForEmailVerification = async () => {
    if (token) {
      setResetToken(token);
      if (mode === "verifyEmail") {
        // const response = await handleVerifyActionCode(token);
        const res = await handleVerifyActionCode(token);
        if (res) {
          setDialogContent({
            title: "Email Verified",
            body: `Your email has been verified. You can now login.`,
            button: "Ok",
            resendVerification: false,
            resetPassword: "",
            emailVerified: "",
          });
          setOpenDialog(true);
        }
      }
    }
  };

  useEffect(() => {
    checkForEmailVerification();
  }, [token]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const credentials = {
      email: formData.get("email") as string,
      password: formData.get("password") as string,
    };

    const response = await loginWithEmail(credentials);
    if (response && isVerified()) {
      console.log("Logging User in.");
      // setIsAuthenticated(true);
    } else if (!isVerified()) {
      setCurrentEmail(credentials.email);
      setDialogContent({
        title: "Your email is not verified.",
        body: `Please verify your email to login.`,
        button: "Ok",
        resendVerification: true,
        resetPassword: "",
        emailVerified: "",
      });
      setOpenDialog(true);
    } else if (!response) {
      setDialogContent({
        title: "Incorrect username or password.",
        body: `Please make sure you entered your username and password correctly.`,
        button: "Ok",
        resendVerification: false,
        resetPassword: "",
        emailVerified: "",
      });
      setOpenDialog(true);
    }
  };

  const openLoginWithGooglePopup = async () => {
    const response = await loginWithGoogle();
    if (response && isVerified()) {
      setIsAuthenticated(true);
      navigate("/" + response.user_type);
    } else {
      setDialogContent({
        title: "Error logging in with Google. ",
        body: `Please contact admin@freetech.co to resolve this issue.`,
        button: "Ok",
        resendVerification: false,
        resetPassword: "",
        emailVerified: "",
      });
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/login");
  };

  const navigateToResetPassword = async () => {
    setOpenDialog(false);
    navigate("/reset-password?token=" + resetToken);
  };

  const handleResendVerificationEmail = async (email: string) => {
    const response = await resendVerificationEmail(email);

    console.log("Resend Verification Email Response: ", response);

    handleCloseDialog();
  }

  const LoginDialog = () => {
    return (
      <>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogContent.title}</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent.body}</Typography>
          </DialogContent>
          <DialogActions>
            {dialogContent.emailVerified && (
              <Button onClick={handleCloseDialog} color="primary">
                {dialogContent.button}
              </Button>
            )}
            {!dialogContent.resetPassword && (
              <Button onClick={handleCloseDialog} color="primary">
                {dialogContent.button}
              </Button>
            )}
            {dialogContent.resendVerification && (
              <Button onClick={() => handleResendVerificationEmail(currentEmail)} color="primary">
                Resend Verification Email
              </Button>
            )}
            {dialogContent.resetPassword && (
              <Button onClick={navigateToResetPassword} color="primary">
                {dialogContent.button}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <AuthLayout>
      {/* Dialogs */}
      {/* <AuthErrorDialog /> */}
      <LoginDialog />

      {/* Login Form */}
      <Box component="form" onSubmit={handleSubmit} noValidate marginTop={1}>
        <TextField
          required
          fullWidth
          margin="normal"
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          sx={authTextFieldStyle}
          autoFocus
        />
        <TextField
          required
          fullWidth
          margin="normal"
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          sx={authTextFieldStyle}
        />

        {/* <FormControlLabel
          control={<Checkbox value="remember" color="secondary" />}
          label="Remember me"
        /> */}

        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 1, mb: 2 }}
        >
          {" "}
          {/* Increased the bottom margin */}
          Sign In
        </Button>

        <Button
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "#4285F4",
            color: "white",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#357ae8",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 1,
            mb: 1,
          }}
          startIcon={<FcGoogle />}
          onClick={() => openLoginWithGooglePopup()}
        >
          Login with Google
        </Button>

        {/* Links */}
        <Grid container>
          <Grid item xs>
            <Link href="/forgot-password" variant="body2">
              {"Forgot password?"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </AuthLayout>
  );
};

export default Login;
