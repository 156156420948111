import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { createOutboundAchTransfer } from 'core/api/increase/ach/createOutboundAchTransfer';
import { listExternalAccounts } from 'core/api/increase/external-accounts/listExternalAccounts';

interface SendMoneyDialogProps {
  open: boolean;
  onClose: () => void;
  mainAccountId: string;
  mainAccountBalance: number;
}

const SendMoneyDialog: React.FC<SendMoneyDialogProps> = ({ open, onClose, mainAccountId, mainAccountBalance }) => {
  const [amount, setAmount] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [externalAccounts, setExternalAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchExternalAccounts();
  }, []);

  const fetchExternalAccounts = async () => {
    try {
      const result = await listExternalAccounts({});
      if (result.success) {
        setExternalAccounts(result.result.data);
      } else {
        setError('Failed to fetch external accounts');
      }
    } catch (err) {
      console.error('Error fetching external accounts:', err);
      setError('An error occurred while fetching external accounts');
    }
  };

  const handleSendMoney = async () => {
    setLoading(true);
    setError(null);

    try {
      const selectedAccountDetails = externalAccounts.find(account => account.id === selectedAccount);
      if (!selectedAccountDetails) {
        throw new Error('Selected account not found');
      }

      // Convert amount to pennies
      const amountInPennies = Math.round(parseFloat(amount) * 100);

      const result = await createOutboundAchTransfer({
        accountId: mainAccountId,
        amount: amountInPennies,
        individualName: selectedAccountDetails.account_holder_name,
        accountNumber: selectedAccountDetails.account_number,
        routingNumber: selectedAccountDetails.routing_number,
        companyEntryDescription: 'Send Money',
        invoiceId: `send_money_${Date.now()}`,
      });

      if (result.success) {
        onClose();
        // You might want to add a success message or refresh the main account balance here
      } else {
        setError('Failed to send money');
      }
    } catch (err) {
      console.error('Error sending money:', err);
      setError('An error occurred while sending money');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Send Money</DialogTitle>
      <DialogContent>
        <Typography variant="body2" paragraph>
          Transfer money from your checking account to an external account.
        </Typography>
        <TextField
          fullWidth
          label="Amount ($)"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          margin="normal"
          inputProps={{ step: "0.01" }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>From Account</InputLabel>
          <Select
            value={mainAccountId}
            label="From Account"
            disabled
          >
            <MenuItem value={mainAccountId}>
              Checking Account (Balance: ${(mainAccountBalance).toFixed(2)})
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>To Account</InputLabel>
          <Select
            value={selectedAccount}
            onChange={(e) => setSelectedAccount(e.target.value as string)}
            label="To Account"
          >
            {externalAccounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.description || `Account ending in ${account.account_number.slice(-4)}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <Button
          onClick={handleSendMoney}
          variant="contained"
          color="primary"
          disabled={loading || !amount || !selectedAccount || parseFloat(amount) > mainAccountBalance}
        >
          {loading ? <CircularProgress size={24} /> : 'Send Money'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendMoneyDialog;