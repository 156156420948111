import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
} from "firebase/firestore";
import {
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
  Chip,
  Avatar,
  CircularProgress,
} from "@mui/material";
import DashboardLayout from "layouts/DashboardLayout";
import { useNavigate } from "react-router-dom";

const db = getFirestore();

interface FreelancerWithDetails extends Freelancer {
  fullName: string;
  projectCount: number;
  assignedClients: string[];
}

const FreelancerManagement: React.FC = () => {
  const [freelancers, setFreelancers] = useState<FreelancerWithDetails[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchFreelancers = async () => {
      const q = query(collection(db, "users"), where("user_type", "==", "freelancer"));
      const querySnapshot = await getDocs(q);
      
      const freelancerPromises = querySnapshot.docs.map(async (doc) => {
        const userData = doc.data() as Freelancer;  
        
        // Fetch project assignments
        const assignmentsQuery = query(collection(db, "users", doc.id, "projectAssignments"));
        const assignmentsSnapshot = await getDocs(assignmentsQuery);
        
        const projectCount = assignmentsSnapshot.size;
        const assignedClients = new Set<string>();
        
        assignmentsSnapshot.forEach((assignmentDoc) => {
          const assignmentData = assignmentDoc.data();
          if (assignmentData.clientId) {
            assignedClients.add(assignmentData.clientId);
          }
        });
        
        return {
          ...userData,
          id: doc.id,
          fullName: `${userData.firstName} ${userData.lastName}`,
          projectCount,
          assignedClients: Array.from(assignedClients),
        };
      });
      
      const freelancerData = await Promise.all(freelancerPromises);
      setFreelancers(freelancerData);
      setLoading(false);
    };

    fetchFreelancers();
  }, []);

  const handleManageFreelancer = (freelancer: FreelancerWithDetails) => {
    navigate(`/admin/freelancer-management/${freelancer.id}`);
  };

  if (loading) {
    return (
      <DashboardLayout>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: '24px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Freelancer Management
        </Typography>

        <Grid container spacing={3}>
          {freelancers.map((freelancer) => (
            <Grid item xs={12} sm={6} md={4} key={freelancer.id}>
              <Card>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar src={freelancer.profilePicture} alt={freelancer.fullName} sx={{ width: 60, height: 60, marginRight: 2 }} />
                    <Box>
                      <Typography variant="h6" component="div">
                        {freelancer.fullName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {freelancer.email}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2" component="p" gutterBottom>
                    Projects: {freelancer.projectCount}
                  </Typography>
                  <Typography variant="body2" component="p" gutterBottom>
                    Assigned Clients:
                  </Typography>
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    {freelancer.assignedClients.map((clientId) => (
                      <Chip key={clientId} label={clientId} size="small" />
                    ))}
                  </Box>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => handleManageFreelancer(freelancer)}
                  >
                    Manage Freelancer
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default FreelancerManagement;
