import React, { useState, useCallback, useRef } from "react";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import DashboardLayout from "layouts/DashboardLayout";
import DataGridFirestoreCRUD from "components/DataGridFirestoreCRUD";
import { CreateUser } from "./components/CreateUser";
import { deleteFirebaseUser } from "core/api/users";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { updateUser } from "core/api/users";
import { AddComment, NotificationImportant } from "@mui/icons-material";
import { PageTitle } from "components/PageTitle";
import { resendVerificationEmail } from "core/api/users";
import { useAuth } from "core/contexts/AuthContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

interface UserType {
  // id?: string;
  // companyName?: string;
  // pointOfContactName?: string;
  // email?: string;
  // phone?: string;
  // location?: string;
  // size?: string;
  // isNew?: boolean;
}

const booleanOptions = ['true', 'false']

const skillLevels = ["Apprentice", "Junior", "Mid", "Senior", "Partner"];

const clientStatuses = ["Active", "Inactive"];

const user_types = ["freelancer", "stakeholder", "admin"];

const UserManagement = () => {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserInfoType | null>(null);
  const { userInfo } = useAuth();
  const notesRef = useRef("");
  let userTypeOptions: string[] = [];
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleOpen = useCallback((user: UserInfoType) => {
    setSelectedUser((prevState) => ({ ...prevState, ...user }));
    notesRef.current = user.notes || "";
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSaveNotes = useCallback(async () => {
    if (selectedUser) {
      await updateUser(selectedUser.id, { notes: notesRef.current });
    }
    handleClose();
  }, [selectedUser, handleClose]);

  const handleChangeNotes = (event: { target: { value: string } }) => {
    notesRef.current = event.target.value; // Update ref value without causing re-renders
  };

  const handleNotifyClick = (row: { email: string }) => async () => {
    const { email } = row;

    try {
      await resendVerificationEmail(email);
      setSnackbar({
        open: true,
        message: "Verification email sent successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to send verification email",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCreateUserSuccess = () => {
    setSnackbar({
      open: true,
      message: "User created successfully",
      severity: "success",
    });
  };

  const handleCreateUserError = (error: string) => {
    setSnackbar({
      open: true,
      message: `Failed to create user: ${error}`,
      severity: "error",
    });
  };

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      editable: true,
    },
    { field: "lastName", headerName: "Last Name", width: 150, editable: true },
    {
      field: "user_type",
      headerName: "User Type",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: user_types,
    },
    { field: "email", headerName: "Email", width: 300, editable: true },
    { field: "phone", headerName: "Phone", width: 150, editable: true },
    {
      field: "is_active",
      headerName: "Active",
      width: 75,
      editable: true,
      type: "singleSelect",
      valueOptions: booleanOptions,
    },
    {
      field: "email_verified",
      headerName: "Email Verified",
      width: 130,
      editable: true,
      type: "singleSelect",
      valueOptions: booleanOptions,
    },
    {
      field: "Resend Email",
      type: "actions",
      headerName: "Resend Verification",
      editable: false,
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<NotificationImportant />}
          label="Send Reminder Email"
          onClick={() => handleNotifyClick(params.row)()}
          color="inherit"
          disabled={params.row.email_verified === true}
        />,
      ],
    },
    {
      field: "add_notes",
      type: "actions",
      headerName: "Notes",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<AddComment />}
          label="Add Notes"
          onClick={() => handleOpen(params.row)}
          color="inherit"
        />,
      ],
    },
  ];

  const deleteUser = async (userId: string) => {
    // Maybe get adam to explain this to me because this is being called in DataGridFirestoreCRUD.tsx and not here, seems to have been trying to do it twice.
    console.log('Deleting user', userId)
    try {
      await deleteFirebaseUser(userId)
    } catch (error) {
      console.error('Failed to delete user', error)
    }
  }

  return (
    <DashboardLayout>

<PageTitle
        title='User Management'
        subtitle='This page allows you to create, update, or delete portal users. '
        bulletPoints={[
          'Double-click the row to start editing a user, then click the save icon that appears on the right side.',
          "Use the 'Remind' icon allows you to resend a verification email to a user.",
          "Share user notes with the team by clicking the 'Add Notes' icon.",
        ]}
      />

      <CreateUser onSuccess={handleCreateUserSuccess} onError={handleCreateUserError} />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent sx={{ width: '550px' }}>
          <TextField
            autoFocus
            margin='dense'
            id='notes'
            label='Notes'
            type='text'
            fullWidth
            variant='outlined'
            multiline
            rows={10}
            defaultValue={notesRef.current}
            onChange={handleChangeNotes}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveNotes}>Save</Button>
        </DialogActions>
      </Dialog>

      <DataGridFirestoreCRUD
        columns={columns}
        collectionName="users"
        editable={true}
        // deleteable={true}
        create={false}
        search={true}
        // onDeleteClick={deleteUser}
      />

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
};

export default UserManagement;
