import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const loadSingleInvoice = async (clientId: string, invoiceId: string): Promise<InvoiceData | null> => {
  try {
    const db = getFirestore();
    const invoiceRef = doc(db, `clients/${clientId}/invoices/${invoiceId}`);
    const invoiceSnapshot = await getDoc(invoiceRef);

    if (invoiceSnapshot.exists()) {
      const invoiceData = invoiceSnapshot.data() as InvoiceData;
      return {
        ...invoiceData,
        id: invoiceSnapshot.id
      };
    } else {
      console.log(`No invoice found with id: ${invoiceId}`);
      return null;
    }
  } catch (error) {
    console.error('Error loading invoice:', error);
    throw error;
  }
};
