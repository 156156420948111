import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getFirestore,
} from "firebase/firestore";
import {
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import DashboardLayout from "layouts/DashboardLayout";

const db = getFirestore();

const ProjectManagement = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [open, setOpen] = useState(false);
  const [newProject, setNewProject] = useState({ name: "", description: "" });

  useEffect(() => {
    const q = query(collection(db, "projects"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const projectsData: any = [];
      querySnapshot.forEach((doc) => {
        projectsData.push({ id: doc.id, ...doc.data() });
      });
      setProjects(projectsData);
    });
    return () => unsubscribe();
  }, []);

  const handleAddProject = async () => {
    await addDoc(collection(db, "projects"), newProject);
    setNewProject({ name: "", description: "" });
    setOpen(false);
  };

  const handleDeleteProject = async (id: string) => {
    await deleteDoc(doc(db, "projects", id));
  };

  const handleOpenCreateProject = () => {
    console.log("open");
    setOpen(true);
  };

  return (
    <DashboardLayout>
      <Box sx={{ marginBottom: '50px'}}>
        <Typography variant="h4">Project Management</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenCreateProject()}
        >
          Add Project
        </Button>
      </Box>

      <Grid container spacing={3}>
        {projects &&
          projects.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project.id}>
              <Card>
                <CardContent>
                  <Typography variant="h5">{project.name}</Typography>
                  <Typography variant="body2">{project.description}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => handleDeleteProject(project.id)}
                  >
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add New Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Project Name"
            fullWidth
            value={newProject.name}
            onChange={(e) =>
              setNewProject({ ...newProject, name: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Project Description"
            fullWidth
            value={newProject.description}
            onChange={(e) =>
              setNewProject({ ...newProject, description: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddProject} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default ProjectManagement;
