export const clockifyApiUrl = 'https://api.clockify.me/api/v1'
export const clockifyReportsUrl = 'https://reports.api.clockify.me/v1'

export const getHeaders = (): Record<string, string> => {
  const apiKey = process.env.REACT_APP_CLOCKIFY_API_KEY
  if (!apiKey) {
    throw new Error('CLOCKIFY_API_KEY is not defined in environment variables.')
  }
  return {
    'Content-Type': 'application/json',
    'X-Api-Key': apiKey,
  }
}
export const workspaceId = process.env.REACT_APP_CLOCKIFY_WORKSPACE_ID

export const functionUrl = 'http://127.0.0.1:5001/freetech-production/us-central1/clockify'