import { getFileUrl } from "core/api/storage";

export const getProjectAssignmentAgreement = async (clientId: string, assignmentId: string): Promise<string | null> => {
  try {
    return await getFileUrl(`clients/${clientId}/projectAssignments/${assignmentId}`);
  } catch (error) {
    console.error("Error fetching project assignment agreement:", error);
    return null;
  }
};
