import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  collection,
  query as firestoreQuery,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  getFirestore,
  DocumentReference,
  CollectionReference,
  Query,
  DocumentData,
  getDocs,
} from "firebase/firestore";
import { db } from "core/config/firebase";
import { useEffect, useState } from "react";

interface DocumentWithSubcollections<T> {
  subcollections?: Record<string, any[]>;
}

export const useCollection = <T extends { id: string }>(
  collectionName: string,
  includeSubcollections: boolean = false,
  subcollectionNames: string[] = [],
  options?: UseQueryOptions<DocumentWithSubcollections<T>[], Error>
) => {
  const firestore = getFirestore();

  const fetchCollection = async (): Promise<
    DocumentWithSubcollections<T>[]
  > => {
    console.log("Fetching Collection: ", collectionName);
    const collectionRef = collection(
      firestore,
      collectionName
    ) as CollectionReference<T>;
    const q: Query<DocumentData> = firestoreQuery(collectionRef);
    const querySnapshot = await getDocs(q);

    const data: DocumentWithSubcollections<T>[] = [];

    for (const docSnapshot of querySnapshot.docs) {
      const docData = {
        id: docSnapshot.id,
        ...(docSnapshot.data() as Omit<T, "id">),
      };

      if (includeSubcollections && subcollectionNames.length > 0) {
        const subcollectionsData: Record<string, any[]> = {};

        for (const subName of subcollectionNames) {
          const subcollectionRef = collection(
            firestore,
            collectionName,
            docSnapshot.id,
            subName
          );
          const subQuery = firestoreQuery(subcollectionRef);
          const subSnapshot = await getDocs(subQuery);
          subcollectionsData[subName] = subSnapshot.docs.map((subDoc) => ({
            id: subDoc.id,
            ...(subDoc.data() as any),
          }));
        }

        data.push({ ...docData, subcollections: subcollectionsData });
      } else {
        data.push({ ...docData, subcollections: {} });
      }
    }

    return data;
  };

  return useQuery<DocumentWithSubcollections<T>[], Error>({
    queryKey: [
      "collection",
      collectionName,
      includeSubcollections,
      ...subcollectionNames,
    ],
    queryFn: fetchCollection,
    staleTime: Infinity,
    ...options,
  });
};

export const useAddDocument = <T extends { id: string }>(
  collectionName: string,
  options?: UseMutationOptions<
    DocumentReference<T>,
    Error,
    Omit<T, "id">,
    unknown
  >
) => {
  const queryClient = useQueryClient();

  const collectionRef = collection(
    db,
    collectionName
  ) as CollectionReference<T>;

  return useMutation<DocumentReference<T>, Error, Omit<T, "id">>(
    //@ts-ignore
    (newDocument: Omit<T, "id">) => addDoc(collectionRef, newDocument),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [collectionName] });
      },
      ...options,
    }
  );
};

export const useDeleteDocument = <T extends { id: string }>(
  collectionName: string,
  options?: UseMutationOptions<void, Error, string, unknown>
) => {
  const queryClient = useQueryClient();

  const collectionRef = collection(
    db,
    collectionName
  ) as CollectionReference<T>;

  return useMutation<void, Error, string>(
    //@ts-ignore
    (id: string) => deleteDoc(doc(collectionRef, id)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [collectionName] });
      },
      ...options,
    }
  );
};

export const useUpdateDocument = <T extends { id: string }>(
  collectionName: string,
  options?: UseMutationOptions<
    void,
    Error,
    Partial<T> & { id: string },
    unknown
  >
) => {
  const queryClient = useQueryClient();

  const collectionRef = collection(
    db,
    collectionName
  ) as CollectionReference<T>;

  return useMutation<void, Error, Partial<T> & { id: string }>(
    //@ts-ignore
    ({ id, ...updates }) => updateDoc(doc(collectionRef, id), updates),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [collectionName] });
      },
      ...options,
    }
  );
};
