import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Box,
  Divider,
  Chip,
  Select,
  MenuItem,
  SelectChangeEvent,
  Snackbar,
  Alert,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  useTheme,
  Link,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { getUserProfile } from "../../../../core/api/users/getUserProfile";
import AddProjectAssignmentDialog from "./AddProjectAssignmentDialog";
import EditProjectAssignmentDialog from "./EditProjectAssignmentDialog";
import {
  updateProjectAssignment,
  updateProjectAssignmentStatus,
  deleteProjectAssignment,
  getEngineerAssignments,
  deleteEngineerFromProjectAssignment,
  addEngineerToProjectAssignment,
} from "core/api/projectAssignments";
import ViewFileDialog from "../../../../components/ViewFileDialog";
import FileUploadDialog from "./FileUploadDialog";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { readCSA } from "core/api/csa/readCSA";
import { getProjectName } from "core/api/projects";
import PerformanceReviewDialog from "./PerformanceReviewDialog";
import { addPerformanceReview } from "core/api/projectAssignments/addPerformanceReview";
import { useAuth } from "core/contexts/AuthContext";
import { getCSAName } from "core/api/csa/getCSAName";
import { getClientProjects } from "core/api/projects";
import { getProjectCSAs } from "core/api/csa";
import ConfirmDialog from "components/ConfirmDialog";
import { useDropzone } from "react-dropzone";

interface EngineerCardProps {
  engineer: Freelancer;
  onRemove: () => void;
  clientId: string;
  onAddAssignment: (
    clientId: string,
    engineerId: string,
    assignmentData: Omit<ProjectAssignment, "id">,
    file: File
  ) => Promise<void>;
  onUpdateStatus: (
    engineerId: string,
    newStatus: Freelancer["status"]
  ) => Promise<void>;
  onAccordionChange: (isExpanded: boolean) => void;
  isExpanded: boolean;
}

const EngineerCard: React.FC<EngineerCardProps> = ({
  engineer,
  onRemove,
  clientId,
  onAddAssignment,
  onUpdateStatus,
  onAccordionChange,
  isExpanded,
}) => {
  const [engineerInfo, setEngineerInfo] = useState<UserInfoType | null>(null);
  const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);
  const [assignments, setAssignments] = useState<ProjectAssignment[]>([]);
  const [openViewFileDialog, setOpenViewFileDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<{
    url: string;
    name: string;
  } | null>(null);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState<
    string | null
  >(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedAssignment, setSelectedAssignment] =
    useState<ProjectAssignment | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "error" | "success";
  }>({
    open: false,
    message: "",
    severity: "success",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAssignments, setFilteredAssignments] = useState<
    ProjectAssignment[]
  >([]);
  const [expanded, setExpanded] = useState(false);
  const [openPerformanceReviewDialog, setOpenPerformanceReviewDialog] =
    useState(false);
  const [selectedAssignmentForReview, setSelectedAssignmentForReview] =
    useState<ProjectAssignment | null>(null);
  const [editingAssignmentId, setEditingAssignmentId] = useState<string | null>(
    null
  );
  const [newAssignment, setNewAssignment] =
    useState<Partial<ProjectAssignment> | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [csas, setCsas] = useState<ConsultingServicesAgreement[]>([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] =
    useState<ProjectAssignment | null>(null);
  const [assignmentFiles, setAssignmentFiles] = useState<{
    [key: string]: File | null;
  }>({});

  const { userInfo } = useAuth(); // Get the current user from the auth context
  const theme = useTheme();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const info = await getUserProfile(engineer.id);
      setEngineerInfo(info);
    };
    fetchUserInfo();
    loadAssignments();
  }, [engineer.id, clientId]);

  useEffect(() => {
    const fetchProjects = async () => {
      const fetchedProjects = await getClientProjects(clientId);
      setProjects(fetchedProjects);
    };
    fetchProjects();
  }, [clientId]);

  useEffect(() => {
    const fetchCSAs = async () => {
      if (editingAssignmentId) {
        const assignment = assignments.find(
          (a) => a.id === editingAssignmentId
        );
        if (assignment && assignment.projectId) {
          const fetchedCSAs = await getProjectCSAs(
            clientId,
            assignment.projectId
          );
          setCsas(fetchedCSAs);
        }
      }
    };
    fetchCSAs();
  }, [clientId, editingAssignmentId, assignments]);

  const loadAssignments = async () => {
    try {
      const engineerAssignments = await getEngineerAssignments(
        clientId,
        engineer.id
      );

      // Fetch CSA names for each assignment
      const assignmentsWithCSANames = await Promise.all(
        engineerAssignments.map(async (assignment: ProjectAssignment) => {
          if (assignment.csaId) {
            const csaName = await getCSAName(
              clientId,
              assignment.projectId,
              assignment.csaId
            );
            const projectName = await getProjectName(
              clientId,
              assignment.projectId
            );
            return { ...assignment, csaName, projectName };
          }
          return assignment;
        })
      );

      setAssignments(assignmentsWithCSANames);
    } catch (error) {
      console.error("Error loading assignments:", error);
    }
  };

  const handleAddAssignment = async (
    clientId: string,
    engineerId: string,
    assignmentData: Omit<ProjectAssignment, "id">,
    file: File | null
  ) => {
    try {
      const newAssignment = await addEngineerToProjectAssignment(
        clientId,
        engineerId,
        assignmentData,
        file
      );
      setAssignments((prevAssignments) => [...prevAssignments, newAssignment]);
      setOpenAssignmentDialog(false);
    } catch (error) {
      console.error("Error adding assignment:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleViewAssignment = (assignment: ProjectAssignment) => {
    if (assignment.fileUrl) {
      setSelectedFile({
        url: assignment.fileUrl,
        name: `Assignment for ${assignment.projectId}`,
      });
      setOpenViewFileDialog(true);
    }
  };

  const getStatusColor = (status: Freelancer["status"]) => {
    switch (status) {
      case "ready":
        return "#3498db"; // Blue
      case "active":
        return "#2ecc71"; // Green
      case "inactive":
        return "#e74c3c"; // Red
      default:
        return "#95a5a6"; // Grey
    }
  };

  const getPAStatusColor = (status: ProjectAssignmentStatus) => {
    switch (status) {
      case "interviewing":
        return "#3498db"; // Blue
      case "ready":
        return "#2ecc71"; // Green
      case "in_progress":
        return "#f39c12"; // Yellow
      case "complete":
        return "#95a"; // Grey
    }
  };

  const handleStatusChange = async (
    event: SelectChangeEvent<Freelancer["status"]>
  ) => {
    const newStatus = event.target.value as Freelancer["status"];
    try {
      await onUpdateStatus(engineer.id, newStatus);
    } catch (error) {
      console.error("Error updating engineer status:", error);
    }
  };

  const handleStatusChangeAssignment = async (
    assignment: ProjectAssignment
  ) => {
    setSelectedAssignment(assignment);
    setOpenEditDialog(true);
  };

  const handleFileUpload = async (file: File) => {
    if (!selectedAssignmentId) return;

    try {
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `clients/${clientId}/projectAssignments/${selectedAssignmentId}`
      );
      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);

      const updatedAssignment = assignments.find(
        (a) => a.id === selectedAssignmentId
      );
      if (updatedAssignment) {
        await updateProjectAssignmentStatus(
          clientId,
          selectedAssignmentId,
          updatedAssignment.status,
          fileUrl
        );
        setAssignments(
          assignments.map((assignment) =>
            assignment.id === selectedAssignmentId
              ? { ...assignment, fileUrl }
              : assignment
          )
        );
      }
      setOpenFileUploadDialog(false);
      setSelectedAssignmentId(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      // Show error message about file upload failure
    }
  };

  const handleEditAssignment = (assignment: ProjectAssignment) => {
    setEditingAssignmentId(assignment.id);
  };

  const handleSaveAssignment = async (
    assignment: ProjectAssignment,
    file: File | null
  ) => {
    try {
      const updatedAssignment = await updateProjectAssignment(
        clientId,
        assignment.id,
        assignment,
        file
      );
      setAssignments((prevAssignments) =>
        prevAssignments.map((a) =>
          a.id === updatedAssignment.id ? updatedAssignment : a
        )
      );
      setEditingAssignmentId(null);
      setAssignmentFiles((prev) => {
        const newFiles = { ...prev };
        delete newFiles[assignment.id];
        return newFiles;
      });
      setSnackbar({
        open: true,
        message: "Assignment updated successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating assignment:", error);
      setSnackbar({
        open: true,
        message: "Error updating assignment",
        severity: "error",
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingAssignmentId(null);
  };

  const handleAddNewAssignment = () => {
    setNewAssignment({
      projectId: "",
      csaId: "",
      startDate: "",
      endDate: "",
      billableRate: 0,
      budgetedHours: 0,
      status: "interviewing" as ProjectAssignmentStatus,
    });
  };

  const handleSaveNewAssignment = async (file: File | null) => {
    if (newAssignment) {
      try {
        await addEngineerToProjectAssignment(
          clientId,
          engineer.id,
          newAssignment as Omit<ProjectAssignment, "id">,
          file
        );
        setNewAssignment(null);
        loadAssignments();
      } catch (error) {
        console.error("Error adding new assignment:", error);
        setSnackbar({
          open: true,
          message: "Error adding new assignment",
          severity: "error",
        });
      }
    }
  };

  const handleCancelNewAssignment = () => {
    setNewAssignment(null);
  };

  const handleSaveEditedAssignment = async (
    updatedAssignment: Partial<ProjectAssignment>,
    file: File | null
  ) => {
    if (selectedAssignment) {
      try {
        await updateProjectAssignment(
          clientId,
          selectedAssignment.id,
          updatedAssignment,
          file
        );
        setAssignments(
          assignments.map((assignment) =>
            assignment.id === selectedAssignment.id
              ? { ...assignment, ...updatedAssignment }
              : assignment
          )
        );
        setOpenEditDialog(false);
        setSnackbar({
          open: true,
          message: "Assignment updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating assignment:", error);
        setSnackbar({
          open: true,
          message: "Error updating assignment",
          severity: "error",
        });
      }
    }
  };

  const handleDeleteAssignment = async (assignment: ProjectAssignment) => {
    try {
      await deleteProjectAssignment(clientId, assignment.id);
      setAssignments(assignments.filter((a) => a.id !== assignment.id));
      setSnackbar({
        open: true,
        message: "Assignment deleted successfully",
        severity: "success",
      });
      setOpenEditDialog(false);
    } catch (error) {
      console.error("Error deleting assignment:", error);
      setSnackbar({
        open: true,
        message: "Error deleting assignment",
        severity: "error",
      });
    }
  };

  const handleOpenPerformanceReview = (assignment: ProjectAssignment) => {
    setSelectedAssignmentForReview(assignment);
    setOpenPerformanceReviewDialog(true);
  };

  const handleAddPerformanceReview = async (reviewData: PerformanceReview) => {
    if (selectedAssignmentForReview) {
      try {
        await addPerformanceReview(
          clientId,
          selectedAssignmentForReview.id,
          reviewData
        );
        setSnackbar({
          open: true,
          message: "Performance review added successfully",
          severity: "success",
        });
        setOpenPerformanceReviewDialog(false);
        // Optionally, refresh the assignments list or update the local state
        await loadAssignments();
      } catch (error) {
        console.error("Error adding performance review:", error);
        setSnackbar({
          open: true,
          message: "Error adding performance review",
          severity: "error",
        });
      }
    }
  };

  useEffect(() => {
    const filtered = assignments.filter((assignment) =>
      Object.values(assignment).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredAssignments(filtered);
  }, [searchTerm, assignments]);

  const handleAccordionChange = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    onAccordionChange(isExpanded);
  };

  const handleAssignmentChange = (
    assignmentId: string | undefined,
    field: keyof ProjectAssignment,
    value: any
  ) => {
    if (assignmentId) {
      setAssignments((prevAssignments) =>
        prevAssignments.map((assignment) =>
          assignment.id === assignmentId
            ? { ...assignment, [field]: value }
            : assignment
        )
      );
    } else {
      // This is a new assignment
      setNewAssignment((prev) => (prev ? { ...prev, [field]: value } : null));
    }

    if (field === "projectId") {
      const fetchCSAs = async () => {
        const fetchedCSAs = await getProjectCSAs(clientId, value as string);
        setCsas(fetchedCSAs);
      };
      fetchCSAs();
    }
  };

  const handleDeleteClick = (assignment: ProjectAssignment) => {
    setAssignmentToDelete(assignment);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (assignmentToDelete) {
      try {
        await deleteEngineerFromProjectAssignment(
          clientId,
          assignmentToDelete.id,
          engineer.id
        );
        setAssignments(
          assignments.filter((a) => a.id !== assignmentToDelete.id)
        );
        setSnackbar({
          open: true,
          message: "Assignment deleted successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error deleting assignment:", error);
        setSnackbar({
          open: true,
          message: "Error deleting assignment",
          severity: "error",
        });
      }
    }
    setDeleteConfirmOpen(false);
    setAssignmentToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setAssignmentToDelete(null);
  };

  const handleFileChange = (assignmentId: string, file: File | null) => {
    setAssignmentFiles((prev) => ({ ...prev, [assignmentId]: file }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // This will be called for the currently edited assignment
      if (editingAssignmentId) {
        handleFileChange(editingAssignmentId, acceptedFiles[0]);
      } else if (newAssignment) {
        handleFileChange("new", acceptedFiles[0]);
      }
    },
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
  });

  const renderAssignmentCard = (
    assignment: ProjectAssignment | Partial<ProjectAssignment>,
    isEditing: boolean,
    isNew: boolean = false
  ) => {
    const assignmentId = assignment.id || "new";
    const file = assignmentFiles[assignmentId];

    const StatusChip = () => (
      <FormControl size="small" sx={{ position: "absolute", top: 8, right: 8 }}>
        <Select
          value={assignment.status || ""}
          onChange={(e) =>
            handleAssignmentChange(
              isNew ? undefined : assignment.id,
              "status",
              e.target.value as ProjectAssignmentStatus
            )
          }
          renderValue={(selected) => (
            <Chip
              label={selected}
              size="small"
              sx={{
                backgroundColor: getPAStatusColor(
                  selected as ProjectAssignmentStatus
                ),
                color: "white",
                "& .MuiChip-label": { px: 1 },
              }}
            />
          )}
          sx={{
            height: 24,
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          }}
        >
          <MenuItem value="interviewing">Interviewing</MenuItem>
          <MenuItem value="ready">Ready</MenuItem>
          <MenuItem value="in_progress">In Progress</MenuItem>
          <MenuItem value="complete">Complete</MenuItem>
        </Select>
      </FormControl>
    );

    return (
      <Grid
        item
        xs={12}
        sm={isEditing ? 12 : 6}
        md={isEditing ? 12 : 4}
        key={assignmentId}
      >
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: theme.shadows[6],
            },
            ...(isEditing && {
              border: "2px solid",
              borderColor: "primary.main",
              boxShadow: theme.shadows[10],
            }),
          }}
        >
          <CardContent sx={{ flexGrow: 1, p: 2, position: "relative" }}>
            <StatusChip />
            <Typography variant="subtitle1" gutterBottom>
              {assignment.projectName || "N/A"}
            </Typography>
            {isEditing ? (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Project</InputLabel>
                    <Select
                      value={assignment.projectId || ""}
                      onChange={(e) =>
                        handleAssignmentChange(
                          isNew ? undefined : assignment.id,
                          "projectId",
                          e.target.value
                        )
                      }
                      label="Project"
                    >
                      {projects.map((project: Project) => (
                        <MenuItem key={project.id} value={project.id}>
                          {project.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>CSA</InputLabel>
                    <Select
                      value={assignment.csaId || ""}
                      onChange={(e) =>
                        handleAssignmentChange(
                          isNew ? undefined : assignment.id,
                          "csaId",
                          e.target.value
                        )
                      }
                      label="CSA"
                      disabled={!assignment.projectId}
                    >
                      {csas.map((csa: ConsultingServicesAgreement) => (
                        <MenuItem key={csa.id} value={csa.id}>
                          {csa.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    type="date"
                    size="small"
                    value={assignment.startDate || ""}
                    onChange={(e) =>
                      handleAssignmentChange(
                        isNew ? undefined : assignment.id,
                        "startDate",
                        e.target.value
                      )
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="End Date"
                    type="date"
                    size="small"
                    value={assignment.endDate || ""}
                    onChange={(e) =>
                      handleAssignmentChange(
                        isNew ? undefined : assignment.id,
                        "endDate",
                        e.target.value
                      )
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Rate"
                    type="number"
                    size="small"
                    value={assignment.billableRate || ""}
                    onChange={(e) =>
                      handleAssignmentChange(
                        isNew ? undefined : assignment.id,
                        "billableRate",
                        parseFloat(e.target.value)
                      )
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Hours"
                    type="number"
                    size="small"
                    value={assignment.budgetedHours || ""}
                    onChange={(e) =>
                      handleAssignmentChange(
                        isNew ? undefined : assignment.id,
                        "budgetedHours",
                        parseInt(e.target.value)
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Utilized"
                    type="number"
                    size="small"
                    value={assignment.utilizedHours || ""}
                    onChange={(e) =>
                      handleAssignmentChange(
                        isNew ? undefined : assignment.id,
                        "utilizedHours",
                        parseInt(e.target.value)
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Amount Paid"
                    type="number"
                    size="small"
                    value={assignment.amountPaid || ""}
                    onChange={(e) =>
                      handleAssignmentChange(
                        isNew ? undefined : assignment.id,
                        "amountPaid",
                        parseFloat(e.target.value)
                      )
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Payment Date"
                    type="date"
                    size="small"
                    value={assignment.paymentDate || ""}
                    onChange={(e) =>
                      handleAssignmentChange(
                        isNew ? undefined : assignment.id,
                        "paymentDate",
                        e.target.value
                      )
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Payment Method</InputLabel>
                    <Select
                      value={assignment.paymentMethod || ""}
                      onChange={(e) =>
                        handleAssignmentChange(
                          isNew ? undefined : assignment.id,
                          "paymentMethod",
                          e.target.value
                        )
                      }
                      label="Payment Method"
                    >
                      <MenuItem value="Quickbooks">Quickbooks</MenuItem>
                      <MenuItem value="Zelle">Zelle</MenuItem>
                      <MenuItem value="Venmo">Venmo</MenuItem>
                      <MenuItem value="CashApp">CashApp</MenuItem>
                      <MenuItem value="Check">Check</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      {...getRootProps()}
                      sx={{
                        mt: 1,
                        p: 1,
                        border: "1px dashed grey",
                        borderRadius: 1,
                      }}
                    >
                      <input {...getInputProps()} />
                      <Typography variant="caption">
                        {file
                          ? `Selected: ${file.name}`
                          : assignment.fileUrl
                            ? `Current file: ${assignment.fileUrl} Drop to replace.`
                            : "Drop file here or click to select"}
                      </Typography>
                    </Box>

                    {/* <Link
                    href={assignment.fileUrl || '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                    component="a"
                  >
                    {assignment.fileUrl ? 'View Current File' : 'No file uploaded'}
                  </Link> */}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                    CSA: {assignment.csaName || 'N/A'}
                  </Typography>
                  <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                    {assignment.startDate || 'N/A'} - {assignment.endDate || 'N/A'}
                  </Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2">
                    Rate: <span style={{ fontWeight: 'bold' }}>${assignment.billableRate}/hr</span>
                  </Typography>
                  <Typography variant="body2">
                    Hours: <span style={{ fontWeight: 'bold' }}>{assignment.budgetedHours || 'N/A'}</span>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2">
                    Utilized: <span style={{ fontWeight: 'bold' }}>{assignment.utilizedHours || 'N/A'}</span>
                  </Typography>
                  <Typography variant="body2">
                    Paid: <span style={{ fontWeight: 'bold' }}>${assignment.amountPaid || 'N/A'}</span>
                  </Typography>
                </Box>
              </Box>
            )}
          </CardContent>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "space-around", p: 1 }}>
            {isEditing ? (
              <>
                {assignment.fileUrl && (
                  <IconButton
                    onClick={() =>
                      handleViewAssignment(assignment as ProjectAssignment)
                    }
                    size="small"
                  >
                    <VisibilityIcon />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => {
                    if (isNew) {
                      handleCancelNewAssignment();
                    } else {
                      handleCancelEdit();
                    }
                    handleFileChange(assignmentId, null);
                  }}
                  size="small"
                >
                  <CancelIcon />
                </IconButton>

                <IconButton
                  onClick={() => {
                    if (isNew) {
                      handleSaveNewAssignment(file);
                    } else {
                      handleSaveAssignment(
                        assignment as ProjectAssignment,
                        file
                      );
                    }
                  }}
                  size="small"
                >
                  <SaveIcon />
                </IconButton>
              </>
            ) : (
              <>
                {assignment.fileUrl && (
                  <IconButton
                    onClick={() =>
                      handleViewAssignment(assignment as ProjectAssignment)
                    }
                    size="small"
                  >
                    <VisibilityIcon />
                  </IconButton>
                )}

                <IconButton
                  onClick={() =>
                    handleOpenPerformanceReview(assignment as ProjectAssignment)
                  }
                  size="small"
                >
                  <RateReviewIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    handleEditAssignment(assignment as ProjectAssignment)
                  }
                  size="small"
                >
                  <EditIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Card>
      </Grid>
    );
  };

  if (!engineerInfo) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 2,
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s ease-in-out",
          "&:hover": {
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <CardContent
          sx={{ flexGrow: 0, backgroundColor: "background.paper", padding: 3 }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            mb={2}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={engineerInfo?.profilePicture}
                alt={`${engineerInfo?.firstName} ${engineerInfo?.lastName}`}
                sx={{ width: 60, height: 60, marginRight: 2 }}
              />
              <Box>
                <Typography variant="h6">
                  {engineerInfo?.firstName} {engineerInfo?.lastName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {engineerInfo?.email}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Select
                value={engineer.status}
                onChange={handleStatusChange}
                sx={{
                  height: 32,
                  mb: 1,
                  "& .MuiSelect-select": {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                renderValue={(selected) => (
                  <Chip
                    label={selected}
                    size="small"
                    sx={{
                      backgroundColor: getStatusColor(selected),
                      color: "white",
                      fontWeight: "bold",
                      "& .MuiChip-label": { px: 2 },
                    }}
                  />
                )}
              >
                {["ready", "active", "inactive"].map((status) => (
                  <MenuItem key={status} value={status}>
                    <Chip
                      label={status}
                      size="small"
                      sx={{
                        backgroundColor: getStatusColor(
                          status as Freelancer["status"]
                        ),
                        color: "white",
                        fontWeight: "bold",
                        "& .MuiChip-label": { px: 2 },
                      }}
                    />
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="caption" color="textSecondary">
                Associated:{" "}
                {new Date(engineer.associationDate).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>
        </CardContent>

        <Divider />

        <Accordion
          expanded={isExpanded}
          onChange={handleAccordionChange}
          sx={{
            "&:before": { display: "none" },
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-assignments-content"
            id="panel-assignments-header"
            sx={{
              backgroundColor: "background.paper",
              borderTop: "1px solid",
              borderColor: "divider",
            }}
          >
            <Box display="flex" alignItems="center" width="100%">
              <Typography variant="h6" flexGrow={1}>
                Project Assignments
              </Typography>
              <Typography variant="body2" color="text.secondary" mr={1}>
                {assignments.length}{" "}
                {assignments.length === 1 ? "Assignment" : "Assignments"}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 2 }}>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleAddNewAssignment}
                startIcon={<AddIcon />}
                sx={{
                  textTransform: "none",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                Add Assignment
              </Button>
              <TextField
                placeholder="Search assignments"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "200px" }}
              />
            </Box>
            <Grid container spacing={2}>
              {newAssignment && renderAssignmentCard(newAssignment, true, true)}
              {filteredAssignments.map((assignment) =>
                renderAssignmentCard(
                  assignment,
                  editingAssignmentId === assignment.id
                )
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Card>
      <AddProjectAssignmentDialog
        open={openAssignmentDialog}
        onClose={() => setOpenAssignmentDialog(false)}
        onAdd={handleAddAssignment}
        clientId={clientId}
        engineerId={engineer.id}
      />
      {selectedFile && (
        <ViewFileDialog
          open={openViewFileDialog}
          onClose={() => setOpenViewFileDialog(false)}
          fileUrl={selectedFile.url}
          fileName={selectedFile.name}
        />
      )}
      <FileUploadDialog
        open={openFileUploadDialog}
        onClose={() => setOpenFileUploadDialog(false)}
        onUpload={handleFileUpload}
      />
      {selectedAssignment && (
        <EditProjectAssignmentDialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          onSave={handleSaveEditedAssignment}
          onDelete={handleDeleteAssignment}
          assignment={selectedAssignment}
          clientId={clientId}
          freelancer={engineer}
          canDelete={
            selectedAssignment.status !== "in_progress" &&
            selectedAssignment.status !== "complete"
          }
        />
      )}
      <PerformanceReviewDialog
        open={openPerformanceReviewDialog}
        onClose={() => setOpenPerformanceReviewDialog(false)}
        onSubmit={handleAddPerformanceReview}
        assignment={selectedAssignmentForReview}
        engineerName={`${engineerInfo?.email}`}
        currentUser={{
          id: userInfo?.id || "",
          email: userInfo?.email || "Unknown User",
        }}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <ConfirmDialog
        open={deleteConfirmOpen}
        title="Confirm Delete"
        content="Are you sure you want to delete this project assignment? This action cannot be undone."
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};

export default EngineerCard;