import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  FormHelperText,
  Snackbar,
  Alert,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { getClientProjects } from "core/api/projects";
import { getProjectCSAs } from "core/api/projects/getProjectCsaDocs";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateProjectAssignment } from "core/api/projectAssignments";

interface EditProjectAssignmentDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (
    updatedAssignment: Partial<ProjectAssignment>,
    file: File | null
  ) => Promise<void>;
  onDelete: (assignment: ProjectAssignment) => Promise<void>;
  assignment: ProjectAssignment;
  clientId: string;
  canDelete: boolean;
  freelancer: Freelancer;
}

const EditProjectAssignmentDialog: React.FC<
  EditProjectAssignmentDialogProps
> = ({
  open,
  onClose,
  onSave,
  onDelete,
  assignment,
  clientId,
  canDelete,
  freelancer,
}) => {
  const [projectId, setProjectId] = useState("");
  const [csaId, setCsaId] = useState("");
  const [status, setStatus] = useState<ProjectAssignmentStatus>("interviewing");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [billableRate, setBillableRate] = useState("");
  const [budgetedHours, setBudgetedHours] = useState("");
  const [utilizedHours, setUtilizedHours] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [projects, setProjects] = useState<{ id: string; name: string }[]>([]);
  const [csas, setCsas] = useState<ConsultingServicesAgreement[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "error" | "success";
  }>({
    open: false,
    message: "",
    severity: "error",
  });

  useEffect(() => {
    const loadProjects = async () => {
      const clientProjects = await getClientProjects(clientId);
      setProjects(clientProjects);
    };
    loadProjects();
  }, [clientId]);

  useEffect(() => {
    const loadCSAs = async () => {
      if (projectId) {
        const projectCSAs = await getProjectCSAs(clientId, projectId);
        setCsas(projectCSAs);
      }
    };
    loadCSAs();
  }, [clientId, projectId]);

  useEffect(() => {
    if (open && assignment) {
      setProjectId(assignment.projectId);
      setCsaId(assignment.csaId || "");
      setStatus(assignment.status);
      setStartDate(
        assignment.startDate
          ? new Date(assignment.startDate).toISOString().split("T")[0]
          : ""
      );
      setEndDate(
        assignment.endDate
          ? new Date(assignment.endDate).toISOString().split("T")[0]
          : ""
      );
      setPaymentDate(
        assignment.paymentDate
          ? new Date(assignment.paymentDate).toISOString().split("T")[0]
          : ""
      );
      setBillableRate(assignment.billableRate?.toString() || "");
      setBudgetedHours(assignment.budgetedHours?.toString() || "");
      setUtilizedHours(assignment.utilizedHours?.toString() || "");
      setAmountPaid(assignment.amountPaid?.toString() || "");
      setPaymentMethod(assignment.paymentMethod || "");
      setFile(null);
    }
  }, [open, assignment]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });

  const isFieldRequired = (fieldName: string) => {
    if (status === "in_progress") {
      return ["project", "billableRate", "budgetedHours", "file"].includes(
        fieldName
      );
    } else if (status === "complete") {
      return [
        "startDate",
        "endDate",
        "utilizedHours",
        "amountPaid",
        "project",
        "billableRate",
        "budgetedHours",
        "file",
        "paymentDate",
        "paymentMethod",
      ].includes(fieldName);
    }
    return false;
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (isFieldRequired("projectId") && !projectId)
      newErrors.projectId = "Project is required";
    if (isFieldRequired("billableRate") && !billableRate)
      newErrors.billableRate = "Billable rate is required";
    if (isFieldRequired("budgetedHours") && !budgetedHours)
      newErrors.budgetedHours = "Budgeted hours are required";
    if (isFieldRequired("file") && !file && !assignment.fileUrl)
      newErrors.file = "File is required";
    if (isFieldRequired("startDate") && !startDate)
      newErrors.startDate = "Start date is required";
    if (isFieldRequired("endDate") && !endDate)
      newErrors.endDate = "End date is required";
    if (isFieldRequired("utilizedHours") && !utilizedHours)
      newErrors.utilizedHours = "Utilized hours are required";
    if (isFieldRequired("amountPaid") && !amountPaid)
      newErrors.amountPaid = "Amount paid is required";
    if (isFieldRequired("paymentDate") && !paymentDate)
      newErrors.paymentDate = "Payment date is required";
    if (isFieldRequired("paymentMethod") && !paymentMethod)
      newErrors.paymentMethod = "Payment method is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isFormValid = () => {
    const requiredFields = ["projectId", "status"];
    if (status === "in_progress" || status === "complete") {
      requiredFields.push("billableRate", "budgetedHours");
    }
    if (status === "complete") {
      requiredFields.push(
        "startDate",
        "endDate",
        "utilizedHours",
        "amountPaid",
        "paymentDate",
        "paymentMethod"
      );
    }

    return (
      requiredFields.every((field) => {
        const value = eval(field);
        return value !== null && value !== undefined && value !== "";
      }) &&
      (file !== null || assignment.fileUrl)
    );
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      setSnackbar({
        open: true,
        message: "Please fill in all required fields",
        severity: "error",
      });
      return;
    }

    setIsSubmitting(true);
    try {
      let fileUrl = assignment.fileUrl;

      if (file) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `clients/${clientId}/projectAssignments/${assignment.id}`
        );
        await uploadBytes(storageRef, file);
        fileUrl = await getDownloadURL(storageRef);
      }

      const updatedAssignment: Partial<ProjectAssignment> = {
        projectId,
        csaId: csaId || undefined,
        status,
        startDate: startDate ? startDate : undefined,
        endDate: endDate ? endDate : undefined,
        paymentDate: paymentDate ? paymentDate : undefined,
        billableRate: billableRate ? parseFloat(billableRate) : undefined,
        budgetedHours: budgetedHours ? parseInt(budgetedHours) : undefined,
        utilizedHours: utilizedHours ? parseInt(utilizedHours) : undefined,
        amountPaid: amountPaid ? parseFloat(amountPaid) : undefined,
        paymentMethod: paymentMethod || undefined,
        fileUrl,
        clientId,
      };

      Object.keys(updatedAssignment).forEach(
        (key) =>
          updatedAssignment[key as keyof ProjectAssignment] === undefined &&
          delete updatedAssignment[key as keyof ProjectAssignment]
      );

      await updateProjectAssignment(clientId, assignment.id, updatedAssignment);
      await onSave(updatedAssignment, file);

      setProjectId("");
      setCsaId("");
      setStatus("interviewing" as ProjectAssignmentStatus);
      setStartDate("");
      setEndDate("");
      setPaymentDate("");
      setBillableRate("");
      setBudgetedHours("");
      setUtilizedHours("");
      setAmountPaid("");
      setPaymentMethod("");
      setFile(null);
      setErrors({});

      onClose();
    } catch (error) {
      console.error("Error updating project assignment:", error);
      setSnackbar({
        open: true,
        message: "Error updating project assignment",
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    await onDelete(assignment);
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Project Assignment</DialogTitle>
        <DialogContent>
          <Typography>{freelancer.email}</Typography>
          <Typography>PA ID: {assignment.id}</Typography>
          <FormControl
            fullWidth
            margin="normal"
            error={!!errors.projectId}
            required={isFieldRequired("projectId")}
          >
            <InputLabel>
              Project {isFieldRequired("projectId") && "*"}
            </InputLabel>
            <Select
              value={projectId}
              onChange={(e) => setProjectId(e.target.value)}
            >
              {projects.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
            {errors.projectId && (
              <FormHelperText>{errors.projectId}</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Consulting Services Agreement</InputLabel>
            <Select
              value={csaId}
              onChange={(e) => setCsaId(e.target.value as string)}
              disabled={!projectId}
            >
              <MenuItem value="">None</MenuItem>
              {csas.map((csa) => (
                <MenuItem key={csa.id} value={csa.id}>
                  {csa.name} - {csa.effectiveDate}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            margin="normal"
            error={!!errors.status}
            required
          >
            <InputLabel>Status *</InputLabel>
            <Select
              value={status}
              onChange={(e) =>
                setStatus(e.target.value as ProjectAssignmentStatus)
              }
            >
              <MenuItem value="interviewing">Interviewing</MenuItem>
              <MenuItem value="ready">Ready</MenuItem>
              <MenuItem value="in_progress">In Progress</MenuItem>
              <MenuItem value="complete">Complete</MenuItem>
            </Select>
            {errors.status && <FormHelperText>{errors.status}</FormHelperText>}
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            error={!!errors.startDate}
            helperText={errors.startDate}
            required={isFieldRequired("startDate")}
          />
          <TextField
            fullWidth
            margin="normal"
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required={isFieldRequired("endDate")}
            error={!!errors.endDate}
            helperText={errors.endDate}
          />
          {status === "complete" && (
            <>
              <TextField
                fullWidth
                margin="normal"
                label="Payment Date"
                type="date"
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                required={isFieldRequired("paymentDate")}
                error={!!errors.paymentDate}
                helperText={errors.paymentDate}
              />
              <FormControl
                fullWidth
                margin="normal"
                error={!!errors.paymentMethod}
                required={isFieldRequired("paymentMethod")}
              >
                <InputLabel>
                  Payment Method {isFieldRequired("paymentMethod") && "*"}
                </InputLabel>
                <Select
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <MenuItem value="Quickbooks">Quickbooks</MenuItem>
                  <MenuItem value="Zelle">Zelle</MenuItem>
                  <MenuItem value="Venmo">Venmo</MenuItem>
                  <MenuItem value="CashApp">CashApp</MenuItem>
                  <MenuItem value="Check">Check</MenuItem>
                </Select>
                {errors.paymentMethod && (
                  <FormHelperText>{errors.paymentMethod}</FormHelperText>
                )}
              </FormControl>
            </>
          )}
          <TextField
            fullWidth
            margin="normal"
            label="Billable Rate"
            type="number"
            value={billableRate}
            onChange={(e) => setBillableRate(e.target.value)}
            error={!!errors.billableRate}
            helperText={errors.billableRate}
            required={isFieldRequired("billableRate")}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Budgeted Hours"
            type="number"
            value={budgetedHours}
            onChange={(e) => setBudgetedHours(e.target.value)}
            error={!!errors.budgetedHours}
            helperText={errors.budgetedHours}
            required={isFieldRequired("budgetedHours")}
          />
          {status === "complete" && (
            <>
              <TextField
                fullWidth
                margin="normal"
                label="Utilized Hours"
                type="number"
                value={utilizedHours}
                onChange={(e) => setUtilizedHours(e.target.value)}
                error={!!errors.utilizedHours}
                helperText={errors.utilizedHours}
                required={isFieldRequired("utilizedHours")}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Amount Paid"
                type="number"
                value={amountPaid}
                onChange={(e) => setAmountPaid(e.target.value)}
                error={!!errors.amountPaid}
                helperText={errors.amountPaid}
                required={isFieldRequired("amountPaid")}
              />
            </>
          )}
          <Box
            {...getRootProps()}
            sx={{ mt: 2, p: 2, border: "1px dashed grey", borderRadius: 1 }}
          >
            <input {...getInputProps()} />
            <Typography>
              {file
                ? `Selected file: ${file.name}`
                : assignment.fileUrl
                  ? "File already uploaded. Drop a new file here to replace, or click to select a file"
                  : "Drag and drop a file here, or click to select a file"}
            </Typography>
          </Box>
          {errors.file && <FormHelperText error>{errors.file}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          {canDelete && (
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          )}
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={isSubmitting || !isFormValid()}
          >
            {isSubmitting ? "Saving..." : "Save Changes"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditProjectAssignmentDialog;
