import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Grid, InputLabel, Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';
import { createInvoice } from 'core/api/invoices';
import { loadAllClients } from 'core/api/clients';
import { getClientProjects } from 'core/api/projects';
import { getProjectCSAs } from 'core/api/csa';
import { createAndSavePDF } from 'core/api/invoices/createAndSavePDF';

interface InvoiceFormProps {
  onSubmit: (invoiceData: InvoiceData) => void;
}

const InvoiceForm: React.FC<InvoiceFormProps> = ({ onSubmit }) => {
  const [invoiceData, setInvoiceData] = useState<InvoiceData>({
    invoiceNumber: '',
    invoiceDate: new Date().toISOString().split('T')[0],
    dueDate: '',
    billTo: { name: '', company: '' },
    shipTo: { name: '', company: '' },
    items: [{ description: '', quantity: 1, rate: 0 }],
    terms: 'Net 10',
    discount: 0,
    id: '',
    projectId: '',
    csaId: '',
    clientId: '',
    clientName: '',
    projectName: '',
    csaName: '',
  });

  useEffect(() => {
    fetchClients();
  }, []);

  const [clients, setClients] = useState<Client[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [csas, setCSAs] = useState<ConsultingServicesAgreement[]>([]);

  const fetchClients = async () => {
    const fetchedClients = await loadAllClients();
    setClients(fetchedClients);
  };

  const fetchProjects = async (clientId: string) => {
    const fetchedProjects = await getClientProjects(clientId);
    setProjects(fetchedProjects);
  };

  const fetchCSAs = async (clientId: string, projectId: string) => {
    const fetchedCSAs = await getProjectCSAs(clientId, projectId);
    setCSAs(fetchedCSAs);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInvoiceData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddressChange = (type: 'billTo' | 'shipTo', field: 'name' | 'company', value: string) => {
    setInvoiceData(prev => ({
      ...prev,
      [type]: { ...prev[type], [field]: value }
    }));
  };

  const handleItemChange = (index: number, field: keyof InvoiceData['items'][0], value: string | number) => {
    setInvoiceData(prev => ({
      ...prev,
      items: prev.items.map((item, i) => 
        i === index ? { ...item, [field]: field === 'description' ? value : Number(value) } : item
      )
    }));
  };

  const handleAddItem = () => {
    setInvoiceData(prev => ({
      ...prev,
      items: [...prev.items, { description: '', quantity: 1, rate: 0 }]
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Create the invoice in Firestore
      const invoiceId = await createInvoice(invoiceData.clientId, invoiceData);
      console.log('Invoice created with ID:', invoiceId);

      // Generate the PDF and save it to Firebase Storage
      const pdfUrl = await createAndSavePDF(invoiceData, invoiceData.clientId, invoiceId);
      console.log('PDF generated and saved. URL:', pdfUrl);

      // Call the onSubmit prop with the updated invoice data
      onSubmit({ ...invoiceData, id: invoiceId, pdfUrl });

      // Offer the PDF for download
      // const link = document.createElement('a');
      // link.href = pdfUrl;
      // link.download = `Invoice_${invoiceData.invoiceNumber}.pdf`;
      // link.click();

    } catch (error) {
      console.error('Error creating invoice:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const handleSelectClientDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setInvoiceData({ ...invoiceData, [name]: value });

    if (name === 'clientId') {
      setInvoiceData(prev => ({ ...prev, projectId: '', csaId: '' }));
      fetchProjects(value);
    } else if (name === 'projectId') {
      setInvoiceData(prev => ({ ...prev, csaId: '' }));
      fetchCSAs(invoiceData.clientId, value);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>Invoice Details</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
        <FormControl fullWidth margin="normal">

        <InputLabel>Client</InputLabel>
          <Select
            name="clientId"
            value={invoiceData.clientId}
            onChange={(e) => handleSelectClientDataChange(e as React.ChangeEvent<HTMLInputElement>)}
          >
            {clients.map((client) => (
              <MenuItem key={client.id} value={client.id}>{client.companyName}</MenuItem>
            ))}
          </Select>
          </FormControl>


        </Grid>
        <Grid item xs={12} sm={4}>
        <FormControl fullWidth margin="normal">
        <InputLabel>Project</InputLabel>
          <Select
            name="projectId"
            value={invoiceData.projectId}
            onChange={(e) => handleSelectClientDataChange(e as React.ChangeEvent<HTMLInputElement>)}
            disabled={!invoiceData.clientId}
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
            ))}
          </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
        <FormControl fullWidth margin="normal">
        <InputLabel>Consulting Services Agreement</InputLabel>
          <Select
            name="csaId"
            value={invoiceData.csaId}
            onChange={(e) => handleSelectClientDataChange(e as React.ChangeEvent<HTMLInputElement>)}
            disabled={!invoiceData.projectId}
          >
            {csas.map((csa) => (
              <MenuItem key={csa.id} value={csa.id}>{csa.name}</MenuItem>
            ))}
          </Select>
          </FormControl>
        </Grid>
      </Grid>
      
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Invoice Number"
            name="invoiceNumber"
            value={invoiceData.invoiceNumber}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Invoice Date"
            name="invoiceDate"
            type="date"
            value={invoiceData.invoiceDate}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Due Date"
            name="dueDate"
            type="date"
            value={invoiceData.dueDate}
            onChange={handleChange}
            required
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Bill To</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Name"
            value={invoiceData.billTo.name}
            onChange={(e) => handleAddressChange('billTo', 'name', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Company"
            value={invoiceData.billTo.company}
            onChange={(e) => handleAddressChange('billTo', 'company', e.target.value)}
            required
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Ship To</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Name"
            value={invoiceData.shipTo.name}
            onChange={(e) => handleAddressChange('shipTo', 'name', e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Company"
            value={invoiceData.shipTo.company}
            onChange={(e) => handleAddressChange('shipTo', 'company', e.target.value)}
            required
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Items</Typography>
      {invoiceData.items.map((item, index) => (
        <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Description"
              value={item.description}
              onChange={(e) => handleItemChange(index, 'description', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Quantity"
              type="number"
              value={item.quantity}
              onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Rate"
              type="number"
              value={item.rate}
              onChange={(e) => handleItemChange(index, 'rate', e.target.value)}
              required
            />
          </Grid>
        </Grid>
      ))}
      <Button onClick={handleAddItem} variant="outlined" sx={{ mt: 1, mb: 2 }}>
        Add Item
      </Button>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Terms"
            name="terms"
            value={invoiceData.terms}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Discount (%)"
            name="discount"
            type="number"
            value={invoiceData.discount}
            onChange={handleChange}
            required
          />
        </Grid>
      </Grid>

      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Generate Invoice
      </Button>
    </Box>
  );
};

export default InvoiceForm;