import { getHeaders } from "core/api/getHeaders";
import { encryptPayload } from "../encryptPayload";
import { increaseFunctionUrls } from "../increaseFunctionUrls";

export const createExternalAccount = async (
  externalAccountDetails: typeof increaseFunctionUrls.externalAccounts.createExternalAccount.input
): Promise<typeof increaseFunctionUrls.externalAccounts.createExternalAccount.output> => {
  const response = await fetch(increaseFunctionUrls.externalAccounts.createExternalAccount.url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ payload: encryptPayload(externalAccountDetails) })
  });

  if (!response.ok) {
    throw new Error("Failed to create external account");
  }

  return response.json();
};
