import { getFileUrl } from "core/api/storage";
export const getCSAFileFromStorage = async (
  clientId: string,
  projectId: string,
  csaId: string
): Promise<string | null> => {
  try {
    return await getFileUrl(
      `clients/${clientId}/projects/${projectId}/csas/${csaId}`
    );
  } catch (error) {
    console.error("Error fetching CSA file:", error);
    return null;
  }
};
