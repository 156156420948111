import React, { useState, useEffect } from 'react';
import { functionUrls } from "core/api";
import { UseEversignTemplateOutput, EversignDocumentBody, Signer, Field } from "core/api/functionUrls/types";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  InputAdornment,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardLayout from 'layouts/DashboardLayout';
import { getUsersByType } from 'core/api/users';
import { useAuth } from 'core/contexts/AuthContext';
import { getClientProjects } from 'core/api/projects';
import { loadAllClients } from 'core/api/clients';
import { getProjectCSAs } from 'core/api/csa';
import InfoIcon from '@mui/icons-material/Info';
import { PAGeneratorVerification } from './components/PAGeneratorVerification';
import { useLocalStorageState } from '@toolpad/core';

const TEMPLATE_IDS = [
  { id: '0046fee9787e45a99876f14f133a11c1', name: 'Project Assignment Form V1' },
];

type User = Stakeholder | Freelancer | UserInfoType;

const projectAssignmentFields: Field[] = [
  { identifier: 'effective_date', value: '' },
  { identifier: 'conclusion_date', value: '' },
  { identifier: 'project_name', value: '' },
  { identifier: 'client_name', value: '' },
  { identifier: 'billable_rate', value: '' },
  { identifier: 'maximum_billable_hours', value: '' },
  { identifier: 'maximum_dollar_budget', value: '' },
  { identifier: 'scope_of_work', value: '' }
];

export interface DocumentState extends EversignDocumentBody {
  selectedClientId: string;
  selectedProjectId: string;
  activeCSAs: ConsultingServicesAgreement[];
  selectedCSA: string;
}

export const ProjectAssignmentGenerator: React.FC = () => {
  const { userInfo } = useAuth();
  const createdBy = userInfo?.email || '';
  // const [stagedDocuments, setStagedDocuments] = useLocalStorageState<DocumentState[]>('stagedDocuments', []);
  const [stagedDocuments, setStagedDocuments] = useState<DocumentState[]>([]);
  const [adminUsers, setAdminUsers] = useState<User[]>([]);
  const [freelancerUsers, setFreelancerUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success'
  });
  const [clients, setClients] = useState<Client[]>([]);
  const [projects, setProjects] = useState<{ [clientId: string]: Project[] }>({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const admins = await getUsersByType('admin');
      const freelancers = await getUsersByType('freelancer');
      setAdminUsers(admins);
      setFreelancerUsers(freelancers);
      console.log('Admin users:', admins); // Add this line for debugging
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      const fetchedClients = await loadAllClients();
      setClients(fetchedClients);
    };
    fetchClients();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      const projectsByClient: { [clientId: string]: Project[] } = {};
      for (const client of clients) {
        const fetchedProjects = await getClientProjects(client.id);
        projectsByClient[client.id] = fetchedProjects;
      }
      setProjects(projectsByClient);
    };
    fetchProjects();
  }, [clients]);

  const handleAddDocument = () => {
    setStagedDocuments([...stagedDocuments, {
      ...{
        template_id: TEMPLATE_IDS[0].id,
        title: 'Project Assignment Form',
        message: 'Please review and sign the Project Assignment Form.',
        signers: [
          { name: '', email: '', deliver_email: 1, role: 'Adam Siwiec', order: 1, language: 'en' },
          { name: '', email: '', deliver_email: 1, role: 'Engineer', order: 2, language: 'en' }
        ],
        fields: projectAssignmentFields,
        sandbox: 1,
        embedded_signing_enabled: 1,
        recipients: []
      },
      selectedClientId: '',
      selectedProjectId: '',
      activeCSAs: [],
      selectedCSA: ''
    }]);
  };

  const handleRemoveDocument = (index: number) => {
    setStagedDocuments(stagedDocuments.filter((_, i) => i !== index));
  };

  const handleChange = (index: number, field: string, value: string) => {
    setStagedDocuments(stagedDocuments.map((doc, i) => {
      if (i === index) {
        return {
          ...doc,
          fields: doc.fields.map(f => f.identifier === field ? { ...f, value } : f)
        };
      }
      return doc;
    }));
  };

  const handleSignerChange = (docIndex: number, signerIndex: number, field: keyof Signer, value: string) => {
    setStagedDocuments(prevDocs => prevDocs.map((doc, i) => {
      if (i === docIndex) {
        const newSigners = [...doc.signers];
        newSigners[signerIndex] = { ...newSigners[signerIndex], [field]: value };
        return { ...doc, signers: newSigners };
      }
      return doc;
    }));
  };

  const handleClientChange = async (clientId: string, docIndex: number) => {
    const selectedClient = clients.find(c => c.id === clientId);
    if (selectedClient) {
      setStagedDocuments(stagedDocuments.map((doc, i) => {
        if (i === docIndex) {
          return {
            ...doc,
            selectedClientId: clientId,
            selectedProjectId: '',
            fields: doc.fields.map(f => 
              f.identifier === 'client_name' ? { ...f, value: selectedClient.companyName } :
              f.identifier === 'project_name' ? { ...f, value: '' } : f
            ),
            activeCSAs: [],
            selectedCSA: ''
          };
        }
        return doc;
      }));
    }
  };

  const handleProjectChange = async (projectId: string, docIndex: number) => {
    const doc = stagedDocuments[docIndex];
    const selectedProject = projects[doc.selectedClientId]?.find(p => p.id === projectId);
    if (selectedProject) {
      const allCSAs = await getProjectCSAs(doc.selectedClientId, projectId);
      const activeCSAs = allCSAs.filter(csa => csa.status === 'active');
      setStagedDocuments(stagedDocuments.map((d, i) => {
        if (i === docIndex) {
          return {
            ...d,
            selectedProjectId: projectId,
            fields: d.fields.map(f => 
              f.identifier === 'project_name' ? { ...f, value: selectedProject.name } : f
            ),
            activeCSAs,
            selectedCSA: activeCSAs.length === 1 ? activeCSAs[0].id : ''
          };
        }
        return d;
      }));
    }
  };

  const handleCSAChange = (csaId: string, docIndex: number) => {
    setStagedDocuments(stagedDocuments.map((doc, i) => {
      if (i === docIndex) {
        return { ...doc, selectedCSA: csaId };
      }
      return doc;
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setConfirmDialogOpen(true);
  };

  const handleConfirmGenerate = async () => {
    setConfirmDialogOpen(false);
    setLoading(true);

    try {
      const { url, method } = functionUrls.eversign.useEversignTemplate;
      const results = await Promise.all(stagedDocuments.map(async (doc) => {
        const response = await fetch(url, {
          method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            created_by: createdBy,
            document_data: doc,
            clientId: doc.selectedClientId,
            projectId: doc.selectedProjectId,
            csaId: doc.selectedCSA,
            freelancerId: doc.signers[1]?.email,
            reporterId: doc.signers[0]?.email,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json() as UseEversignTemplateOutput;
      }));

      setSnackbar({
        open: true,
        message: `Successfully generated ${results.length} project assignment(s)`,
        severity: 'success'
      });
      setStagedDocuments([]);
    } catch (error) {
      console.error('Error:', error);
      setSnackbar({
        open: true,
        message: 'Error generating project assignments',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const renderCSAHint = (doc: DocumentState) => {
    if (!doc.selectedClientId || !doc.selectedProjectId) {
      return null;
    }

    if (doc.activeCSAs.length === 0) {
      return (
        <Typography variant="body2" color="error">
          No CSAs found for this project, please contact Adam
        </Typography>
      );
    }

    if (doc.activeCSAs.length === 1) {
      const csa = doc.activeCSAs[0];
      return (
        <Typography variant="body2" color="success.main">
          Active CSA Detected: {csa.name}
        </Typography>
      );
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" color="warning.main" sx={{ mr: 1 }}>
          Multiple CSAs detected, please pick one:
        </Typography>
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <Select
            value={doc.selectedCSA}
            onChange={(e) => handleCSAChange(e.target.value as string, stagedDocuments.indexOf(doc))}
            displayEmpty
            variant="outlined"
          >
            <MenuItem value="">
              <em>Select a CSA</em>
            </MenuItem>
            {doc.activeCSAs.map((csa) => (
              <MenuItem key={csa.id} value={csa.id}>
                {csa.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  };

  const renderEditView = (doc: DocumentState, docIndex: number) => {
    const calculateMaxDollarBudget = () => {
      const rate = parseFloat(doc.fields.find(f => f.identifier === 'billable_rate')?.value || '0');
      const hours = parseFloat(doc.fields.find(f => f.identifier === 'maximum_billable_hours')?.value || '0');
      return (rate * hours).toFixed(2);
    };

    const maxDollarBudget = calculateMaxDollarBudget();

    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Maximum Dollar Budget: ${maxDollarBudget}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Client</InputLabel>
              <Select
                value={doc.selectedClientId || ''}
                onChange={(e) => handleClientChange(e.target.value as string, docIndex)}
                label="Client"
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.companyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Project</InputLabel>
              <Select
                value={doc.selectedProjectId || ''}
                onChange={(e) => handleProjectChange(e.target.value as string, docIndex)}
                label="Project"
                disabled={!doc.selectedClientId}
              >
                {projects[doc.selectedClientId]?.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Effective Date"
              type="date"
              name="effective_date"
              value={doc.fields.find(f => f.identifier === 'effective_date')?.value || ''}
              onChange={(e) => handleChange(docIndex, 'effective_date', e.target.value)}
              required
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Conclusion Date"
              type="date"
              name="conclusion_date"
              value={doc.fields.find(f => f.identifier === 'conclusion_date')?.value || ''}
              onChange={(e) => handleChange(docIndex, 'conclusion_date', e.target.value)}
              required
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Billable Rate"
              name="billable_rate"
              type="number"
              value={doc.fields.find(f => f.identifier === 'billable_rate')?.value || ''}
              onChange={(e) => handleChange(docIndex, 'billable_rate', e.target.value)}
              required
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Maximum Billable Hours"
              name="maximum_billable_hours"
              type="number"
              value={doc.fields.find(f => f.identifier === 'maximum_billable_hours')?.value || ''}
              onChange={(e) => handleChange(docIndex, 'maximum_billable_hours', e.target.value)}
              required
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Admin Signer</InputLabel>
              <Select
                value={doc.signers[0]?.email || ''}
                onChange={(e) => {
                  const selectedUser = adminUsers.find(user => user.email === e.target.value);
                  if (selectedUser) {
                    handleSignerChange(docIndex, 0, 'email', selectedUser.email);
                    handleSignerChange(docIndex, 0, 'name', `${selectedUser.firstName} ${selectedUser.lastName}`);
                  }
                }}
                required
              >
                {adminUsers.map((user) => (
                  <MenuItem key={user.id} value={user.email}>
                    {`${user.firstName} ${user.lastName} (${user.email})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Freelancer Signer</InputLabel>
              <Select
                value={doc.signers[1]?.email || ''}
                onChange={(e) => {
                  const selectedUser = freelancerUsers.find(user => user.email === e.target.value);
                  if (selectedUser) {
                    handleSignerChange(docIndex, 1, 'email', selectedUser.email);
                    handleSignerChange(docIndex, 1, 'name', `${selectedUser.firstName} ${selectedUser.lastName}`);
                  }
                }}
                required
              >
                {freelancerUsers.map((user) => (
                  <MenuItem key={user.id} value={user.email}>
                    {user.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Scope of Work"
              name="scope_of_work"
              multiline
              rows={4}
              value={doc.fields.find(f => f.identifier === 'scope_of_work')?.value || ''}
              onChange={(e) => handleChange(docIndex, 'scope_of_work', e.target.value)}
              required
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  console.log('Staged documents:', stagedDocuments);

  return (
    <DashboardLayout>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Project Assignment Generator
        </Typography>
        <Card sx={{ mb: 3, cursor: 'pointer' }} onClick={handleAddDocument}>
          <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
            <Typography variant="h6">
              <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Generate New Project Assignment
            </Typography>
          </CardContent>
        </Card>
        <form onSubmit={handleSubmit}>
          {stagedDocuments.map((doc, docIndex) => (
            <Card key={docIndex} sx={{ mb: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">Project Assignment {docIndex + 1}</Typography>
                {renderCSAHint(doc)}
                  <IconButton onClick={() => handleRemoveDocument(docIndex)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Box>
                {renderEditView(doc, docIndex)}
              </CardContent>
            </Card>
          ))}
          {stagedDocuments.length > 0 && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              {loading ? 'Submitting...' : 'Generate Assignments'}
            </Button>
          )}
        </form>
      </Box>
      <PAGeneratorVerification
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirmGenerate}
        documents={stagedDocuments}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
};