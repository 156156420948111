import { updateDocument } from "core/api/firestore";

export const updateProjectAssignmentStatus = async (
  clientId: string,
  assignmentId: string,
  newStatus: ProjectAssignmentStatus,
  fileUrl?: string
): Promise<void> => {
  const updateData: Partial<ProjectAssignment> = { status: newStatus };
  if (fileUrl) {
    updateData.fileUrl = fileUrl;
  }
  await updateDocument(`clients/${clientId}/projectAssignments/${assignmentId}`, updateData);
};
