import * as React from 'react';
import { AppRoutes } from './routes/AppRoutes';
import { AuthProvider } from './core/contexts/AuthContext';
import { SnackbarProvider } from 'core/contexts/snackBarContext';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <SnackbarProvider>
          <AppRoutes />
        </SnackbarProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
  );
}
