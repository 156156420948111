import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { createFirebaseUser } from "core/api/users";
import {
  collection,
  getDocs,
  getFirestore,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";

const db = getFirestore();

interface CreateUserProps {
  onSuccess: () => void;
  onError: (error: string) => void;
}

export const CreateUser: React.FC<CreateUserProps> = ({ onSuccess, onError }) => {
  const [userData, setUserData] = useState<any>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    user_type: "",
    associatedClientId: "", // For storing selected client ID
  });
  const [open, setOpen] = useState(false);
  const [useTempPassword, setUseTempPassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [errorCreatingUser, setErrorCreatingUser] = useState<string | null>(
    null
  );
  const [isUserTypeSelected, setIsUserTypeSelected] = useState(true);
  const [clients, setClients] = useState<Client[]>([]); // For storing clients

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setIsEmailValid(true);
    setIsPasswordValid(true);
    setErrorCreatingUser(null);
    setOpen(false);
  };

  useEffect(() => {
    const fetchClients = async () => {
      const querySnapshot = await getDocs(collection(db, "clients"));
      const clientsList: Client[] = [];
      querySnapshot.forEach((doc) => {
        clientsList.push({ id: doc.id, ...doc.data() } as Client);
      });
      setClients(clientsList);
    };

    fetchClients();
  }, []);

  const generateTempPassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$";

    const getRandomChar = (charSet: any) =>
      charSet.charAt(Math.floor(Math.random() * charSet.length));

    const requiredChars = [
      getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ"),
      getRandomChar("0123456789"),
    ];

    const remainingChars = length - requiredChars.length;
    const allChars = charset.replace(/[A-Z0-9]/g, "");

    const randomChars = Array.from({ length: remainingChars }, () =>
      getRandomChar(allChars)
    );
    const tempPassword = [...requiredChars, ...randomChars]
      .sort(() => Math.random() - 0.5)
      .join("");

    return tempPassword;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "useTempPassword") {
      setUseTempPassword(e.target.checked);
      if (e.target.checked) {
        setUserData({ ...userData, password: generateTempPassword() });
      } else {
        setUserData({ ...userData, password: "" });
      }
    } else {
      setUserData({
        ...userData,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      });
    }
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const userId = await createFirebaseUser(userData);

      if (!userId) {
        setErrorCreatingUser("Error creating user.");
        return;
      }

      // If user type is client, associate user with the selected client
      if (userData.user_type === "client" && userData.associatedClientId) {
        const clientRef = doc(db, "clients", userData.associatedClientId);

        const clientDoc = await getDoc(clientRef);

        const docData = clientDoc.data();
        if (!docData) {
          throw new Error("Client not found");
        }

        await updateDoc(clientRef, {
          associatedStakeholders: [...docData.associatedStakeholders, userId],
        });
      }

      onSuccess();
      // Reset form fields
      setUserData({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        user_type: "",
        associatedClientId: "", // For storing selected client ID
      });
      setIsEmailValid(true);
      setIsPasswordValid(true);
      setErrorCreatingUser(null);
      setIsUserTypeSelected(true);
      setClients([]);
    } catch (error) {
      console.error("Error creating user", error);
      setErrorCreatingUser("Error creating user");
      onError(error instanceof Error ? error.message : "Unknown error occurred");
    }
  };

  return (
    <>
      <Box sx={{ marginBottom: 4 }}>
        <Button variant="outlined" onClick={handleOpen}>
          Create User
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={userData.email}
                  onChange={handleChange}
                  error={!isEmailValid}
                  helperText={!isEmailValid ? "Invalid email address" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="fname"
                  value={userData.firstName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={userData.lastName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={!useTempPassword}
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={userData.password}
                  onChange={handleChange}
                  disabled={useTempPassword}
                  error={!isPasswordValid}
                  helperText={
                    !isPasswordValid
                      ? "Password does not meet requirements. Must be 8 characters and contain a number."
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>User Type</InputLabel>
                  <Select
                    label="user_type"
                    name="user_type"
                    value={userData.user_type}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="freelancer">Freelancer</MenuItem>
                    <MenuItem value="stakeholder">Stakeholder</MenuItem>
                  </Select>
                  {!isUserTypeSelected && (
                    <Typography color="error" variant="caption">
                      Please select a user type.
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              {userData.user_type === "client" && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Select Client</InputLabel>
                    <Select
                      label="associatedClientId"
                      name="associatedClientId"
                      value={userData.associatedClientId}
                      onChange={handleSelectChange}
                    >
                      {clients.map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                          {client.companyName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useTempPassword}
                      onChange={handleChange}
                      name="useTempPassword"
                    />
                  }
                  label="Send Temp Password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={useTempPassword}
                      checked={userData ? userData.email_verified : false}
                      onChange={handleChange}
                      name="email_verified"
                    />
                  }
                  label="Email Verified"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={userData.is_active}
                      onChange={handleChange}
                      name="is_active"
                    />
                  }
                  label="Active"
                />
              </Grid>
              <Typography sx={{ color: "red" }}>
                {errorCreatingUser && `${errorCreatingUser}`}
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Create User
              </Button>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
