import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'core/contexts/AuthContext'
import { IsLoading, NotFound } from 'components'

interface StakeholderRouteProps {
  children: React.ReactNode
}

const useStakeholderRoute = () => {
  const { isAuthenticated, userInfo, isLoading } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
    } else if ((userInfo && userInfo.user_type !== 'stakeholder') || !userInfo?.user_type) {
      console.log('userInfo', userInfo)
      console.log('not found in stakeholder route')
      navigate(`/not-found`)
    }
  }, [isAuthenticated, navigate])

  return { isAuthenticated, isLoading }
}

const StakeholderRoute = ({ children }: StakeholderRouteProps) => {
  const { isAuthenticated, isLoading } = useStakeholderRoute()

  if (isLoading) {
    return <IsLoading />
  }

  if (!isAuthenticated) {
    return <NotFound />
  }

  return <>{children}</>
}

export default StakeholderRoute
