import React, { useEffect } from 'react'
import { useAuth } from 'core/contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

interface AuthRouteProps {
  children: React.ReactNode
}

const useAuthRoute = () => {
const { isAuthenticated, userInfo } = useAuth()
  const navigate = useNavigate()
  const userType = userInfo?.user_type
  useEffect(() => {
    const handleRedirect = () => {
      navigate(`/${userType}`)
    }
    if (isAuthenticated && userType) {
      handleRedirect()
    }
  }, [isAuthenticated, navigate, userType])

  return { isAuthenticated }
}

const AuthRoute = ({ children }: AuthRouteProps) => {
  const { isAuthenticated } = useAuthRoute()
  console.log(isAuthenticated)

  return <>{children}</>
}

export default AuthRoute

// http://localhost:3000/__/auth/action?mode=verifyEmail&oobCode=IeT3g5G4qXNtm4sQb4f3FGlc7VeFm49W9Ig7wNndWvAAAAGQlY3Y5A&apiKey=AIzaSyAjMrO1o1Yp9721nlqGXRHiou6sBUc62gI&lang=en
