import React from 'react';
import { DashboardLayout as MuiDashboardLayout } from '@toolpad/core';
import { Box } from '@mui/material';
import Footer from 'components/Footer';

interface Props {
    children: React.ReactNode;
}


export const DashboardLayout: React.FC<Props> = ({ children }) => {
  return (
    <MuiDashboardLayout>
      <Box
        maxWidth="xl"
        sx={{ 
          marginTop: 2,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <main>
          {children}
        </main>
      </Box>
      <Footer />
    </MuiDashboardLayout>
  );
};


export default DashboardLayout;
