import React, { useState, useEffect, useMemo } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { getAuth } from 'firebase/auth'
import DashboardLayout from '../../../layouts/DashboardLayout'
import ViewFileDialog from '../../../components/ViewFileDialog'
import ProjectAssignmentsOverview from './components/ProjectAssignmentsOverview'
import ProjectAssignmentCard from './components/ProjectAssignmentCard'
import ProjectAssignmentsHeader from './components/ProjectAssignmentsHeader'
import { useCollection } from 'core/hooks/useCollection'

const ProjectAssignments: React.FC = () => {
  const [assignments, setAssignments] = useState<ProjectAssignment[]>([])
  const [filteredAssignments, setFilteredAssignments] = useState<ProjectAssignment[]>([])
  const [selectedFile, setSelectedFile] = useState<{ url: string; name: string } | null>(null)
  const [openFileDialog, setOpenFileDialog] = useState(false)
  const [clients, setClients] = useState<string[]>([])
  const [selectedClient, setSelectedClient] = useState<string>('all')
  const [selectedStatus, setSelectedStatus] = useState<string>('all')
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const subcollectionNamesUsers = useMemo(() => ['projectAssignments'], [])
  const subcollectionNamesClients = useMemo(() => ['projectAssignments', 'projects', 'engineers'], [])
  const usersCollection = useCollection<Client>('users', true, subcollectionNamesUsers)
  const clientsCollection = useCollection<Client>('clients', true, subcollectionNamesClients)


  useEffect(() => {

    const processAssignments = () => {
      const auth = getAuth()
      const user = auth.currentUser
      if (!user) {
        console.warn('No authenticated user found.')
        return
      }

      //@ts-ignore
      const currentUser = usersCollection.data.find(u => u.id === user.uid)
      if (!currentUser) {
        return
      }

      const userProjectAssignments = currentUser.subcollections?.projectAssignments || []

      console.log('User Project Assignments:', userProjectAssignments)

      const assignmentsData: ProjectAssignment[] = userProjectAssignments.map(assignment => {
        const { clientId, assignmentId, projectId } = assignment

        //@ts-ignore
        const client = clientsCollection.data.find(c => c.id === clientId)
        if (!client) {
          return null
        }
        const projectAssignment = client.subcollections?.projectAssignments.find(pa => pa.id === assignmentId)
        if (!projectAssignment) {
          return null
        }

        const project = client.subcollections?.projects.find(p => p.id === projectId)
        const projectName = project?.name || 'Unknown Project'

        return {
          ...projectAssignment,
          id: assignmentId,
          projectId,
          projectName,
          engineerId: user.uid,
          startDate: projectAssignment.startDate || null,
          endDate: projectAssignment.endDate || null,
        }
      }).filter(a => a !== null) as ProjectAssignment[]

      console.log('Enriched Assignments:', assignmentsData)

      setAssignments(assignmentsData)
      setFilteredAssignments(assignmentsData)

      const uniqueClients = Array.from(new Set(assignmentsData.map(a => a.projectName?.split(' - ')[0] || 'Unknown Client')))
      setClients(uniqueClients)
    }

    if (
      usersCollection.data &&
      clientsCollection.data &&
      !usersCollection.isLoading &&
      !clientsCollection.isLoading &&
      !usersCollection.isError &&
      !clientsCollection.isError
    ) {
      processAssignments()
    }
  }, [usersCollection.data, clientsCollection.data, usersCollection.isLoading, clientsCollection.isLoading, usersCollection.isError, clientsCollection.isError])

  useEffect(() => {
    filterAssignments()
  }, [selectedClient, selectedStatus, startDate, endDate, assignments])

  const filterAssignments = () => {
    let filtered = assignments

    if (selectedClient !== 'all') {
      filtered = filtered.filter(a => a.projectName?.startsWith(selectedClient))
    }

    if (selectedStatus !== 'all') {
      filtered = filtered.filter(a => a.status === selectedStatus)
    }

    if (startDate) {
      filtered = filtered.filter(a => a.startDate && a.startDate >= startDate)
    }

    if (endDate) {
      filtered = filtered.filter(a => a.endDate && a.endDate <= endDate)
    }

    setFilteredAssignments(filtered)
  }

  const handleViewFile = (assignment: ProjectAssignment) => {
    if (assignment.fileUrl) {
      setSelectedFile({ url: assignment.fileUrl, name: `${assignment.projectName} - Contract` })
      setOpenFileDialog(true)
    }
  }

  const getStatusColor = (
    status: ProjectAssignment['status']
  ): "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" => {
    switch (status) {
      case 'in_progress': return 'success'
      case 'complete': return 'secondary'
      case 'ready': return 'warning'
      case 'interviewing': return 'info'
      default: return 'default'
    }
  }

  if (usersCollection.isLoading || clientsCollection.isLoading) {
    return (
      <DashboardLayout>
        <Box sx={{ p: 3 }}>
          <Typography>Loading Project Assignments...</Typography>
        </Box>
      </DashboardLayout>
    )
  }

  if (usersCollection.isError || clientsCollection.isError) {
    return (
      <DashboardLayout>
        <Box sx={{ p: 3 }}>
          <Typography>Error loading data. Please try again later.</Typography>
        </Box>
      </DashboardLayout>
    )
  }

  return (
    <DashboardLayout>
      <Box sx={{ p: 3 }}>
        <ProjectAssignmentsOverview assignments={assignments} />

        <ProjectAssignmentsHeader
          clients={clients}
          onClientChange={setSelectedClient}
          onStatusChange={setSelectedStatus}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
        />

        <Grid container spacing={3}>
          {filteredAssignments.map((assignment) => (
            <Grid item xs={12} sm={6} md={4} key={assignment.id}>
              <ProjectAssignmentCard
                assignment={assignment}
                onViewFile={handleViewFile}
                getStatusColor={getStatusColor}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {selectedFile && (
        <ViewFileDialog
          open={openFileDialog}
          onClose={() => setOpenFileDialog(false)}
          fileUrl={selectedFile.url}
          fileName={selectedFile.name}
        />
      )}
    </DashboardLayout>
  )
}

export default ProjectAssignments
