import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from '@mui/material';

interface FileUploadDialogProps {
  open: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
}

const FileUploadDialog: React.FC<FileUploadDialogProps> = ({ open, onClose, onUpload }) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (file) {
      onUpload(file);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload Project Assignment File</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <input
            accept=".pdf,.doc,.docx"
            style={{ display: 'none' }}
            id="assignment-file-upload"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="assignment-file-upload">
            <Button variant="contained" component="span">
              Select File
            </Button>
          </label>
          {file && <Box mt={1}>Selected file: {file.name}</Box>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpload} color="primary" disabled={!file}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;