import React from 'react';
import { Box, Typography, Card, CardContent, Grid, Paper } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

interface ProjectAssignmentsOverviewProps {
  assignments: ProjectAssignment[];
}

const ProjectAssignmentsOverview: React.FC<ProjectAssignmentsOverviewProps> = ({ assignments }) => {
  const totalAssignments = assignments.length;
  const activeAssignments = assignments.filter(a => a.status === 'in_progress').length;
  const completedAssignments = assignments.filter(a => a.status === 'complete').length;
  const onHoldAssignments = assignments.filter(a => a.status === 'ready').length;
  const interviewingAssignments = assignments.filter(a => a.status === 'interviewing').length;

  // Calculate current monthly budget and max monthly budget
  const currentMonthlyBudget = assignments
    .filter(a => a.status === 'in_progress')
    .reduce((sum, a) => sum + ((a.budgetedHours || 0) * (a.billableRate || 0)), 0);

  const maxMonthlyBudget = Math.max(...assignments.map(a => (a.budgetedHours || 0) * (a.billableRate || 0)));

  // Calculate average and max bill rate
  const billRates = assignments.map(a => a.billableRate || 0).filter(rate => rate > 0);
  const averageBillRate = billRates.length > 0 ? billRates.reduce((sum, rate) => sum + rate, 0) / billRates.length : 0;
  const maxBillRate = Math.max(...billRates);

  // Calculate amount paid per client
  const amountPaidPerClient = assignments.reduce((acc, assignment) => {
    const clientName = assignment.projectName?.split(' - ')[0] || 'Unknown Client'; // Assuming project name format is "ClientName - ProjectName"
    const amount = assignment.amountPaid || 0;
    acc[clientName] = (acc[clientName] || 0) + amount;
    return acc;
  }, {} as Record<string, number>);

  const amountPaidData = Object.entries(amountPaidPerClient).map(([clientName, amount], index) => ({
    id: index,
    value: amount,
    label: clientName,
  }));

  const statusColors = {
    Total: 'black',
    Active: '#4caf50',
    Completed: 'grey',
    Interviewing: '#9c27b0',
    AverageBillRate: '#ff9800', // Changed to a green shade
    MaxBillRate: '#2e7d32', // Changed to a fireish blue color
  };

  const pieChartColors = ['#2196f3', '#4caf50', '#ff9800', '#f44336', '#9c27b0', '#00bcd4', '#3f51b5', '#e91e63'];

  // Calculate total earnings across all clients
  const totalEarnings = assignments.reduce((sum, assignment) => {
    const earnings = assignment.amountPaid || 0;
    return sum + earnings;
  }, 0);

  // Calculate budgeted hours for the current month
  const currentMonthBudgetedHours = assignments
    .filter(a => a.status === 'in_progress')
    .reduce((sum, a) => sum + (a.budgetedHours || 0), 0);

  // Calculate total utilized hours
  const totalUtilizedHours = assignments.reduce((sum, a) => sum + (a.utilizedHours || 0), 0);

  return (
    <Card sx={{ mb: 4, boxShadow: 3, borderRadius: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>Assignments Overview</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>Assignment Status</Typography>
            <Grid container spacing={2}>
              {[
                { label: 'Active', value: activeAssignments },
                { label: 'Interviewing', value: interviewingAssignments },
                { label: 'Completed', value: completedAssignments },
                { label: 'Total', value: totalAssignments },
                { label: 'AverageBillRate', value: averageBillRate.toFixed(2) },
                { label: 'MaxBillRate', value: maxBillRate.toFixed(2) },
              ].map((item) => (
                <Grid item xs={6} key={item.label}>
                  <Paper sx={{ 
                    p: 2, 
                    textAlign: 'center', 
                    backgroundColor: statusColors[item.label as keyof typeof statusColors],
                    color: 'white',
                    boxShadow: 2,
                    borderRadius: 2,
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {item.label.includes('BillRate') ? '$' : ''}{item.value}
                    </Typography>
                    <Typography variant="body2">{item.label.replace(/([A-Z])/g, ' $1').trim()}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>Earnings</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ width: '30%' }}>
                {amountPaidData.map((item) => (
                  <Typography key={item.id} variant="body2" sx={{ mb: 1 }}>
                    <span style={{ backgroundColor: pieChartColors[item.id % pieChartColors.length], width: 10, height: 10, display: 'inline-block', marginRight: 5 }}></span>
                    {item.label}: ${item.value.toFixed(2)}
                  </Typography>
                ))}
              </Box>
              <Box sx={{ width: '70%' }}>
                <PieChart
                  series={[
                    {
                      data: amountPaidData as Array<{ id: number; value: number; label: string }>,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30 },
                      valueFormatter: (value: number | { value: number }) => {
                        const numericValue = typeof value === 'number' ? value : value.value;
                        return `$${numericValue.toFixed(2)}`;
                      },
                    },
                  ]}
                  colors={pieChartColors}
                  height={300}
                  margin={{ top: 0, bottom: 0, left: 10, right: 10 }}
                  legend={{ hidden: true }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ 
                p: 2, 
                textAlign: 'center', 
                backgroundColor: '#e3f2fd', 
                boxShadow: 2,
                borderRadius: 2,
              }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2' }}>{currentMonthBudgetedHours.toFixed(2)}</Typography>
                <Typography variant="body2">Current Month Budgeted Hours</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ 
                p: 2, 
                textAlign: 'center', 
                backgroundColor: '#e8f5e9',
                boxShadow: 2,
                borderRadius: 2,
              }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4caf50' }}>{totalUtilizedHours.toFixed(2)}</Typography>
                <Typography variant="body2">Total Utilized Hours</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ 
                p: 2, 
                textAlign: 'center', 
                backgroundColor: '#1565c0', // Darker shade of blue
                boxShadow: 2,
                borderRadius: 2,
              }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'white' }}>${currentMonthlyBudget.toFixed(2)}</Typography>
                <Typography variant="body2" sx={{ color: 'white' }}>Current Monthly Budget</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper sx={{ 
                p: 2, 
                textAlign: 'center', 
                backgroundColor: '#2e7d32', // Darker shade of green
                boxShadow: 2,
                borderRadius: 2,
              }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'white' }}>${totalEarnings.toFixed(2)}</Typography>
                <Typography variant="body2" sx={{ color: 'white' }}>Total Earnings</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProjectAssignmentsOverview;
