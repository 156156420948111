import { collection, query, getDocs } from 'firebase/firestore';
import { db } from 'core/config/firebase';

export const getClientInvoices = async (clientId: string): Promise<InvoiceData[]> => {
  try {
    const invoicesRef = collection(db, 'clients', clientId, 'invoices');
    const q = query(invoicesRef);
    const querySnapshot = await getDocs(q);

    console.log(querySnapshot.docs);
    
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as InvoiceData));
  } catch (error) {
    console.error('Error fetching client invoices:', error);
    throw error;
  }
};