import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Card, CardContent, Divider, List, ListItem, ListItemText, Grid, CircularProgress, Chip, Stack } from '@mui/material';
import DashboardLayout from 'layouts/DashboardLayout';
import { getAccountDetailsById } from 'core/api/increase/account/getAccountDetailsById';
import { listAccountTransactions } from 'core/api/increase/account/listAccountTransactions';
import { listExternalAccounts } from 'core/api/increase/external-accounts/listExternalAccounts';
import SendMoneyDialog from './components/dialog/SendMoneyDialog';
import AddBankDialog from './components/dialog/AddBankDialog';
import AddMoneyDialog from './components/dialog/AddMoneyDialog';
import AddIcon from '@mui/icons-material/Add';
import Increase from 'increase';

// Increase Account type
interface IncreaseAccount {
  id: string;
  bank: 'blue_ridge_bank' | 'first_internet_bank' | 'grasshopper_bank';
  closed_at: string | null;
  created_at: string;
  currency: 'CAD' | 'CHF' | 'EUR' | 'GBP' | 'JPY' | 'USD';
  entity_id: string | null;
  idempotency_key: string | null;
  informational_entity_id: string | null;
  interest_accrued: string;
  interest_accrued_at: string | null;
  interest_rate: string;
  name: string;
  program_id: string;
  status: 'open' | 'closed';
  type: 'account';
}

interface AccountDetails {
  accountDetails: IncreaseAccount;
  balance: {
    available_balance: number;
    current_balance: number;
  }
}

// External Account type (simplified, adjust as needed)
interface ExternalAccount {
  id: string;
  account_number: string;
  routing_number: string;
  description: string;
  status: string;
}

const Bank: React.FC = () => {
  const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(null);
  const [transactions, setTransactions] = useState<Increase.Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [sendMoneyDialogOpen, setSendMoneyDialogOpen] = useState(false);
  const [addBankDialogOpen, setAddBankDialogOpen] = useState(false);
  const [externalAccounts, setExternalAccounts] = useState<ExternalAccount[]>([]);
  const [externalAccountsLoading, setExternalAccountsLoading] = useState(true);
  const [externalAccountsError, setExternalAccountsError] = useState<string | null>(null);
  const [addMoneyDialogOpen, setAddMoneyDialogOpen] = useState(false);

  const accountId = 'sandbox_account_wsja3ql2aqobwsegmehy';

  const fetchAccountData = async () => {
    try {
      setLoading(true);
      const details = await getAccountDetailsById(accountId);
      setAccountDetails(details);

      const transactionList = await listAccountTransactions(accountId);
      setTransactions(transactionList);
    } catch (err) {
      setError('Failed to fetch account data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchExternalAccounts = async () => {
    try {
      setExternalAccountsLoading(true);
      const result = await listExternalAccounts({});
      if (result.success) {
        setExternalAccounts(result.result.data);
      } else {
        setExternalAccountsError('Failed to fetch external accounts');
      }
    } catch (err) {
      console.error('Error fetching external accounts:', err);
      setExternalAccountsError('An error occurred while fetching external accounts');
    } finally {
      setExternalAccountsLoading(false);
    }
  };

  useEffect(() => {
    fetchAccountData();
    fetchExternalAccounts();
  }, []);

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active':
        return 'success';
      case 'pending':
        return 'warning';
      case 'inactive':
      case 'disabled':
        return 'error';
      default:
        return 'default';
    }
  };

  const handleSendMoneyClick = () => {
    setSendMoneyDialogOpen(true);
  };

  const handleSendMoneyDialogClose = () => {
    setSendMoneyDialogOpen(false);
  };

  const handleAddBank = () => {
    setAddBankDialogOpen(true);
  };

  const handleAddBankDialogClose = () => {
    setAddBankDialogOpen(false);
  };

  const handleAddMoneyClick = () => {
    setAddMoneyDialogOpen(true);
  };
 
  const handleAddMoneyDialogClose = () => {
    setAddMoneyDialogOpen(false);
    fetchAccountData();
    fetchExternalAccounts();
  };

  return (
    <DashboardLayout>
      <Box>
        <Typography variant="h4" component="h1" gutterBottom>
          Vault
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {accountDetails?.accountDetails.name || 'CHECKING'}
                </Typography>
                {loading ? (
                  <CircularProgress />
                ) : error ? (
                  <Typography color="error">{error}</Typography>
                ) : accountDetails ? (
                  <>
                    <Typography variant="h4" component="div" gutterBottom>
                      ${accountDetails.balance.current_balance.toFixed(2)}
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="body2" color="text.secondary">
                        ${accountDetails.balance.available_balance.toFixed(2)} Available
                      </Typography>
                      <Box>
                        <Button variant="outlined" color="primary" onClick={handleSendMoneyClick} sx={{ mr: 1 }}>
                          Send money
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleAddMoneyClick}>
                          Add money
                        </Button>
                      </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body2" color="text.secondary">
                      Account ID: {accountDetails.accountDetails.id}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Status: {accountDetails.accountDetails.status}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Bank: {accountDetails.accountDetails.bank}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Currency: {accountDetails.accountDetails.currency}
                    </Typography>
                  </>
                ) : (
                  <Typography>Unable to fetch account details</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  External Accounts
                </Typography>
                <List>
                  <ListItem>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleAddBank}
                      startIcon={<AddIcon />}
                      fullWidth
                    >
                      Add External Account
                    </Button>
                  </ListItem>
                  <Divider />
                  {externalAccountsLoading && <CircularProgress />}
                  {externalAccountsError && <Typography color="error">{externalAccountsError}</Typography>}
                  {externalAccounts.map((account) => (
                    <ListItem key={account.id}>
                      <ListItemText 
                        primary={
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1" fontWeight="bold">
                              {account.description || "External Account"}
                            </Typography>
                            <Chip 
                              label={account.status.charAt(0).toUpperCase() + account.status.slice(1)} 
                              size="small" 
                              color={getStatusColor(account.status) as "success" | "warning" | "error" | "default"}
                            />
                          </Stack>
                        }
                        secondary={
                          <Box mt={1}>
                            <Typography variant="body2" color="text.secondary">
                              Account: ••••{account.account_number.slice(-4)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              Routing: {account.routing_number}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
          
          {/* Recent Transactions Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Recent Transactions
                </Typography>
                {loading && <Typography>Loading transactions...</Typography>}
                {error && <Typography color="error">{error}</Typography>}
                {transactions.length > 0 && (
                  <List>
                    {transactions.slice(0, 10).map((transaction) => (
                      <ListItem key={transaction.id} divider>
                        <ListItemText
                          primary={transaction.description}
                          secondary={`${new Date(transaction.created_at).toLocaleDateString()} - ${transaction.type}`}
                        />
                        <Typography variant="body2" color={transaction.amount < 0 ? 'error' : 'success'}>
                          ${Math.abs(transaction.amount).toFixed(2)}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                )}
                {transactions.length === 0 && !loading && (
                  <Typography>No recent transactions found.</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <SendMoneyDialog 
          open={sendMoneyDialogOpen} 
          onClose={handleSendMoneyDialogClose} 
          mainAccountId={accountId}
          mainAccountBalance={accountDetails ? accountDetails.balance.available_balance : 0}
        />
        <AddBankDialog open={addBankDialogOpen} onClose={handleAddBankDialogClose} />
        <AddMoneyDialog 
          open={addMoneyDialogOpen} 
          onClose={handleAddMoneyDialogClose} 
          mainAccountId={accountId}
        />
      </Box>
    </DashboardLayout>
  );
};

export default Bank;
