import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Snackbar, Paper, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PeopleIcon from '@mui/icons-material/People';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddStakeholderDialog from "./AddStakeholderDialog";
import { addStakeholderToClient, removeStakeholder, getClientStakeholders } from "core/api/stakeholders";
import StakeholderCard from "./StakeholderCard";
import { useLocalStorageBoolState } from "core/utils";

interface AssociatedStakeholdersProps {
  clientId: string;
}

const AssociatedStakeholders: React.FC<AssociatedStakeholdersProps> = ({ clientId }) => {
  const [openStakeholderDialog, setOpenStakeholderDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [stakeholders, setStakeholders] = useState<Stakeholder[]>([]);
  const [isVisible, setIsVisible] = useLocalStorageBoolState("isAssociatedStakeholdersVisible", false);

  useEffect(() => {
    loadStakeholders();
  }, [clientId]);

  const loadStakeholders = async () => {
    try {
      const clientStakeholders = await getClientStakeholders(clientId);
      setStakeholders(clientStakeholders);
    } catch (error) {
      console.error("Error loading stakeholders:", error);
      setError("Failed to load stakeholders. Please try again.");
    }
  };

  const handleAddStakeholderToClient = async (stakeholderData: Omit<Stakeholder, "id" | "email" | "user_type">) => {
    try {
      const newStakeholder = await addStakeholderToClient(clientId, stakeholderData);
      setStakeholders([...stakeholders, newStakeholder]);
      setOpenStakeholderDialog(false);
    } catch (error) {
      console.error("Error adding stakeholder:", error);
      setError("Failed to add stakeholder. Please try again.");
    }
  };

  const handleRemoveStakeholder = async (stakeholderId: string) => {
    try {
      await removeStakeholder(clientId, stakeholderId);
      setStakeholders(stakeholders.filter(stakeholder => stakeholder.id !== stakeholderId));
    } catch (error) {
      console.error("Error removing stakeholder:", error);
      setError("Failed to remove stakeholder. Please try again.");
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Box>
      <Paper 
        elevation={3}
        sx={{
          mb: 3,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: 'linear-gradient(45deg, #424242 30%, #616161 90%)',
          color: 'white',
        }}
      >
        <Box display="flex" alignItems="center">
          <PeopleIcon sx={{ fontSize: 40, mr: 2 }} />
          <Typography variant="h5" component="h2">Stakeholders</Typography>
          <IconButton onClick={toggleVisibility} sx={{ color: 'white', mr: 1 }}>
            {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Box>
        <Button
          onClick={() => setOpenStakeholderDialog(true)}
          startIcon={<AddIcon />}
          variant="contained"
          sx={{ 
            bgcolor: '#212121',
            color: 'white',
            '&:hover': {
              bgcolor: '#424242',
            },
          }}
        >
          Add Stakeholder
        </Button>
      </Paper>
      
      {isVisible && (
        stakeholders.length === 0 && (
          <>
          <Box display="flex" justifyContent="center" mt={4} mb={2}>

            <Typography>No stakeholders found</Typography>
          </Box>
                <Grid container spacing={3}>
                {stakeholders.map((stakeholder) => (
                  <Grid item xs={12} sm={6} md={4} key={stakeholder.id}>
                    <StakeholderCard
                      stakeholder={stakeholder as ClientStakeholder}
                      onRemove={() => handleRemoveStakeholder(stakeholder.id)}
                    />
                  </Grid>
                ))}
              </Grid>
              </>
        )
      )}

      <AddStakeholderDialog
        open={openStakeholderDialog}
        onClose={() => setOpenStakeholderDialog(false)}
        onAdd={handleAddStakeholderToClient}
        clientId={clientId}
        currentStakeholders={stakeholders.map((stakeholder) => ({ userId: stakeholder.id }))}
      />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={error}
      />
    </Box>
  );
};

export default AssociatedStakeholders;
