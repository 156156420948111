import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export const getUsersByType = async (userType: 'admin' | 'freelancer'): Promise<Stakeholder[] | Freelancer[] | UserInfoType[]> => {
  const db = getFirestore();
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('user_type', '==', userType));
  const querySnapshot = await getDocs(q);
  
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as Stakeholder | Freelancer | UserInfoType));
};
