/* eslint-disable */

// TODO: Remove eslint-disable and chanlde user info type in set state

import React from 'react'
import { GoogleAuthProvider, signInWithPopup, getAuth, getAdditionalUserInfo } from '@firebase/auth'
import { setJwtToken } from 'core/api/setJwtToken'
import { createUser, getUserProfile } from 'core/api/users'
import { getUTCtimeNow } from 'core/utils/getUTCTimeNow'

export const handleLoginWithGoogle = async (
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
  setUserInfo: React.Dispatch<any>,
) => {
  // export const handleLoginWithGoogle = async (setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>, setUserInfo: React.Dispatch<UserInfoType>) => {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()
  try {
    const userCredentialObj = await signInWithPopup(auth, provider)

    const credential = GoogleAuthProvider.credentialFromResult(userCredentialObj)
    if (!credential) {
      console.error('No credentials found')
      return false
    }
    const token = await userCredentialObj.user.getIdToken()
    if (!token) {
      throw new Error('No access token found.')
    }
    setJwtToken(token)

    let firebaseUserProfile = undefined  

    const additionalUserInfo = getAdditionalUserInfo(userCredentialObj)
    firebaseUserProfile = await getUserProfile(userCredentialObj.user.uid)
    const isNewUser = additionalUserInfo?.isNewUser || firebaseUserProfile === null

    if (isNewUser) {
      const user = userCredentialObj.user
      const data = user.toJSON()

      const utcTimeNow = getUTCtimeNow()

      const additionalData = {
        name: userCredentialObj.user.displayName,
        email: userCredentialObj.user.email,
        user_type: 'non-admin',
        is_active: false,
        email_verified: true,
      }

      firebaseUserProfile = await createUser(user, additionalData)
    }

    console.log('firebase user profile:', firebaseUserProfile)

    setUserInfo(firebaseUserProfile)
    setIsAuthenticated(true)
    return firebaseUserProfile
  } catch (error) {
    console.error('Error logging in with Google:', error)
    return false
  }
}
