import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { 
  Typography, Box, CircularProgress, Paper, Grid
} from "@mui/material";
import DashboardLayout from "layouts/DashboardLayout";
import ProfileInfo from "./components/ProfileInfo";
import SkillsSection from "./components/SkillsSection";
import DocumentsSection from "./components/DocumentsSection"; // Update this import
import { fetchFreelancerDoc, fetchFreelancerDocuments } from "core/api/freelancer";

const Profile: React.FC = () => {
  const [freelancer, setFreelancer] = useState<Freelancer | null>(null);
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState<FreelancerDocument[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        try {
          const freelancerData = await fetchFreelancerDoc(user.uid);
          setFreelancer(freelancerData);
          const documentsData = await fetchFreelancerDocuments(user.uid);
          setDocuments(documentsData);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <DashboardLayout>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </DashboardLayout>
    );
  }

  if (!freelancer) {
    return (
      <DashboardLayout>
        <Typography>No freelancer data found</Typography>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Box py={4}>
        <Paper elevation={3} sx={{ mb: 4 }}>
          <Box p={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <ProfileInfo freelancer={freelancer} onUpdate={setFreelancer} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SkillsSection freelancer={freelancer} onUpdate={setFreelancer} />
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <DocumentsSection documents={documents} setDocuments={setDocuments} />
      </Box>
    </DashboardLayout>
  );
};

export default Profile;